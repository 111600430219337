import styled from "styled-components"
import { useState , useEffect} from "react";
import Pagination from "react-js-pagination";
import '../css/Paging.css'
import axios from "axios";

export default function AdminReservationHistory (props) {


    const [pageList, setPageList] = useState([]); 
    
    const [history, setHistory] = useState([]) ; 

    useEffect( () => {

        getData(); 
        

    } , [] )

    
    const ITEMCOUNTPERPAGE = 8; 

    const getData = () => {

        const data = {userid : props.userid}
        
        axios.post('/api/admin/getreservationhistory', data)
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
              }

                setPageList( result.data.slice( (page-1) * ITEMCOUNTPERPAGE, (page-1) * ITEMCOUNTPERPAGE + ITEMCOUNTPERPAGE )) ; 


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 
        

    }
   


    const close = () => {

        props.close(); 
    }

     const [page, setPage] = useState(1);

    const handlePageChange = (p) => {
        setPage(p);

        setPageList( history.slice( (p-1) * ITEMCOUNTPERPAGE, (p-1) * ITEMCOUNTPERPAGE + ITEMCOUNTPERPAGE )) ; 
    };


    return ( 
        <WRAP>

            <HistoryContainer> 

                <div><button onClick={()=> close()}>×</button></div>
                <Title> 예약/방문 내역 </Title>
                <TableContainer>
                    <table>
                        <tr>
                            <th>방문일</th>
                            <th>인원</th>
                            <th>가격</th>
                            <th>결과</th>
                            <th>예약일</th>
                        </tr>
                        
                        {pageList.map((a,i)=>( 
                            <tr key={i} onClick={()=>close()}>
                                <td>{`${pageList[i].yyyy}-${pageList[i].mm}-${pageList[i].dd}`}</td>
                                <td>{pageList[i].person}</td>
                                <td>{pageList[i].price}</td>
                                <td>{pageList[i].status}</td>
                                <td>{pageList[i].crtdt}</td>
                            </tr>
                        ))}
                        
                    </table>
                </TableContainer>

                <Pagination
                        activePage={page} 
                        itemsCountPerPage={ITEMCOUNTPERPAGE} 
                        totalItemsCount={ pageList.length} 
                        pageRangeDisplayed={ITEMCOUNTPERPAGE}
                        prevPageText={"<"}  
                        nextPageText={">"}
                        onChange={handlePageChange} 
                />

            </HistoryContainer>



        </WRAP>
        
    )

}


const WRAP = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
`

const HistoryContainer = styled.div`

    div{
        text-align: right ; 
        button{ background-color: #fff; font-size : 2rem }
    }

    width: 90%;
    max-width: 500px;
    margin: 30px auto;
    padding: 16px 16px 16px 16px;
    border-radius: 0.3rem;
    background-color: #fff;
    
    overflow: hidden;
    
`
const Title = styled.h4`
    position: relative;
    
    margin : 1rem ; 
    font-size : 1.8rem; 
    text-align : center ; 
    font-weight: 700;
`

const TableContainer = styled.table `




`

