import '../../css/My.css'
import AboutMenu from './AboutMenu'
import AboutUs from './AboutUs'
import WayToCome from './WayToCome'
import { useNavigate } from 'react-router-dom'


export default function InfoHome() {

    const navigate = useNavigate();
    

    return (
        <div id="contents">
         <div className="sub-back">
			<p onClick={()=>navigate(-1) }>Back</p>
		</div>

            <div id="sub">
                <h4 className="main-title">INFO</h4>
                <div id="sub-wrap">

                <AboutUs/>
                <WayToCome/>
                   
                </div>    
            </div>
        </div>

    )


}


