 import { useState } from "react"
 import { useEffect } from "react"
  import axios from "axios";
 import { useNavigate ,Link } from "react-router-dom";
 import MyTeamSetup from "./MyTeamSetup";

 export default function MyTeam() { 

    const [myTeam, setMyTeam] = useState([]) ;
    const [isEditModal, setIsEditModal] = useState(false); 
    const [currentTeam,setCurrentTeam] = useState(0); 


    useEffect( ()=>{

        getMyTeam(); 


    } ,[])


    const getMyTeam = () => {
        axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getmyteam/${localStorage.getItem('userid')}`) 
        .then((res)=>{

            if(res.data.errno !== undefined ) {
                alert(res.data.message); 
                return ; 
            }


            setMyTeam(res.data)
        }) 
        .catch((error)=> {
                console.log(error) ;
        })
    }


    const submitDecision = (teamname, userid, decision ) =>{


        const data = { teamname : teamname ,
            userid : userid, 
            ok : decision}


        axios.post(process.env.REACT_APP_DB_HOST+`/api/user/okteam/`, data ) 

        .then((res)=>{

            if(res.data.errno !== undefined ) {
                alert(res.data.message); 
                return ; 
            }

            alert('처리되었습니다')
            getMyTeam(); 
        }) 
        .catch((error)=> {
                console.log(error) ;
        })

    }

    const onEdit = (i) => {

        /*
        if( myTeam[i].leaderid !== localStorage.getItem('userid') ){
            alert('리더만 수정이 가능합니다. ') ;
            return ;
        }
*/

        setCurrentTeam(i)
    
        setIsEditModal(!isEditModal);
    }

    const closeModal = () => {

        setIsEditModal(false); 
    }
    


 
    return (

        <div id="my-team">
            <table className="my-team__tb">
           
                <thead>
                    <tr>
                        <th>팀 활동이름</th>
                        <th>리더명</th>									
                        <th>리더이미지</th>
                        <th>상세</th>
                        <th>수정</th>
                    </tr>
                </thead>
                <tbody>
                    {myTeam.map((a,i) => ( 
                    <tr key = {i}>
                        <td className="my-team__tb--team">{myTeam[i].teamname}<span>팀인원 {myTeam[i].count}명</span></td>
                        <td className="my-team__tb--name">{myTeam[i].leadername}<span>님</span></td>									
                        <td className="my-team__tb--image"><img src= {'/publicimg/' + myTeam[i].profileimgurl}/></td>
                        <td className="my-team__tb--detail"><Link to={`/artistdetail/TEAM/${myTeam[i].teamname}`}  >상세보기</Link></td>
                        <td className="my-team__tb--status" onClick={()=>onEdit(i)}><button >수정</button></td>
                    </tr>
                    ))}

                </tbody>
            </table>

            {isEditModal && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsEditModal(!isEditModal)}>X </h2>
                    <MyTeamSetup teamname={myTeam[currentTeam].teamname} mode='EDIT' close={closeModal} />
                </div>
            }                        


        </div>
    )

 }
