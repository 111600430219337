import { useState, useRef, useEffect } from "react"; 
import styled from "styled-components";
import OneArtist from "../../components/OneArtist";
import axios from "axios";
import { useSelector } from 'react-redux';
import {   useNavigate  } from 'react-router-dom';

export default function MyTeamSetup(props) { 

    
    const navigate = useNavigate();
  
    const[oneArtistOpen,setOneArtistOpen] = useState(false);

    const [instruments, setInstruments] = useState([]) ;
    const instBase = ['Drums','Keyboard','Bass','Trumpet','Disk Jackey','Saxophone','Vocal','Guitar','Piano','Trombone','Percussion','Harmonica','Flute','Violin','Accordion','Viola','Cello','Organ','Vibraphone'] ;
    

    const artistRedux = useSelector((state)=>{return state.ArtistsSlice.data}) 
    const userRedux = useSelector((state)=>{return state.LoginSlice.user}) 
  //  const [teamMembers, setTeamMembers] = useState([]);
    //const [myUserId, setMyUserId] = useState(''); 
    
   
    useEffect(()=>{  

        if(userRedux.usertype !== 'ARTIST' && userRedux.usertype !== 'ADMIN' ){
            alert('Artist만 접근 가능합니다.')
            navigate("/", { replace: true })
            return; 
        } 

        const userid = localStorage.getItem('userid') ;

        if (userid === '' || userid === null || userid === undefined)
        {
            alert('로그인이 필요합니다. ') ;
            navigate("/", { replace: true })
            return ; 
        }
        // setMyUserId(localStorage.getItem('userid'));

        // 수정모드 
        if (props.mode === 'EDIT' ) {
            axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getteam/${props.teamname}`)
            .then((result) => { 

                if(result.data.errno !== undefined ) {
                    alert(result.data.message); 
                    return ; 
                }

                setTeamname(result.data[0].teamname);
                setInstruments(result.data[0].instruments.split(','));
                setVideo1(result.data[0].video1); 
                setVideo2(result.data[0].video2); 
                setVideo3(result.data[0].video3); 
                setInstagram(result.data[0].instagram); 
                setIntroduce(result.data[0].introduce); 

                const temp = [] ; 
                temp[0] = result.data[0].profileimgurl ;
                temp[1] = result.data[0].imageurl1 ;
                temp[2] = result.data[0].imageurl2 ;
                temp[3] = result.data[0].imageurl3 ;
                temp[4] = result.data[0].imageurl4 ;
                setImageurl(temp); 

            })
            .catch((error)=> {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            }) 

            axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getteammembers/${props.teamname}`)
            .then((result) => { 

                if(result.data.errno !== undefined ) {
                    alert(result.data.message); 
                    return ; 
                }

                setTeamMembers(result.data); 
            })
            .catch((error)=> {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            }) 
  
        }else    // 생성모드 
        {

            if(userRedux.usertype !== 'ADMIN' ){
                const Local_userid = localStorage.getItem('userid') ; 
                const temp = artistRedux.filter( (el) => ( el.userid === Local_userid ) );
        
                setTeamMembers([{
                    teamname : '', 
                    userid : localStorage.getItem('userid') ,
                    nickname : temp[0].nickname, 
                    profileimgurl : temp[0].profileimgurl,
                    isleader : true ,
                    subMenuVisible : false, 
                    ok : '수락완료'
                }])
            }
        }
    
} , [] )

    

    
    const [teamMembers, setTeamMembers] = useState([]) ;


    // 이미지 업로드 처리 
    const imageCnt = [1,2,3,4];
    const [localImageUrl, setLocalImageUrl] = useState([]); // http:// 주소 
    const [imageurl, setImageurl] = useState([null,null,null,null,null]) ;
    const [preview, setPreview] = useState([]);
    const [imageBool, setImageBool] = useState([true,true,true,true,true]);
    const pictureRef = useRef([1,2,3,4,5]);   // 버튼을 숨기고 input 에 ref 를 통해서 event 전달 용 


    const [teamname, setTeamname] = useState(''); 
    const [video1, setVideo1] = useState('');
    const [video2, setVideo2] = useState('');
    const [video3, setVideo3] = useState('');
    const [instagram, setInstagram] = useState('');
    
    const [introduce, setIntroduce] = useState(''); 

    const checkedInstrument = (isChecked, item) => 
    {
        if(isChecked){
            setInstruments([...instruments, item]);
        }else if (!isChecked){
            setInstruments(instruments.filter( el=> el !== item)) ;
        }
    }


    // 팀원 추가 미니버튼 서브 메뉴를 보여줬다 안보여줬다. 
    const submenuToggle = (i) => {

        
        const temp = {...teamMembers[i] , subMenuVisible : !teamMembers[i].subMenuVisible} ;
        const tempArray = [...teamMembers]
        tempArray[i] = temp ; 
        setTeamMembers(tempArray) ;
        
    }

    const invitation = () => { 
        setOneArtistOpen(true) ; 
    }
    
    const invitationClose = ( userid, nickname, profileimgurl, isleader, ok ) => { 

        if ( teamMembers.findIndex(e => e.userid === userid) !== -1 ) {

            alert('이미 등록된 멤버입니다. ') ;
            return; 
        }


        if(userid !== null && userid !== undefined && userid !== 'justclose'){

            const temp = [...teamMembers,   {
                teamname : teamname, 
                userid : userid ,
                nickname : nickname, 
                profileimgurl : profileimgurl,
                isleader : isleader ,
                subMenuVisible : false,
                ok : ok
            }] ;
            setTeamMembers(temp) ; 
        }

        setOneArtistOpen(false) ; 
    }

   

    
    const onPictureClick = (e,i) => {e.preventDefault(); pictureRef.current[i].click(); }



    const onDeleteClick = (e,i) => { 
        var temp = {...imageBool} ; 
        temp[i] = true ; 
        setImageBool(temp); 
    
        var temp2 = [...imageurl] ; 
        temp2[i] = null ; 
        setImageurl(temp2) ; 
    
    }

    const encodeFileToBase64 = (e, i ) => {
        
        const temp2 = {...localImageUrl} ; 
        temp2[i] = e.target.files[0] ; 
        setLocalImageUrl(temp2 ) ;

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        //console.log(fileBlob);

        return new Promise((resolve) => {
            reader.onload = () => {
            const temp = {...preview}
            temp[i] = reader.result ;
            setPreview(temp);
            resolve();
            };
            const temp1 = {...imageBool} ;
            temp1[i] = false ;
            setImageBool(temp1) ;
            e.target.value = '' ; 

        });
    };

    const deleteMember = (i) => {

        const id = teamMembers[i].userid
        setTeamMembers(teamMembers.filter((el) => el.userid !== id)) ;
    }

    const upgradeMember = (i) => {

        const tempArray = [...teamMembers];
        
        // 모두 평민으로 
        for( var j = 0 ; j < tempArray.length ; j++)  tempArray[j].isleader = false; 
              
        // 리더로 승격 
        tempArray[i].isleader = true ; 
        tempArray[i].subMenuVisible = false ; 

        setTeamMembers(tempArray) ;

    }

    const onChangeTeamname = (e) =>{ setTeamname(e.target.value)}
    const onChangeVideo1 = (e) =>{ setVideo1(e.target.value)}
    const onChangeVideo2 = (e) =>{ setVideo2(e.target.value)}
    const onChangeVideo3 = (e) =>{ setVideo3(e.target.value)}
    const onChangeInstagram = (e) =>{ setInstagram(e.target.value)}
    
    const introduceChange = (e) => { setIntroduce ( e.target.value) } ; 

    const checkForSubmit = () => {

        if ( teamname === '' || teamname === null )
        {
            
            alert('활동이름(필수)를 입력하세요. ') ; 
            return false ; 
        }

        
        // if ( instruments.size > 3 )
        // {
        //     alert('대표악기는 3개까지 선택 가능합니다. ') ; 
        //     return false ; 
        // }

        if ( imageBool[0] === true && imageurl[0] === null   ) 
        {
            alert('프로필 이미지는 반드시 필요합니다. ') ; 

            return false ; 

        }

    }


    const submit = () => {
        if( checkForSubmit () === false ) return ; 
        // 그림 업로드 
        var j = 0 ; 
        const formArray = [] ; 

        for(var i=0 ; i < 5 ; i++) {
        
             // imageBool 이 false 인 것만 업로드 대상이다. 
             if(imageBool[i] === false  ) { 
                
                formArray[j] = new FormData();
                formArray[j].append("selectImg", localImageUrl[i]);
                
                j++;
            }    
        }

        const promiseArray = formArray.map( (formdata) => (axios.post(process.env.REACT_APP_DB_HOST+"/upload", formdata)) );

        
        // 그림이 몽땅 올라간 뒤에 
        Promise.all(promiseArray)
        .then((res) => { 
            // 1. 그림 경로 update

            const temp = [...imageurl] ;

            for(var i = 0 ; i < res.length ; i++ ) {

                for(var j = 0 ; j < imageurl.length ; j ++ ){
                    if ( temp[j] === null || temp[j] === undefined  || temp[j] === 'null' ||  temp[j]  === '' ){
                        temp[j] = res[i].data.filename ;
                        break ;        
                    }
                }

            }



            setImageurl(temp);
              
            // 2. DB 업로드 , 그림이 모두 올라가야 url 을 받아서 db update 가능 
            const dbData = {
            teamname : teamname.trim() , 
            profileimgurl : temp[0] , 
            imageurl1 : temp[1] , 
            imageurl2 : temp[2] , 
            imageurl3 : temp[3] , 
            imageurl4 : temp[4] , 
            instruments : instruments.join(',') ,
            video1 : video1 , 
            video2 : video2 , 
            video3 : video3 , 
            instagram : instagram, 
            introduce : introduce ,
            click : 0 , 
            good :  0 , 
            crtdt : '' 
            }

    
            console.log(dbData)
            // dbData.artistid = Math.random().toString(36).substring(2,12);
            dbData.crtdt = new Date().toISOString().slice(0,19).replace('T', ' ');

            const MergeObject = { team : {...dbData} , members : [...teamMembers]}
            
            axios.post(process.env.REACT_APP_DB_HOST+'/api/user/teamsetup', MergeObject )
            .then((result)=>{
                console.log("success")
                alert('저장 하였습니다. ')

                if(props.mode === 'EDIT' || props.mode === 'ADMIN' )
                    props.close(); 
                else
                    navigate("/my/myteam", { replace: true });
            })
            .catch((error)=> {
                alert('회원가입 정보를 서버에 전송중 오류가 발생하였습니다. 이미지 파일 사이즈가 최대치를 초과하였습니다. ' + error )
          
                console.log(error)}) 


        })
        .catch((err)=>{
            
            console.log(err)
        } )
        


    }

        



    return (
        <div>

            <div id="mysub-cont-teamsetup">
                <div className="mpg-title">
                    {props.mode === 'EDIT' && 
                    <h4>아티스트 팀 수정</h4>
                    }
                    {props.mode !== 'EDIT' && 
                    <h4>아티스트 팀 생성</h4>
                    }
                </div>
                
                <div className="mpg-team">
                    <dl className="join-form">
                        <dt>팀명(활동이름)<span>*</span></dt>
                        <dd>
                            {props.mode === 'EDIT' && 
                                <TeamName>{teamname}</TeamName>
                            }
                            {props.mode !== 'EDIT' && 
                                <input style={{width:'100%'}} type="text" name="" id="" className="join-input" placeholder="팀명을 입력해주세요 (특수문자 불가 ,저장 후 수정 불가)" onChange={onChangeTeamname} value={teamname} />
                            }
                            </dd>
                    </dl>

                    <dl className="join-form">
                        <dt>팀원등록</dt>
                        <dd>
                            <ul className="join-artist__img">
                                { teamMembers.map((a,i)=>(
                                    <li key={i}>
                                        <div className="mpg-team__profile">
                                            <img src={'/publicimg/'+ teamMembers[i].profileimgurl} alt=''/>
                                            {teamMembers[i].isleader === true  && 
                                                <div className="mpg-team__profile--leader">

                                                </div>
                                            }
                                            {teamMembers[i].isleader !== true  &&
                                                <div className="mpg-team__profile--setting">
                                                    <button type="button" onClick={()=>submenuToggle(i)}/>
                                                    {teamMembers[i].subMenuVisible && 
                                                             <dl>
                                                             <dt><button onClick={()=>deleteMember(i)}>팀에서 삭제</button></dt>
                                                             <dt><button onClick={()=>upgradeMember(i)}>리더 권한 위임</button></dt>
                                                     </dl>
                                                    }
                                                    
                                                </div>
                                                
                                            }
                                            
                                            <p>{teamMembers[i].nickname }</p>
                                        </div>
                                    </li>
                                ))}
                                <li>
                                    <div className="mpg-team__profile">
                                        <img className="dotted" src='/img/common/user_sample.png' alt='' />
                                            <button type="button" onClick={invitation}className="mpg-team__profile--add">팀원초대</button>
                                        
                                        <p>팀원초대</p>
                                        <span>&nbsp;</span>											
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </dl>


                    <dl className="join-form">                                            
                        <dt> 프로필 이미지 등록<span>*</span></dt>
                    </dl>
                
                    <ArtistImgDiv>
                        <div>
                            {!imageBool[0]?<img src={preview[0]} alt="" />  :  imageurl[0] !== null ? <img src={'/publicimg/' + imageurl[0]} alt="" /> : '' }
                            <input type="file" accept='image/*' ref={(el)=>pictureRef.current[0] = el} onChange={(e)=>encodeFileToBase64(e,0)} style={{display:'none'}} />
                            {imageBool[0]  && imageurl[0] === null ?<PictureClick onClick={(e)=>onPictureClick(e,0)}>＋</PictureClick> : ''}
                            {!imageBool[0] || imageurl[0] !== null ?<ImageDelete onClick={(e)=>onDeleteClick(e,0)}>X</ImageDelete>: ''}
                        </div>
                        
                    </ArtistImgDiv>

        

                    <dl className="join-form">
                        <dt>팀 이미지 등록</dt>
                        <dd>
                            <ArtistImgDiv>
                                <div>
                                {imageCnt.map( (a,i)=> (
                                    <div key={i}>
                                        {!imageBool[a]?<img src={preview[a]} alt="" /> :  imageurl[a] !== null && imageurl[a] !== 'null' ? <img src={'/publicimg/' + imageurl[a]} alt="" /> : ''}
                                        <input type="file" accept='image/*' ref={(el)=>pictureRef.current[a] = el} onChange={(e)=>encodeFileToBase64(e,a)} style={{display:'none'}} />
                                        {imageBool[a] && ( imageurl[a] === null || imageurl[a] === 'null' ) ?<PictureClick onClick={(e)=>onPictureClick(e,a)}>＋</PictureClick> : ''}
                                        {!imageBool[a] || ( imageurl[a] !== null && imageurl[a] !== 'null') ?<ImageDelete onClick={(e)=>onDeleteClick(e,a)}>X</ImageDelete>: ''}
                                    </div>
                                ))}
                                </div>
                                
                            </ArtistImgDiv>

                        </dd>
                    </dl>                            



                        <dl className="join-form">
                            <dt> 대표악기설정 <span className="comment-span">(팀내 공연악기 모두 선택해주세요.)</span></dt>
                            <dd>
                                <ul className="join-inst">
                                    {instBase.map( 
                                        (a,i) => (
                                            <InstrumentCheckBox key={i} >
                                                <input type="checkbox" checked={instruments.includes(a)?true:false} id={a} value={a} onChange={(e)=>checkedInstrument(e.target.checked,e.target.value) } /><label  htmlFor={a}>{' '+a}</label></InstrumentCheckBox>
                                        )
                                    )}
                                    
                                    
                                </ul>
                            </dd>
                        </dl> 

                     <dl className="join-form">
                        <dt> Youtube </dt>
                            <dd>
                               <ArtistVideo >
                                <div>
                                    <input type="text" onChange={onChangeVideo1} placeholder="URL 입력" value={video1} />
                                </div>
                                <div>
                                    <input type="text" onChange={onChangeVideo2} placeholder="URL 입력" value={video2}/>
                                </div>
                                <div>
                                    <input type="text" onChange={onChangeVideo3} placeholder="URL 입력" value={video3}/>
                                </div>

                            </ArtistVideo>
                        </dd>
                    </dl> 

                    <dl className="join-form">
                        <dt> Instagram </dt>
                            <dd>
                               <ArtistVideo >
                                <div>
                                    <input type="text" onChange={onChangeInstagram} placeholder="https:// 가 포함된 URL 을 입력해주세요." value={instagram}/>
                                </div>

                                </ArtistVideo>
                        </dd>
                    </dl> 


                    <dl className="join-form">
                        <dt>팀 소개</dt>
                        <ArtistIntroduce>
                            <textarea cols="30" rows="10" maxLength = {100} onChange={introduceChange} placeholder="아티스트를 소개하는 글을 간단히 입력해 주세요" value={introduce} ></textarea>
                            <p> <span>{introduce.length}</span>/100</p>
                        </ArtistIntroduce>
                        
                    </dl>

                    <SubmitButton onClick={submit} >
                        {props.mode === 'EDIT' && 
                            <button>저장</button>
                        }
                        {props.mode !== 'EDIT' && 
                            <button>팀 생성</button>
                        }
                    </SubmitButton>

                    <ul className="join-desc">
                        <li>아티스트 팀 신청자가 최초  리더가 됩니다.</li>
                    </ul>

                </div>
                    
            </div>

            {oneArtistOpen && <OneArtist close={invitationClose}/>} 

        </div>

    )

 }


const TeamName = styled.div`


    font-size:22px ; 
    color : grey; 
    width : 200px; 
    

`

const SubmitButton = styled.div`

 text-align : center ; 
 margin : 3rem ;  
 

    button{
        background : black; 
        color : white; 
        font-size : 1.5rem ; 
        height : 40px; 
        width : 200px; 
    }

`

 const InstrumentCheckBox = styled.li`
 height:30px; 
 width : auto ;
 display:inline-block;  
 line-height:28px;
 text-align:center ; 
 margin: 3px;
 padding: 0 13px 0 10px;
 border: solid 1px #acacac;
 border-radius: 20px; font-size: 14px;
`

const ArtistImgDiv = styled.div`
    position: relative; 
    div { 
        display:flex;
        flex-direction: row; 
        justify-content : space-between; 
        position: relative; 
        
            
        img {

            width : 80px; 
            height : 90px;
            margin : 10px 0px; 

        }

       
    }
`


const PictureClick = styled.button`

    font-size : 50px; 
    font-weight : 100;
    width : 80px;
    height : 90px; 
    color:#fff; 
    background: #acacac; 
    cursor: pointer; 
    margin : 10px 0px; 

    &:hover{
        background : #5b5b5b ;
    }

`

const ImageDelete = styled.button`
    
    position: absolute; 
    padding : 5px 10px; 
    left: 54px; top: 10px;
    background : black;
    opacity: 0.7;
    color : white; 
    :hover { opacity: 0.9; cursor:pointer }
`


const ArtistVideo = styled.div`
    display: flex; 
    flex-direction : column ; 

    div{
        display :flex;
        flex-direction : row ; 
        margin-bottom: 10px; position: relative; border-bottom: solid 1px #c1c1c1;

        input{
            width:calc(100% - 25px) !important; height: 40px; border: none; padding: 0; font-size: 14px;

        }
    }

`


const ArtistIntroduce = styled.div`

    textarea{
        width : 100%; 
        height : 150px ; 
        border :  solid 1px  #ebebeb;
        
        font-size: 14px;

    }
    p { text-align: right; color: #acacac; margin-top: 5px; }

`