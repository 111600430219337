

export default function AboutUs() {

    return (

        <div >
            <h5 className="sub-cont__title">About Us</h5>
            <div id="about">
                <div className="about-top">
                    <img src="../img/sub/about_top.png" alt="" />
                </div>
                <dl className="about-text">
                    <dt>올댓재즈에 대하여</dt>
                    <dd>
                        <p>
                            올댓재즈는 1976년부터 한국의 재즈씬을 이끌어온 한국의 대표브랜드로서 재즈매니아들의 성지와도 같은 공간입니다.<br />
                            우리는 재즈의 불모지로 불리는 한국에서 재즈라는 장르의 음악이 조금 더 대중들에게 알려질 수 있도록 다양한 시도를 하고 있으며,  2022년 새로운 공간에서 새롭게 태어납니다.<br /><br />
                            우리는 더 이상 단순한 재즈바가 아닌 재즈를 주제로 다양한 활동들을 아우를 수 있는 새로운 브랜드로 새로운 발돋음을 합니다.<br />
                            그동안 쌓아온 시간위에 올댓재즈만의 헤리티지를 덧붙여 한국 재즈문화의 선두주자로 한국 재즈문화를 이끌어 가겠습니다.
                        </p>
                        <strong>
                            Enjoy whatever you want.<br />
                            ‘All that jazz’ means A to z about jazz.<br />
                            당신이 원하는게 무엇이든 즐기세요. 올댓재즈의 의미는 재즈의 A부터 Z까지입니다.
                        </strong>
                    </dd>
                </dl>
            </div>
        </div>



    )

}