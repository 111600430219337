import React, { useCallback, useState, useRef, useEffect  } from "react";
import styled from "styled-components";
import axios from "axios";

//import style from "../style/calendar.css";


const Container = styled.div`
    display : flex; 
    flex-direction : column ; 
    width : 100%;
    height : 100% ; 

 `

const Five_week = styled.div`
    display: flex; 
    flex-direction : row ; 
    
    
`
const Seven_day = styled.div`
    position : relative; 
    width : 15%; 
    font-size : 3em ; 
    height : 5em; 
    border-top : solid 1px #000 ;
    background : #f5f5f5 ; 
   
    color : ${props=>( props.disable ? '#c8c8c8' : props.selectedMm===props.tempMm && props.selectedDd===props.day && props.day > 0  ? '#C20019' : 'black' )};
    font-weight : ${props=>(props.selectedMm===props.tempMm && props.selectedDd===props.day && props.day > 0? '700' : '100' )};
    

    div{
        margin : auto ; 
        transform: rotate(-30deg);
        line-height : 3rem ; 
        text-align : center ; 
        font-size : 2rem ; 
        width : 3rem ; 
        height : 3rem ; 
        color : white; 
        background : #C20019 ; 
        border-radius: 50%;
    }

    &:hover{  
      color : #C20019 ;
      cursor : pointer ; 
      font-weight : 700; 
`

const MonthSelector = styled.div` 
      display : flex ; 
      flex-direction : row ; 
      justify-content : space-between ; 
      padding : 0em 2em ; 
      background : white ; 
      line-height : 40px ; 
      font-size : 5em ; 
      button { font-size : 1em; background : white; }
`

const Week = styled.div`
        display : flex ; 
        flex-direction : row ; 
        justify-content : space-between ; 
        line-height : 19px ; 
        div{
          width : 100%; 
          text-align : center ; 
          font-weight: 700; 
          font-size : 3em ; 
          border-top : solid 1px black; 
        }
      
      
`

const Dayoff = styled.p`

        font-size : 15px; 
        text-align : center; 
        font-weight : 700; 
        color : grey ; 

`




const Circle = styled.li  `

          content: '' ; display: block; position: absolute; left: 5px;  margin-top: -6px; width: 8px; height: 8px; margin-right: 5px; -webkit-border-radius: 50%; border-radius: 50%;
          top: ${(props)=>((props.bu+1)*15+40)}%;
          background: ${props=>( props.disable ? '#c8c8c8' : '#C20019' )};
`

export default function Calendar  ( props ) {

  const week = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]; //일주일


  // selecte = 클릭되어있는   // temp 화면에 보이는 
  const [tempYyyy, setTempYyyy] = useState(0); //화면에보이는 연도
  const [tempMm, setTempMm] = useState(0); //화면에보이는 달 ( 선택된 달과 화면에 보이는 달이 다른 경우가 있어 필요함 )
  const [selectedYyyy, setSelectedYyyy] = useState(0); //현재 선택된 연도
  const [selectedMm, setSelectedMm] = useState(0); //현재 선택된 달
  const selectedref  = useRef(0); 
  const [selectedDd, setSelectedDd] = useState(0); 
  const today = new Date();
  const todaynum = today.getFullYear() * 10000 + (today.getMonth()+1)*100 + today.getDate() ; 
  const five = [ 1 , 2, 3, 4, 5, 6 ] ;
  const seven = [ 0 , 1, 2, 3 , 4, 5, 6] ;
  const dateTotalCount = new Date(tempYyyy, tempMm, 0).getDate(); //선택된 연도, 달의 마지막 날짜
  const firstday = new Date(tempYyyy, tempMm - 1, 1).getDay() - 1 ;  // 1일의 n번째 

  const [redWhite,setRedWhite] = useState([]) ; 

  const [dayoff,setDayoff] = useState([]) ; 


  useEffect(
    ()=> {

      setSeletedDate(props.yyyy, props.mm , props.dd );
      setTempYyyy(props.yyyy); 
      setTempMm(props.mm); 



 


    }, [props.yyyy, props.mm , props.dd ]
  )

  useEffect(
    ()=> {

      // if(props.purpose === 'ADMIN') getRedWhite(); 

      getRedWhite(); 
      getDayoff(); 
    }, [tempYyyy, tempMm , props.tick  ]
    )
  
      // 공연을 가져온다 빨간색/회색 표시한다 
  const getRedWhite = () =>{
      const data = { yyyy : tempYyyy, mm: tempMm }

      axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/getmonthschedule', data)
        .then((result) => { 

            

            if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
            }
            
            setRedWhite(result.data) ;

            for( var i = 0 ; i < result.data.length ; i++) {

            }


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)}) 
  }

        // 휴일을 가져와 '휴무' 를 표시한다. 
        const getDayoff = () =>{
          const data = { yyyy : tempYyyy, mm: tempMm }
    
          axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/getmonthdayoff', data)
            .then((result) => { 
    
                if(result.data.errno !== undefined ) {
                    alert(result.data.message); 
                    return ; 
                }
                setDayoff(result.data) ;
   
            })
            .catch((error)=> {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)}) 
      }
    


  const setSeletedDate = (yyyy,mm,dd)=>{
    setSelectedYyyy(yyyy); //현재 선택된 연도
    setSelectedMm(mm); //현재 선택된 달
    setSelectedDd(dd); 
  }

  const prevMonth = () => {
    
    //이전 달 보기 보튼
    if (tempMm === 1) {
      setTempMm(12);
      setTempYyyy(tempYyyy- 1);
    } else {
      setTempMm(tempMm - 1);
    }
    
  };

  const nextMonth = useCallback(() => {
    
    //다음 달 보기 버튼
    if (tempMm === 12) {
      setTempMm(1);
      setTempYyyy(tempYyyy + 1);
    } else {
      setTempMm(tempMm + 1);
    }


  }, [tempMm]);


  

  const onClickDay =  (e, dd) => 
  { 

    if( 0 < dd && dd <= 31)
    {
     
      const clickDt = new Date(tempYyyy , tempMm -1 , dd+1) ;
      
      setSeletedDate(tempYyyy,tempMm,dd);
      if( props.changeDate != null ) props.changeDate(tempYyyy,tempMm,dd);

      // if( clickDt < today) 
      // {

      // }
      // else 
      // {
      //   setSeletedDate(tempYyyy,tempMm,dd);
      //   if( props.changeDate != null ) props.changeDate(tempYyyy,tempMm,dd);
      // }
    }
    
  }




  return (
    <>
      <Container>
        <MonthSelector>
          <button onClick={prevMonth}>〈</button>
          <h3> {tempMm.toString().padStart(2,'0')} </h3>
          <button onClick={nextMonth}>〉</button>
        </MonthSelector>

        <Week>
          <div>SUN</div><div>MON</div><div>TUE</div><div>WED</div><div>THE</div><div>FRI</div><div>SAT</div>
        </Week>
        
        <div className="date">
            {five.map( (a,i) => (
                <Five_week key={i}>
                    {seven.map((a,j)=>( 
                        <Seven_day key={j} 
                                    
                                    day={ (0 < i*7 + j-firstday && i*7 + j-firstday <= dateTotalCount) ? i*7 + j-firstday : '' }  
                                    onClick={(e)=>onClickDay(e, (0 < i*7 + j-firstday && i*7 + j-firstday <= dateTotalCount) ? i*7 + j-firstday : '')}
                                    selectedDd={selectedDd }  
                                    selectedMm={selectedMm }  
                                    tempMm={tempMm}
                                    disable={tempYyyy*10000 + tempMm * 100 + (i*7 + j-firstday) < todaynum? true : false }
                                    
                        > 
                          { (0 < i*7 + j-firstday && i*7 + j-firstday <= dateTotalCount) ? i*7 + j-firstday  : '' }  

                          { (selectedMm===tempMm && selectedDd===i*7 + j-firstday)   && <div> A </div>  }
                          
                          { redWhite.map((c,k) =>(

                              <ul key={k}>
                                { (redWhite[k].yyyy === tempYyyy && redWhite[k].mm === tempMm && redWhite[k].dd===i*7 + j-firstday) && <> 
                                   {redWhite[k].bu===0  && <Circle bu={redWhite[k].bu} disable={tempYyyy*10000 + tempMm * 100 + (i*7 + j-firstday) < todaynum? true : false } ></Circle> }
                                   { redWhite[k].bu===1  && <Circle bu={redWhite[k].bu} disable={tempYyyy*10000 + tempMm * 100 + (i*7 + j-firstday) < todaynum? true : false }></Circle> } 
                                   { redWhite[k].bu===2  && <Circle bu={redWhite[k].bu} disable={tempYyyy*10000 + tempMm * 100 + (i*7 + j-firstday) < todaynum? true : false }></Circle> }
                                   </>
                                } 
                                  
                               
                              </ul>    

                          ) ) }
                          
                          { dayoff.map((d,l) =>(

                              <ul key={l}>
                                { (dayoff[l].yyyy === tempYyyy && dayoff[l].mm === tempMm && dayoff[l].dd===i*7 + j-firstday) && 
                                  <Dayoff>closed</Dayoff>
                                } 
                                  
                              
                              </ul>    

                              ) ) }



                        </Seven_day> 
                        
                                                                  
                        
                        )
                    )}    
                </Five_week>

            ) )}
        </div>
      </Container>
    </>
  );
};

