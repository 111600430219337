import { useState, useEffect } from "react"
import styled from "styled-components";
import { useSelector } from 'react-redux';
import axios from "axios";


export default function SeatModal(props) {

    const [seats, setSeats] = useState([]);
    const [count, setCount] = useState(0); 
    const bu = [props.bu1,props.bu2,props.bu3] ;

    const selectedDate = props.selectedDate ; // useSelector((state)=>{return state.ReservationSlice.selectedDate}) ;

	useEffect(()=>{

        var tempbu = '('
        for(var i = 0 ; i < 3 ; i ++) {
            if(bu[i]===true) tempbu += `${i},`;
        }
        tempbu += `9)`

		const data = { yyyy: selectedDate.yyyy, mm: selectedDate.mm, dd: selectedDate.dd, bu: tempbu } ;

        axios.post(process.env.REACT_APP_DB_HOST+'/api/reservation/getreservationseats', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setSeats(result.data.filter(el => el.y > 100));

            })
            .catch((error) => {
                //  alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
			})
            
	},[])


    const onSeleteSeat = (i) => {


        // C 석이 선택되어 있으면 A,B 를 고르지 못하게 막는다.  
        var isC = false ; 
        var isAB = false; 
        for( var k = 0 ; k < seats.length ; k++){

            if( seats[k].occupied === 'NEW' ) {
                if ( seats[k].nametag.substr( 0,1 )  === 'V' )
                    isC = true ; 
                if ( seats[k].nametag.substr( 0,1 )  === 'B' || seats[k].nametag.substr( 0,1 )  === 'O' || 
                     seats[k].nametag.substr( 0,1 )  === 'S' || seats[k].nametag.substr( 0,1 )  === 'H')
                    isAB = true ;                 
            }
        }

        if( seats[i].nametag.substr(0,1) === 'B' && isC === true){
            alert('Booth와 Hall, Bar 테이블은 동시에 선택이 불가능합니다. ')
            return ; 
        }

        if( seats[i].nametag.substr(0,1) === 'O' && isC === true){
            alert('Booth와 Hall, Bar 테이블은 동시에 선택이 불가능합니다.  ')
            return ; 
        }
        if( seats[i].nametag.substr(0,1) === 'S' && isC === true){
            alert('Booth와 Hall, Bar 테이블은 동시에 선택이 불가능합니다. ')
            return ; 
        }
        if( seats[i].nametag.substr(0,1) === 'H' && isC === true){
            alert('Booth와 Hall, Bar 테이블은 동시에 선택이 불가능합니다.  ')
            return ; 
        }
        if( seats[i].nametag.substr(0,1) === 'V' && isAB === true){
            alert('Booth와 Hall, Bar 테이블은 동시에 선택이 불가능합니다. ')
            return ; 
        }
   


        const temp = [...seats];
        if( temp[i].occupied === 'NOTYET'){
             temp[i].occupied  = 'NEW';
             setCount(count+temp[i].maxperson); 
        }else if( temp[i].occupied === 'NEW'){
             temp[i].occupied  = 'NOTYET';
             setCount(count-temp[i].maxperson); 
        }


        setSeats(temp);
    }

    const  onX = () => {

         props.close('JUSTCLOSE'); 
    }

    const  onClose = () => {

        if(props.admin !== 'ADMIN'){

            if( seats.filter( (el)=>( el.occupied === 'NEW'))[0].nametag.substr(0,1) !== 'V' )
            {
                if( props.people > count){
                    alert('인원대비 선택된 좌석이 부족합니다. 좌석을 추가로 선택해 주세요')
                    return ; 
                }
                if( props.people < count-1 ){
                    alert('인원대비 많은 좌석이 선택 되었습니다. ')
                    return ; 
                }
            }

        }
        props.close( seats.filter( (el)=>( el.occupied === 'NEW'))); 
    }

    return (
        <WRAP>

            
                <SeatContainer gap={props.gap}>


                    <CloseBtn onClick={onX}>x</CloseBtn>

                    {seats.map((a, i) => (
                        <Box key={i} x={seats[i].x+20} y={seats[i].y - 200} onClick={() => (onSeleteSeat(i))} occupied={seats[i].occupied}  width={seats[i].width} height={seats[i].height} >
                               
                               <h4 > {seats[i].nametag } </h4>
                                <img src={seats[i].imageurl} width={seats[i].width} height={seats[i].height} />


                        </Box>
                        
                    ))}
                    <h3> 인원수 : {props.people}  </h3>
                    <h5> 선택좌석수 : {count}</h5>

                    <ConfirmButton onClick={onClose}>선택확정</ConfirmButton>

                    <Restorant_table/>

                </SeatContainer>
          
        </WRAP>

    )


}


const WRAP = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0; 
    bottom:0 ; 
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
   
`

const SeatContainer = styled.div`

    position : fixed; 
    width : 100% ; 
    max-width : 400px; 
    height : 600px ;
    border : solid #ddd 2px ; 
    top : 50px; 
    left : 0 ; 
    right : 0 ; 
    margin : 0 auto ; 
    
    background-color : #F4F4F4; 
    h3{ position : absolute; font-size : 1.2rem ; left : 20px; bottom: 30px;  }
    h5{ position : absolute; font-size : 1.2rem ; left : 20px; bottom: 10px; }
    

    

`

const Restorant_table = styled.div`
    left : 120px; 
    top : 0px; 
    position : absolute; 
    width : 255px; 
    height : 500px; 
    background: url('../img/common/restorant.png')no-repeat top right / contain;
`

const CloseBtn = styled.button` position : absolute; font-size : 2rem ; right : 3%; top: 5px; background : #f1f1f1; z-index : 2; `
const Box = styled.div`

    position : absolute ; 
    left : ${(props) => (props.x)}px ;
    top : ${(props) => (props.y)}px ;
    z-index : 3; 

    cursor : ${(props) => (props.occupied === 'OCCUPIED' ? '' : 'pointer')} ; 
    img {
    
   
        z-index : 3; 
        filter: ${(props) => (props.occupied === 'OCCUPIED' ?  'opacity(0.5) drop-shadow(0 0 0 grey)' :( props.occupied === 'NEW' ? 'opacity(0.5) drop-shadow(0 0 0 red)' :  '' ) )}  ;
        
        

    }
    h4 {
        position : absolute; 
        text-align : center ; 
        top : ${(props) => (props.height)/4}px; 
        left : ${(props) => (props.width)/4}px; 
        width : 20px; 
        font-size : 1rem ; 
        color : ${(props) => (props.occupied === 'OCCUPIED' ?  'grey' : ( props.occupied === 'NEW' ? 'red' : 'black' ))} ; 
        z-index : 4; 
        
        
        
    }

`

const ConfirmButton = styled.button`
    position : absolute; 
    bottom : 10px; 
    border : solid 1px black ; 
    border-radius : 20px; 
    padding : 10px; 
    left : 40% ; 
    right : 40% ; 
    background : black ; 
    color : white; 

`

