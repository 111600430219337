import { configureStore, combineReducers, getDefaultMiddleware  } from '@reduxjs/toolkit'
import ReservationSlice from './ReservationSlice'
import ArtistsSlice from './ArtistsSlice';
import LoginSlice from './LoginSlice';
import OrderSlice from './OrderSlice';


const rootReducer = combineReducers({
    ReservationSlice, ArtistsSlice, LoginSlice, OrderSlice
})

export default configureStore({
    reducer : rootReducer, 
    middleware : [...getDefaultMiddleware()]

}) 