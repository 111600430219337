import { useState } from "react";
import { useEffect } from "react"
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setCart, setCartTotal } from "../store/OrderSlice";
import { setLoginFrom, setMenuLogin } from "../store/LoginSlice";
import { useParams , useNavigate} from "react-router-dom";
import Toast from "../components/Toast";
import { FaTrashAlt } from "react-icons/fa";

export default function FoodCart() {

    let { table } = useParams();
    const navigate = useNavigate();

    const [message, setMessage] = useState('');
    const [toast, setToast] = useState(false);

    const cartRedux = useSelector((state) => { return state.OrderSlice.cart });
    const cartTotalRedux = useSelector((state) => { return state.OrderSlice.cartTotal });

  
    const dispatch = useDispatch();


    const onChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    // 주문리스트 
    const onOrderList = () => { 

        navigate(`/foodorderedlist/${table}`, { replace: false })

    }

 

    const onGuestRequest = () => {

        var crtdt = new Date().toISOString().slice(0, 19).replace('T', ' ')
        const data = { nametag: table, request: message, crtdt: crtdt }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/setguestrequest', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setMessage('');
                alert('요청하였습니다.');

            })
            .catch((error) => {
                alert("getfood error occured!!! Please check console log (F12) ")
                console.log(error)
            })
   }
    // 주문하기 
    const onOrder = () => { 

        if ( localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '' || localStorage.getItem('userid') === null ){
            alert('주문은 로그인이 필요 합니다. ') ;

            //dispatch(setLoginFrom({from :'foodcart', table: table}) ) ; 

            const temp = 'foodmenu'
            dispatch(setMenuLogin(temp) ) ; 
            sessionStorage.setItem('loginfrom','foodcart') 
            sessionStorage.setItem('table',table) 

            navigate(`/login`, { replace: false }); 
            return ; 
        }



      


        var data = [] 
        var crtdt =  new Date().toISOString().slice(0,19).replace('T', ' '); 
        
        for(var i = 0 ; i < cartRedux.length ; i++ ){
            data[i] = { nametag : table , userid: localStorage.getItem('userid') , category : cartRedux[i].category , ordernum : cartRedux[i].ordernum, ordercnt : cartRedux[i].ordercnt , orderstate : '주문', ispayed : false , crtdt : crtdt }
        }
        
        var send = { data : data}

        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/setorder', send)
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            const temp = [] ; 
            dispatch(setCart(temp));

        })
        .catch((error) => {
            alert("setorder error occured!!! Please check console log (F12) ")
            console.log(error)
        })

        alert ( '주문하였습니다. 전체 주문내역으로 이동합니다. '); 

        navigate(`/foodorderedlist/${table}`, { replace: false })

    }

    const itemPlus = (i) =>{

        calTotal(i,cartRedux[i].ordercnt + 1 ) ;
    }

    const itemMinus = (i) =>{

        if (  cartRedux[i].ordercnt <= 1 ) return ; 

        calTotal(i,cartRedux[i].ordercnt - 1 ) ;
    }

    const calTotal = (i, cnt) => { 
    
        var temp = [ ...cartRedux]
        temp[i] = { ...temp[i] , ordercnt : cnt } ;

        var total = 0 ; 
        for( var i = 0 ; i < temp.length; i++ ){
            total += temp[i].price * temp[i].ordercnt ;
        }

        dispatch(setCart(temp));
        dispatch(setCartTotal(total));

    }

    const deleteCart = (i) => { 
        
        var temp = []

        for(var j=0 ; j < cartRedux.length ; j++) { 

            if(i !== j ) {
                temp.push(cartRedux[j])
            }

        }

        dispatch(setCart(temp)) ;

    }

    return (
        <div id="contents" className="sub"> 

            <div id="order">
                <div className="order-top">
                    <div className="order-menu">                
                            <h4 className="order-title">Cart</h4>
                            <h5 className="order-table">( 테이블 : {table} )</h5>
                            <div className="order-back">
                                <p onClick={() => navigate(-1)}>Back</p>
                            </div>
                        </div>
            

                    <div className="cart-list">

                        {cartRedux.map((a, i) => (
                            <div className="order-list__box" key={i}>
                                <div className="order-list__inner">
                                    {cartRedux[i].ispicture === 1 &&
                                        <img className="order-list__thumb" src={'/publicimg/' + cartRedux[i].imageurl} />
                                    }
                                    <div className={cartRedux[i].ispicture === 1? "order-list__text" : "order-list__text on"} >
                                        <h5>{cartRedux[i].title}</h5>
                                        <p>{cartRedux[i].description}</p>
                                        <ul>
                                            <li className="order-list__btn">
                                                <button onClick={()=>itemMinus(i)}>-</button>
                                                    <p>{cartRedux[i].ordercnt}</p>
                                                <button  onClick={()=>itemPlus(i)}>+</button>
                                            </li>
                                            <li className="order-list__price"><b>{(cartRedux[i].price*cartRedux[i].ordercnt).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>원</li>
                                            
                                        </ul>
                                    </div>
                                    <span className="order-list__trashcan" onClick={() => deleteCart(i)} ><FaTrashAlt /></span>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
 
            </div>

            <div className="order-bottom">
                 <div className="order-bottom__info">
                    <input type="text" onChange={onChangeMessage} placeholder="직원호출시 전달할 메세지" />
                    <button onClick={onGuestRequest}>직원호출</button>
                </div>
                <button className="order-cart" onClick={onOrder}>
                    <span className="order-cart__count">{cartRedux.length}</span>
                    주문하기
                    <span className="order-cart__total"><b>
                        {cartRedux.map((x)=>(x.price*x.ordercnt)).reduce((a,b)=>(a+b),0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </b>원</span>
                </button>
            </div>

            {toast && <Toast setToast={setToast} text="주문하였습니다. " />}

         
        </div>
    )
}