import Calendar from "../components/Calendar"
import { useEffect, useState } from "react";
import OneArtist from "../components/OneArtist";
import OneTeam from "../components/OneTeam";
import MyTeamSetup from "../pages/MY/MyTeamSetup";
import axios from "axios";
import styled from "styled-components";
import OneArtistAndTeam from "../components/OneArtistAndTeam";


export default function AdminSchedule() {
    const today = new Date();
    const [selecedDate, setSelectedDate] = useState({ yyyy: today.getFullYear(), mm: today.getMonth() + 1, dd: today.getDate() });
    const [oneArtistOpen, setOneArtistOpen] = useState(false);
    const [oneTeamOpen, setOneTeamOpen] = useState(false);
    const [oneArtistAndTeamOpen, setOneArtistAndTeamOpen] = useState(false);
    const [isTeamMember, setIsTeamMember] = useState(false);
    const [isImsiTeam, setIsImsiTeam] = useState(false);

    const [tick, setTick] = useState(0);

    const [teamMembers, setTeamMembers] = useState([]);

    //const [currentDate, setCurrentDate] = useState(`${selecedDate.yyyy}-${selecedDate.mm.toString().padStart(2,'0')}-${selecedDate.dd.toString().padStart(2,'0')}` ); 

    const [selectedBu, setSelectedBu] = useState(0);

    // const [isEdit, setIsEdit] = useState([true,true,true]) ; 

    const baseData = [
        { yyyy: '0000', mm: '00', dd: '00', bu: 0, starthh: '18', startmm: '00', endhh: '20', endmm: '00', userid: '', name: '', teamname: '', profileimgurl: '', type: '', profileimgurl: '', isEdit: true },
        { yyyy: '0000', mm: '00', dd: '00', bu: 1, starthh: '20', startmm: '30', endhh: '23', endmm: '00', userid: '', name: '', teamname: '', profileimgurl: '', type: '', profileimgurl: '', isEdit: true },
        { yyyy: '0000', mm: '00', dd: '00', bu: 2, starthh: '23', startmm: '00', endhh: '01', endmm: '30', userid: '', name: '', teamname: '', profileimgurl: '', type: '', profileimgurl: '', isEdit: true }
    ];

    const [dbData, setDbData] = useState([]);


    const [addMember, setAddMember] = useState([]);
    


    useEffect(() => {

        onChangeSeletedDate();
        //setDbData(tempArray);      

    }, [selecedDate])



    // 자식에서 호출하는 날짜 변경 함수 
    const changeDate = (yyyy2, mm2, dd2) => {

        setSelectedDate({ ...selecedDate, yyyy: yyyy2, mm: mm2, dd: dd2 })


        // setCurrentDate( yyyy2 + '-' + mm2.toString().padStart(2,'0') + '-' + dd2.toString().padStart(2,'0') ); 
    }



    const onChangeSeletedDate = () => {
        const data = { yyyy: selecedDate.yyyy, mm: selecedDate.mm, dd: selecedDate.dd }

        //setDbData(baseData); 
        // setIsEdit([true,true,true]) ; 
        const tempArray = [];
        for (var i = 0; i < baseData.length; i++) {

            const temp = { ...baseData[i], yyyy: data.yyyy, mm: data.mm, dd: data.dd };
            tempArray[i] = temp;
        }

        console.log("time start 1 = " + new Date().getTime())
        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getschedule', data)
            .then((result) => {


                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }


                for (var i = 0; i < result.data.length; i++) {
                    const temp = result.data[i];
                    tempArray[result.data[i].bu] = temp;

                }
                setDbData(tempArray);
                console.log("time end 1 = " + new Date().getTime())

            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }


    const onChangeStarthh = (e, i) => {
        const temp = { ...dbData[i], starthh: e.target.value }
        const tempArray = [...dbData];
        tempArray[i] = temp;
        setDbData(tempArray);
        //        editModeBool(i , false) ;
    }
    const onChangeStartmm = (e, i) => {
        const temp = { ...dbData[i], startmm: e.target.value }
        const tempArray = [...dbData];
        tempArray[i] = temp;
        setDbData(tempArray);
        //      editModeBool(i) ;
    }
    const onChangeEndhh = (e, i) => {
        const temp = { ...dbData[i], endhh: e.target.value }
        const tempArray = [...dbData];
        tempArray[i] = temp;
        setDbData(tempArray);
        //    editModeBool(i) ;
    }
    const onChangeEndmm = (e, i) => {
        const temp = { ...dbData[i], endmm: e.target.value }
        const tempArray = [...dbData];
        tempArray[i] = temp;
        setDbData(tempArray);
        //  editModeBool(i) ;
    }

    const onSelectArtist = (i) => {
        setSelectedBu(i);
        setOneArtistOpen(true);

    }

    const onSelectArtistAndTeam = (i) => {
        setSelectedBu(i);
        setOneArtistAndTeamOpen(true);

    }
    

    const onSelectTeam = (i) => {
        setSelectedBu(i);
        setOneTeamOpen(true);

    }


    const editModeBool = (i, bool) => {

        const tempArray = [...dbData];
        tempArray[i].isEdit = bool;
        setDbData(tempArray);
    }

    const artistClose = (userid, name, profileimgurl, isLeader, ok) => {

        if (userid === null || userid === undefined || userid === 'justclose') {
            setOneArtistOpen(false);
            return; 
        }

        if( isTeamMember === true ) {
            const temp = { userid: userid, nickname: name, profileimgurl: profileimgurl  }
            const tempArray = [...addMember];
            tempArray[tempArray.length] = temp;
            setAddMember(tempArray);


        }else {
            const temp = { ...dbData[selectedBu], userid: userid, name: name, profileimgurl: profileimgurl, type: 'ARTIST', isEdit: true }
            const tempArray = [...dbData];
            tempArray[selectedBu] = temp;
            setDbData(tempArray);

            //  editModeBool(selectedBu,true) ;

        }

        setOneArtistOpen(false);

    }

    const teamClose = (teamname, nicknames, profileimgurl, team) => {


        if (teamname !== null && teamname !== undefined && teamname !== 'justclose') {
            const temp = { ...dbData[selectedBu], name: teamname, teamname: teamname, profileimgurl: profileimgurl, type: 'TEAM', isEdit: true }
            const tempArray = [...dbData];
            tempArray[selectedBu] = temp;
            setDbData(tempArray);

            //    editModeBool(selectedBu,true) ;
        }

        setOneTeamOpen(false);

    }

    const artistAndTeamClose = (userid, nickname, profileimgurl, type) => {


        if (userid !== null && userid !== undefined && userid !== 'justclose') {
            const temp = { ...dbData[selectedBu], name: userid, teamname: nickname, profileimgurl: profileimgurl, type: type, isEdit: true }
            const tempArray = [...dbData];
            tempArray[selectedBu] = temp;
            setDbData(tempArray);

            //    editModeBool(selectedBu,true) ;
        }

        setOneArtistAndTeamOpen(false);

    }

    

    const teamSetupClose = () => { 

        setIsImsiTeam(false); 

    }

    const onSave = (i) => {


        if (dbData[i].name === '') {
            alert('Artist를 선택해야 합니다.  ')
            return;
        }



        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/saveschedule', dbData[i])
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
                alert('등록되었습니다. ')
                editModeBool(i, false);
                setTick(tick + 1);


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })
    }


    const onDelete = (i) => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/deleteschedule', dbData[i])
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                alert('삭제되었습니다. ')
                onChangeSeletedDate();
                setTick(tick - 1);
            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }


    const onTeamMemberChange = (i) => {

        setSelectedBu(i);
        setIsTeamMember(true);

        axios.get(process.env.REACT_APP_DB_HOST + `/api/user/getteammembers/${dbData[i].teamname}`)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                //setTeamMembers(result.data);

                const data = {  yyyy : selecedDate.yyyy , mm : selecedDate.mm, dd : selecedDate.dd , bu : i }

                axios.post(process.env.REACT_APP_DB_HOST + `/api/user/getaddmember` , data )
                .then((addresult) => {
        
                    if (addresult.data.errno !== undefined) {
                        alert(addresult.data.message);
                        return;
                    }
        
                    var tempPlus = [] 
                    var tempMinus = [...result.data] 
                    for( var i = 0 ; i < addresult.data.length ; i++) { 
                        if( addresult.data[i].plusminus === 'MINUS') { 
                            for( var j = 0 ; j < result.data.length ; j++ ) { 
                                if( result.data[j].userid === addresult.data[i].userid ) {
                                    
                                    tempMinus[j].isMinus = true ; 
                                    break; 
                                }
                            }
                        }else if( addresult.data[i].plusminus === 'PLUS') { 
                           tempPlus.push( addresult.data[i] ) ; 
                        }
       
                    }
                    setTeamMembers(tempMinus) ;         
                    setAddMember(tempPlus);
                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
        
                    





            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })




    }

    const onCloseTeammember = () => { setIsTeamMember(false); }

    const onMinusMember = (i) => { 

        const tempArray = [...teamMembers]
        tempArray[i].isMinus = !tempArray[i].isMinus;
        setTeamMembers(tempArray); 

    }

    const invitation = () => {

        setOneArtistOpen(true);

     }

     const onAddMemberSave = () => { 
       

        const temparray = [] ;

        // 멤버 추가 
        for(var i = 0 ; i < addMember.length ; i++ ) {

            const temp = { userid : addMember[i].userid, plusminus : 'PLUS' }
            temparray.push(temp); 
        }

        // 멤버 삭제 
        const minusmember = [...teamMembers.filter( (el) => el.isMinus === true)] 
        for( i = 0 ; i < minusmember.length ; i++ ) {

            const temp = { userid : minusmember[i].userid, plusminus : 'MINUS' }
            temparray.push(temp); 
        }


        const data = {  yyyy : selecedDate.yyyy , mm : selecedDate.mm, dd : selecedDate.dd , bu : selectedBu , teamname : dbData[selectedBu].teamname , members : temparray}

        axios.post(process.env.REACT_APP_DB_HOST + `/api/user/addmember` , data)
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            alert('저장되었습니다.') ;  

            onCloseTeammember(); 
        })
        .catch((error) => {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        })


     }

    const delAddMember = (i) => {


        const delUserId = addMember[i].userid ; 
        if( !window.confirm( "정말 삭제하시겠습니까?" ) ) return ; 

        setAddMember ( addMember.filter( (el) => el.userid !== delUserId))

    }

    const imsiBand = () => {

        setIsImsiTeam(true); 
    }

    return (
        <div id="adm-inner">

            <h2 id="adm-title">공연일정관리</h2>

            <div className="adm-sch__cancel">
                <ul>
                    <li>공연 등록 완료</li>
                    <li>공연 미등록</li>
                </ul>
            </div>


            <div className="adm-calendar">
                <Calendar changeDate={changeDate} yyyy={selecedDate.yyyy} mm={selecedDate.mm} dd={selecedDate.dd} purpose='ADMIN' tick={tick} />
            </div>



            <table className="adm-tb">

                <thead>
                    <tr>
                        <th style={{width:"100px"}}>공연타임</th>
                        <th style={{width:"250px"}}>공연시간</th>
                        <th style={{width:"500px"}}>아티스트  <button className='adm-imsiteam_btn'  onClick={imsiBand} >임시 팀 생성</button> </th>
                        <th style={{width:"150px"}}>스케줄 등록/삭제</th>
                    </tr>
                </thead>
                <tbody>
                    {dbData.map((a, i) => (
                        <tr key={i}>
                            <td>{i + 1}부</td>
                            <td>
                                <ul className="adm-sch__time" >
                                    <li>
                                        <select onChange={(e) => onChangeStarthh(e, i)} value={dbData[i].starthh} disabled={!dbData[i].isEdit}>
                                            <option value="00">00</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                        </select>
                                    </li>
                                    <li>:</li>
                                    <li>
                                        <select onChange={(e) => onChangeStartmm(e, i)} value={dbData[i].startmm} disabled={!dbData[i].isEdit}>
                                            <option value="00">00</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>
                                    </li>
                                    <li>~</li>
                                    <li>
                                        <select onChange={(e) => onChangeEndhh(e, i)} value={dbData[i].endhh} disabled={!dbData[i].isEdit}>
                                            <option value="00">00</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                        </select>
                                    </li>
                                    <li>:</li>
                                    <li>
                                        <select onChange={(e) => onChangeEndmm(e, i)} value={dbData[i].endmm} disabled={!dbData[i].isEdit}>
                                            <option value="00">00</option>
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="40">40</option>
                                            <option value="50">50</option>
                                        </select>
                                    </li>
                                </ul>
                            </td>

                            <td>

                                <div>
                                    <h4 className="adm-tb__h4">{dbData[i].name} </h4>
                                    <button className="adm-tb__btn" onClick={() => onSelectArtistAndTeam(i)} disabled={!dbData[i].isEdit}>아티스트+팀</button>
                                    <button className="adm-tb__btn" onClick={() => onSelectArtist(i)} disabled={!dbData[i].isEdit}>아티스트</button>
                                    <button className="adm-tb__btn" onClick={() => onSelectTeam(i)} disabled={!dbData[i].isEdit}>팀</button>
                                    <button className="adm-tb__btn" onClick={() => onTeamMemberChange(i)} disabled={dbData[i].isEdit || dbData[i].type !== 'TEAM'} > 팀멤버변경</button>
                                </div>
                            </td>
                            <td>
                                {dbData[i].isEdit &&
                                    <button className="adm-tb__btn" onClick={() => onSave(i)}>등록</button>
                                }
                                {!dbData[i].isEdit &&
                                    <button className="adm-tb__btn red" onClick={() => onDelete(i)}>삭제</button>
                                }


                            </td>

                        </tr>

                    ))}

                </tbody>
            </table>


            {isImsiTeam && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsImsiTeam(!isImsiTeam)}>X </h2>
                    <MyTeamSetup  close={teamSetupClose} mode='ADMIN' />
                </div>
            }


            {oneArtistOpen && <OneArtist close={artistClose} />}
            {oneTeamOpen && <OneTeam close={teamClose} />}
            {oneArtistAndTeamOpen && <OneArtistAndTeam close={artistAndTeamClose} />}

            {isTeamMember &&

                <div className="adm-tm-wrap">
                    <div className="adm-tm-box" >
                        <div>
                            <ul>
                                <li><h4> 공연 멤버 삭제 </h4></li>

                            </ul>
                            <ul >
                                {teamMembers.map((a, i) => (
                                    <li key={i}>
                                        <div className="adm-tm-box_profile" onClick={() => onMinusMember(i)}>
                                            <img src={'/publicimg/' + teamMembers[i].profileimgurl} alt='' />
                               

                                            <p>{teamMembers[i].nickname}</p>
                                            {teamMembers[i].isMinus === true  && 
                                                <h3> X </h3>
                                            }
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>


                        <div>
                            <ul>
                                <li><h4> 공연 멤버 추가 ( 사진클릭시 삭제 ) </h4></li>
                                <li><button onClick={onAddMemberSave}> 저장 </button></li>
                                <li><span onClick={onCloseTeammember}>X</span></li>
                            </ul>
                            <ul>
                                 {addMember.map((a, i) => (
                                    <li key={i}>
                                        <div className="adm-tm-box_profile"  onClick={()=>delAddMember(i)}  >
                                            <img src={'/publicimg/' + addMember[i].profileimgurl} alt='' />
                                                <p>{addMember[i].nickname}</p>
                                        </div>
                                    </li>
                                ))}


                                <li>
                                    <div className="adm-tm-box_profile">
                                        <img className="dotted" src='/img/common/user_sample.png' alt='' onClick={invitation}  />
                                        <p>팀원초대</p>
                                    </div>
                                </li>
                            </ul>



                        </div>


                    </div>

                </div>
            }



        </div>



    )


}


