//HomePop.js

import { Link } from "react-router-dom";

export default function HomePopup({ setShowMainPop }) {
  //props로 setShowMainPop을 받아서 사용
  
    const closePop = () => {
      setShowMainPop(false);
    };
  
    const closeTodayPop = () => {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
      localStorage.setItem("homeVisited", expires);
      // 현재 시간의 24시간 뒤의 시간을 homeVisited에 저장
      setShowMainPop(false);
    };


    return (
      <div className="popup-wrapper">
        <div className="main-popup">
          <div className="main-popup-close">
            <button onClick={closePop}>×</button>
          </div>
          <div className="main-popup-body"></div>
          <div className="main-popup-link">
            <a className="main-popup-link-naver" href="https://naver.me/xkIuR5ZT" target="_blank">
            </a>
            <a className="main-popup-link-catchtable" href="https://app.catchtable.co.kr/ct/shop/edls?from=share&type=VISIT_RESERVATION" target="_blank">
            </a>
          </div>
          <div className="main-popup-gap"></div>
          <div className="main-popup-todayonly">
            <input type="checkbox" id="cb" onChange={closeTodayPop}  />
            <button onClick={closeTodayPop}>오늘 하루 열지 않기</button>
          </div>
        </div>
      </div>
    );
  }