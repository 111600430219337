import { useState, useEffect, useRef } from "react";
import styled from "styled-components";




const Banner_Container = styled.div`
  overflow:hidden;
  margin : 1rem 0rem 2rem 0rem ;    
  padding : 0rem 0rem 1rem 0rem ;    
  border-bottom : solid 1px #000; 

`;

const Elements = styled.div`
display: flex;
flex-direction: row;

transition: ${props => (props.boolean ? ''  : 'transform 3s linear')};
transform: ${props =>  (props.boolean ? 'translateX(0px)' : 'translateX(-100% )')};
`;


const Banner_IMG = styled.img`
    object-position : 0px 0px; 
    object-fit : scale-down;  
    width: 100%; 

`

export default function MainBanner(){ 

    const IMG_MARGQUEE = [
        "/img/main/main_marquee.png", 
        "/img/main/main_marquee.png"
    ] ;        

    //const elementHeight = 120;
    const elementLength = 2;
    const [count, setCount] = useState(0);
    let boolean = useRef(false);

    useEffect(() => {
        const timer = setInterval(
          () => {
            if (count < elementLength - 1) {
              boolean.current = false ;
              
              setCount(count + 1);
            } else {
              boolean.current = true;
              setCount(0);
            }
          },
          boolean.current ? 10 : 3000
        );
    
        return () => {
          clearInterval(timer);
        };
      }, [count]);

      //console.log(boolean.current );
      //console.log(count);

    //{ IMG_MARGQUEE.map( (a,i)=> (<img src={`${a}`} key={i}/> ) ) }
//     <Elements count={count} boolean={boolean.current}>
//     <img src="/img/main/main_marquee.png"/>
// </Elements>               

return ( 
    <Banner_Container>
       
        <Elements count={count} boolean={boolean.current}>
            { IMG_MARGQUEE.map( (a,i)=> (<Banner_IMG src={`${a}`} key={i} /> ) ) }
        </Elements>  

    </Banner_Container>

)

}