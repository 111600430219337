import { useEffect, useRef } from 'react';

const getDayofWeek = (dateStr)=>{
    const week = ['Sunday' ,'Monday', 'Tuesday' , 'Wendsday', 'Thursday' , 'Friday', 'Saturday'  ] 
    const dayOfWeek = week[new Date(dateStr).getDay()] ; 
    return dayOfWeek ;
}



const useScript = (url, onload) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.onload = onload;

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [url, onload]);
};



const useInterval = (callback, delay) => {
  const savedCallback = useRef(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const executeCallback = () => {
      savedCallback.current();
    };

    const timerId = setInterval(executeCallback, delay);

    return () => clearInterval(timerId);
  }, []);
};

export {getDayofWeek, useScript, useInterval}