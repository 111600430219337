import '../../css/My.css'
import { Link, Routes, Route } from 'react-router-dom'
import MyArtist from './MyArtist'
import MyTeamSetup from './MyTeamSetup'
import MyTeam from './MyTeam'
import MyReservation from './MyReservation'
import SignUp from '../SignUp'
import { useState  } from 'react'
import {   useNavigate  } from 'react-router-dom';
import { useSelector  } from 'react-redux';
import { useEffect } from 'react'  



export default function MyHome() {

    const navigate = useNavigate();
    useEffect(()=>{

        if ( localStorage.getItem('userid') === null || localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '' ) {


            alert (' MY 메뉴는 로그인이 필요 합니다. ')
            sessionStorage.setItem('loginfrom','my') 
            sessionStorage.setItem('table','myreservation') 
            navigate("/login", { replace: false })

            

            
            //navigate(-1);
        }

    

    }, [] )

    const userRedux = useSelector((state)=>{return state.LoginSlice.user}) ;
    

    const [menu, setMenu] = useState([ 
                    { target : 'myreservation' , className : '' , text : 'My Reservations', type: 'USER'},
                    { target : 'myartist' , className : '' , text : 'Interested Artists', type: 'USER'},
                    { target : 'myinfo' , className : '' , text : 'Member Edit', type: 'USER'},
                    { target : 'myteamsetup' , className : '' , text : 'Band Setup', type: 'ARTIST'},
                    { target : 'myteam' , className : '' , text : 'View My Band', type: 'ARTIST'}  ]); 

    const onMenuClick = (i) =>{
        const temp = [] ; 
        for(var j = 0 ; j < menu.length ; j++){
            temp[j] = {...menu[j], className : '' }
        }
        temp[i] = {...menu[i], className : 'on' }

        setMenu(temp);
    }
 

    return (
        <div id="contents">

         <div className="sub-back">
            <p onClick={()=>navigate(-1) }>Back</p>
        </div>

            <div id="sub">
                <h4 className="main-title">MY</h4>
                <div id="sub-wrap">

                    <div id="sub-aside">
                        <ul>
                            {menu.map((a,i)=>(
                                <div key={i} >
                                  {(userRedux.usertype === 'ARTIST' || userRedux.usertype === 'ADMIN'  || userRedux.usertype === menu[i].type) &&
                                    <li onClick={()=>onMenuClick(i)}>
                                        <Link to={menu[i].target} className ={menu[i].className}>{menu[i].text}
                                        </Link>
                                    </li>
                                  }
                                </div>
                            ))}
                        </ul>
                    </div>
                
                    <div id="sub-cont">
                        <Routes> 
                            <Route path='myreservation' element = { <MyReservation/> }> </Route>  
                            <Route path='myartist' element = { <MyArtist/> }> </Route>  
                            <Route path='myinfo' element = {<SignUp mode='EDIT' title='내 정보 조회/수정'/> }> </Route>  
                            <Route path='myteamsetup' element = { <MyTeamSetup/> }> </Route>  
                            <Route path='myteam' element = { <MyTeam/> }> </Route>  
                        </Routes>
                    </div>             
                </div>    
            </div>
        </div>

    )


}


