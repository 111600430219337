import { useEffect } from "react";

export default function WayToCome() {


    const { kakao } = window;

    const KakaoMapScript = () => {
        const container = document.getElementById('myMap');
        const options = {
            center: new kakao.maps.LatLng(37.53471678925675, 126.99815578500336),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);

        let markerPosition = new kakao.maps.LatLng(
            37.53471678925675,
            126.99815578500336
          );
        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });
        marker.setMap(map);          
    }

/*
    const KakaoMapScriptParking = () => {
        const container = document.getElementById('myMapParking');
        const options = {
            center: new kakao.maps.LatLng(37.5349397610347,126.99691404209881),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);

        let markerPosition = new kakao.maps.LatLng(
            37.5349397610347,
            126.99691404209881
          );
        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });
        marker.setMap(map);          
    }
*/
    useEffect(() => {
        KakaoMapScript();
  //      KakaoMapScriptParking();
    }, []);


    return (


        <div >
            <h5 className="sub-cont__title">Way to come</h5>
            
            <div id='myMap' className="loc-map"></div>
            <div className="loc-info">
            
                <dl>
                    <dt>오시는 길</dt>
                  
                    <dd>주소: 서울특별시 용산구 이태원로 216, 2F (2F, 216, Itaewon-ro, Yongsan-gu, SEOUL)</dd>
                </dl>
                <dl>
                    <dt>주차안내</dt>
                    <dd>
                        <ul>
                            <li>발렛주차 : 서울 용산구 이태원로27나길 40 IBK기업은행 이태원  </li>
                            <li>Valet parking : 40 Itaewon-ro 27na-gil, Yongsan-gu, Seoul, IBK Industrial Bank of Korea Itaewon Branch  </li>
                            <li>한남동 공영주차장 : 서울 용산구 이태원로 224-19 </li>
                            <li>The Hannam-dong Public Parking Lot : 224-19 Itaewon-ro, Yongsan-gu, Seoul. </li>
                          
                        </ul>
                    </dd>
                </dl>
                <dl>
                    <dt>운영시간</dt>
                    <dd>
                        <ul>
                            <li>평일: 18:00 - 24:00</li>
                            <li>문의: 02-795-5701</li>
                        </ul>
                    </dd>
                </dl>
            </div>
        </div>



    )
}