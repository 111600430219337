import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { setUser } from "../store/LoginSlice"
import CryptoJS from "crypto-js";
import { setMenuToken } from "../store/LoginSlice"; 

const NaverRedirectHandler = () => {

  const { naver } = window

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSignup, setisSignup] = useState(true);
  let token = '';


  const location = useLocation();


  var naver_callback_url = "http://localhost:3000/naverlogin" ; 
  if ( process.env.REACT_APP_DB_HOST !== "" ) 
    naver_callback_url = "https://allthatjazz.kr/naverlogin" ;

  const naverLogin = new naver.LoginWithNaverId({
    clientId: 'UTXaD_xemPLK9OEfmqN9',
    callbackUrl: naver_callback_url,
    isPopup: false,
    callbackHandle: false,
  });


  const menuLogin =  useSelector((state)=>(state.LoginSlice.menuLogin)) ;

  const naverlogin = async () => {


    if (!location.hash) return;
    const token = location.hash.split('=')[1].split('&')[0];


    naverLogin.getLoginStatus(async function (status: any) {
      if (status) {
        const userid = naverLogin.user.getEmail();





        console.log('naver userid ' + userid)

        try {

    
          //const accesstoken_aes256 = CryptoJS.AES.encrypt(token, 'allthatjazzciphertext').toString();



          // 네이버id를 활용하여 user 테이블 조회 => name 이 있으면 로긴완료 ( usertype, name , snstype을 리덕스에 등록한다 )
          const user_res = await axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getuser/${userid}`)
          if (user_res.data.errno !== undefined) {
            alert(user_res.data.message);
            return;
          }


          localStorage.setItem('snstype', 'NAVER');
          localStorage.setItem('userid', userid);
          localStorage.setItem('email', userid);

          // 주문을 위한 미니 로그인 
          if( 'foodmenu' === menuLogin ) {


            dispatch(setMenuToken('true') ) ; 

            const loginfrom = sessionStorage.getItem('loginfrom') 
            const table = sessionStorage.getItem('table') 

            console.log(`FoodLogin: Table : ${table} , Email : ${userid }, userId : ${userid}`)

            //navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
            if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
              navigate(`/`, { replace: false, state: { name: user_res.data[0].name } })
            }
            else{
              navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
            }

            return; 

          }



          if (user_res.data.length === 0 ) {
            setisSignup(false);
            navigate("/join", { replace: true })
          }
          else {

            const data = { accesstoken : token , refreshtoken : 'NAVERREFRESHTOKEN' , userid : userid, snstype : 'NAVER' }

            // access token 을 insert 한다 
            const accesstoken_res = await axios.post(process.env.REACT_APP_DB_HOST+`/api/login/settoken`, data)
            if (accesstoken_res.data.errno !== undefined) {
              alert(accesstoken_res.data.message);
              return;
            }
            dispatch(setUser(user_res.data[0]));

            setisSignup(true);
            localStorage.setItem('name', user_res.data[0].name);
            localStorage.setItem('aes256', accesstoken_res.data.aes256);



            const loginfrom = sessionStorage.getItem('loginfrom') 
            const table = sessionStorage.getItem('table') 
    
            if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
              navigate(`/`, { replace: false, state: { name: user_res.data[0].name } })
            }
            else{
               navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
            }

          }


        } catch (error) {
          alert("server error occured!!! Please check console log (F12) ")
          console.log(error)
        }
      }
    })



  }




  useEffect(() => {

    naverLogin.init();
    naverlogin();

  }, [])

  return (
    <>
    </>
  )
};

export default NaverRedirectHandler;

/*
https://han-py.tistory.com/417
https://data-jj.tistory.com/53
https://hymndev.tistory.com/72
https://injekim97.tistory.com/137

*/ 