import styled from "styled-components";
import Calendar from "./Calendar";
import React, { useState , useEffect, useRef} from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setSchedulesAction } from '../store/ReservationSlice';

import axios from "axios";

const ScheduleContainer = styled.div`

  display : flex; 
  justify-content: space-between;
  width : 100%; 
  padding : 0rem 0rem ; 
  
  border-bottom : solid 1px #000 ;
  display : flex; 
  
  @media (min-width:700px) {   flex-direction: row ;   }
  @media (max-width:700px) {   flex-direction: column ;   }

`

const CalendarContainer = styled.div`

  border-top : solid 1px black;  
  @media (min-width:700px) {   width : 50%; font-size : 0.4rem ;   }
  @media (max-width:700px) {   width : 100%;  font-size : 0.5rem ;  }

`

const ArtistContainer = styled.div`

  border-top : solid 1px black;    
  border-left : solid 1px black;    
  display : flex; 
  flex-direction: column ; 

  @media (min-width:700px) {   width : 50%; font-size : 0.4rem ;   }
  @media (max-width:700px) {   width : 100%;  font-size : 0.5rem ;  border-left : none;   }
  
`

 


const MonthSelector = styled.div` 
      display : flex ; 
      flex-direction : row ;

      justify-content : space-between ; 
      padding : 0em 1em 0em 1em ; 
      background : white ; 
      font-size : 5em ; 
      line-height : 60px ; 
      border-bottom : solid 1px black; 
      

      button { font-size : 1em; background : white; }
`

const ArtistSelector = styled.div`
  display : flex; 
  flex-direction : column ; 
  width : 100% ; 
  height : 335px;

  h3{ font-size : 20px; font-weight : 300; padding: 10px; }
  
`
const ScheduleArtist = styled.div`

  cursor : pointer ; 
  display : flex;
  position : relative; 
  flex-direction: row ; 
  width : 100% ;
  font-size : 2rem; 
  border-bottom : ${(props)=>(props.i===2? 'null' : 'solid 1px black')}; 
  line-height : 117px; 

  span{ font-size : 2rem ; 
        padding : 0rem 1rem ; 
       }
  :after {content: ''; display: block; width: 15px; height: 15px; position: absolute; right: 20px; top: 20px; background: url('/img/common/hover_arrow.png')no-repeat center center / cover; -webkit-transition: all 0.3s; transition: all 0.3s;}
  :hover:after {right: 10px; top: 10px;}


`
const Order = styled.div` 
  
  span{ font-size : 1.5rem ; font-weight : 600}

`


const ArtistIMG = styled.div`


  flex-basis : 40% ;
  
  display : flex; 
  flex-direction : column ; 
  justify-content : center ; 
  
  
  div{
    
    opacity: 1; 
    line-height : 1rem ; 
    height : 10rem ; 
    width : 70%;  
    background: #C20019;          
    img  { 
          
        width : 100% ; 
        height : 100% ; 
        object-fit:cover ; 
        transition: .3s ease-in-out;

        :hover { cursor : pointer; 
              opacity: 0.9;
            }
    }
    @media (max-width:700px) {   width : 70%;     }
  }
`
const ArtistText = styled.div` 
  
  
  display: flex  ;
  margin-left : -20px; 
  flex-direction : column ;
  justify-content : center ; 
  

  h3 { font-size : 1.8rem ;  line-height : 100%;  padding : 0.5rem 1rem ;  }
  h4 { font-size : 1.5rem ;  line-height : 100%; padding : 0.5rem 1rem ;  font-weight : 300 ;  }
      
`


const ReservationContainer = styled.div ` 
     display : flex ; 
     flex-direction : row ; 
     justify-content : space-between ; 
     margin : 3em auto ; 


     h5{ border-radius : 20px ; 
         font-size : 1.8rem ;  
         width : 28rem; 
         height : auto; 
         text-align : center ; 
         background : grey ; 
         color : white ; 
         margin : auto 0px ; 
    }
`


export default function MainSchedule (props) {
  
  const today = new Date();   
  


  const dispatch = useDispatch( );  
  
    
    const [selecedDate, setSelectedDate] = useState({ yyyy:today.getFullYear() , mm: today.getMonth()+1 , dd:today.getDate()});
    const [currentDate, setCurrentDate] = useState(`${selecedDate.yyyy}-${selecedDate.mm.toString().padStart(2,'0')}-${selecedDate.dd.toString().padStart(2,'0')}` ); 
    const CalendarRef = useRef();
    const artistListRedux = useSelector((state)=>{return state.ReservationSlice.schedules}) ;
    const [ArtistList,setArtistList] = useState([ ]) ;

    useEffect (()=>{ 
      setArtistList( artistListRedux.filter((el) => el.yyyy === selecedDate.yyyy && el.mm === selecedDate.mm && el.dd === selecedDate.dd ) ) 
      
     } ,[selecedDate]);  


    useEffect(()=>{
      axios.get(process.env.REACT_APP_DB_HOST+`/api/admin/scheduleforcustomer`) 
      .then((response)=>{

          if(response.data.errno !== undefined ) {
            alert(response.data.message); 
            return ; 
          }

          // app.js 가 아닌 이곳에서 dispatch 를 하는 이유는 로딩과 동시에 데이타를 가져와야 하기 때문에다. app 에서 하면 화면에 뿌려지기전에 가져오는 것이 불가능 해서 문제 발생. 
          dispatch(setSchedulesAction(response.data));
          setArtistList( response.data.filter((el) => el.yyyy === today.getFullYear() && el.mm === today.getMonth()+1 && el.dd === today.getDate() ) ) 
      })
      .catch((error)=> {
              console.log(error) ;
      })

      

    },[])     



    // 자식에서 호출하는 날짜 변경 함수 
    const changeDate = (yyyy2,mm2,dd2) => {

      setSelectedDate( { ...selecedDate , yyyy : yyyy2, mm: mm2, dd:dd2 }) 
      setCurrentDate( yyyy2 + '-' + mm2.toString().padStart(2,'0') + '-' + dd2.toString().padStart(2,'0') ); 


      // 날짜에 해당하는 Artist List 가져오기 from Redux  
  

    }

    const prevDay = () => {

      const dt = new Date(selecedDate.yyyy , selecedDate.mm-1, selecedDate.dd-1) ;
      changeDate( dt.getFullYear() , dt.getMonth()+1, dt.getDate()); 
     //  CalendarRef.current.setSeletedDate(  ) ;
     // CalendarRef.current.setSeletedDate( dt.getFullYear() , dt.getMonth(), dt.getDate() ) ;
    }

    const nextDay = () => {

      const dt = new Date(selecedDate.yyyy , selecedDate.mm-1, selecedDate.dd+1) ;
      changeDate( dt.getFullYear() , dt.getMonth()+1, dt.getDate()); 
      //CalendarRef.current.setSeletedDate( dt.getFullYear() , dt.getMonth(), dt.getDate() ) ;
    }

    // Depricated 아티스트 선택이 아닌 전체를 보냄
    /*
    const OnSelectClick = (i) => { 
      
      const temp = {...selectPerformance} ;
      temp[i] = !temp[i] ;
      setSelectPerformance(temp) ; 



      const temp2 = [{}];
      for(var i = 0 ; i < ArtistList.length ; i++){
        if(temp[i])
        {
          temp2[i] =  ArtistList[i] 
          setSubmitData(temp2);
        }

      }

      // 선택한 공연을 넣는다. 마치 장바구니 처럼 
     
      dispatch(setArtistReservationAction(temp2));

    }
    */


     
    
    return (
        <ScheduleContainer>

          <CalendarContainer>
            <Calendar 
              Ref={CalendarRef} changeDate={changeDate} yyyy={selecedDate.yyyy} mm={selecedDate.mm} dd={selecedDate.dd} />
          </CalendarContainer>

          <ArtistContainer>
            <MonthSelector>
              <button onClick={prevDay} >〈</button>
              <h3> {currentDate} </h3>
              <button onClick={nextDay} >〉</button>
            </MonthSelector>
              <ArtistSelector>
               { ArtistList.length === 0 &&  <h3> 예정된 공연이 없습니다. </h3> }
                { ArtistList.map( (a,i) => ( 
                  <Link to={`/artistdetail/${ArtistList[i].usertype}/${ArtistList[i].userid}`} 
                     state={{ yyyy : ArtistList[i].yyyy, mm : ArtistList[i].mm, dd: ArtistList[i].dd , bu : ArtistList[i].bu }} key={i}  >
                    <ScheduleArtist  i={i}  >
                        <Order > <span> 0{ArtistList[i].bu+1} </span> </Order>  
                        <ArtistIMG><div> <img src={'/publicimg/'+ ArtistList[i].profileimgurl} alt='' /> </div> </ArtistIMG>  
                        <ArtistText> <h3> {ArtistList[i].nickname } </h3> 
                              <h4> {ArtistList[i].starthh}:{ArtistList[i].startmm} - {ArtistList[i].endhh}:{ArtistList[i].endmm}</h4></ArtistText>

                    </ScheduleArtist>  
                  </Link>
                  ))  
                }
              </ArtistSelector>
            <ReservationContainer>
            



            </ReservationContainer>

          </ArtistContainer>
        </ScheduleContainer>
      );
}