import { useState, useRef } from "react";
import { useEffect } from "react"
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setCart, setCartTotal, setSelectedCategory } from "../store/OrderSlice";
import { useParams, useNavigate } from "react-router-dom";
import Toast from "../components/Toast";
import { BsFillCartPlusFill } from "react-icons/bs";
import { setLoginFrom, setMenuLogin } from "../store/LoginSlice"; 
import styled from "styled-components";


export default function FoodMenu() {

 
    const [location, setLocation] = useState();
    const [error, setError] = useState();

    let { table } = useParams();
    const navigate = useNavigate();

    const wineLoop = [0, 0, 0, 0, 0];

    const [foodCategory, setFoodCategory] = useState([]);
    const foodCategoryRef = useRef([]); // useRef로 사용할 배열 [
    const [food, setFood] = useState([]);
    const [foodData, setFoodData] = useState([]);
    const [selectedFood, setSelectedFood] = useState(0);
    const [selectedType, setSelectedType] = useState('');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [toast, setToast] = useState(false);
    const [isWine, setIsWine] = useState(false);
    const [currentWine, setCurrentWine] = useState();
    const [currentDetail, setCurrentDetail] = useState(0);
    const [currentDetailCnt, setCurrentDetailCnt] = useState(0);

    const cartRedux = useSelector((state) => { return state.OrderSlice.cart });
    const cartTotalRedux = useSelector((state) => { return state.OrderSlice.cartTotal });

    const selectedCategoryRedux = useSelector((state) => { return state.OrderSlice.selectedCategory });

    const dispatch = useDispatch();
    const menuToken = useSelector((state)=>(state.LoginSlice.menuToken))

    useEffect(() => {


        // 위치 정보 시작 

        /*
        const { geolocation } = navigator;
        if (!geolocation) {
            setError("Geolocation is not supported.");
            return;
        }
   
        geolocation.getCurrentPosition(handleSuccess, handleError);
        */
        // 위치 정보 끝

        // 로그인 체크 - access key, user id있는지만 판단. 주문시에만 토큰 체크 

        

        if ((localStorage.getItem('aes256') === null ||
            localStorage.getItem('aes256') === undefined || 
            localStorage.getItem('aes256') === '' ) && 
            menuToken !== 'true' ) {
            alert(' 올댓재즈 회원이신가요? 간편 로그인으로 음식 주문 서비스가 가능합니다!')

            // 미니로그인을 위한 리덕스 세팅 
            dispatch(setMenuLogin('foodmenu') ) ;  
           
            sessionStorage.setItem('loginfrom', 'foodmenu')
            sessionStorage.setItem('table', table)
        
            navigate("/login", { replace: false })
        }


        getData();


    }, [])

  // Geolocation의 `getCurrentPosition` 메소드에 대한 성공 callback 핸들러
  const handleSuccess = (pos) => {
    const { latitude, longitude } = pos.coords;

    setLocation({
      latitude,
      longitude,
    });

    alert(`가로:${latitude}, 세로:${longitude}`)
  };

  // Geolocation의 `getCurrentPosition` 메소드에 대한 실패 callback 핸들러
  const handleError = (error) => {
    setError(error.message);
  };

    const getData = async () => {


        try {
            const result = await axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/getfoodcategory')
            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            setFoodCategory(result.data);
            setTitle(result.data[selectedCategoryRedux].title);
            setSelectedFood(selectedCategoryRedux);
            setSelectedType(result.data[selectedCategoryRedux].foodtype)

            const result_food = await axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/getfood')

            if (result_food.data.errno !== undefined) {
                alert(result_food.data.message);
                return;
            }
            setFood(result_food.data);

            

            const temp_food = [...result_food.data];

            for (var j = 0; j < temp_food.length; j++) {
                temp_food[j] = { ...temp_food[j], foodkey: j }
            }
            setFood(temp_food);

            console.log(JSON.stringify(temp_food,null,'\t'));
            /*
            const temp_category = [...result.data];
            const temp_food = [...result_food.data];
            var k=0; 
            for (var i = 0; i < temp_category.length; i++) {
                for (var j = 0; j < temp_food.length; j++) {
                foodData[k]    
                temp[i] = { ...temp[i], items: result_food.data.filter((el) => (el.category === foodCategory[].title)) }
            }

            setResItem(temp);

            setFilteredItem(temp.filter((el) => (el.phone.includes(searchText) || el.name.includes(searchText))))
*/

        }
        catch (error) {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }



    }


    const onCategoryClick = (i) => {
        setSelectedFood(i);
        setSelectedType(foodCategory[i].foodtype)
      //  getMenu(foodCategory[i].title);({behavior: "smooth"})
        foodCategoryRef.current[i].scrollIntoView({behavior: "smooth"});
        setTitle(foodCategory[i].title);
        dispatch(setSelectedCategory(i));
    }


    const onWineDetail = (i) => {

        setCurrentWine(food[i]);
        setCurrentDetail(i);
        setIsWine(true);
        setCurrentDetailCnt(1);

    }

    const onWineClose = (i) => {
        setIsWine(false);
    }

    const onChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    const onGuestRequest = () => {

        var crtdt = new Date().toISOString().slice(0, 19).replace('T', ' ')
        const data = { nametag: table, request: message, crtdt: crtdt }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/setguestrequest', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setMessage('');
                alert('요청하였습니다.');

            })
            .catch((error) => {
                alert("getfood error occured!!! Please check console log (F12) ")
                console.log(error)
            })
   }

    const addCart = (i) => {

        const tempObject = { ...food[i], ordercnt: 1 }
        const temp = [...cartRedux, tempObject]

        var total = 0;
        for (var i = 0; i < temp.length; i++) {
            total += temp[i].price * temp[i].ordercnt;
        }

        dispatch(setCart(temp));
        dispatch(setCartTotal(total));

        setToast(true);

    }

    const addDetailCart = () => {

        const tempObject = { ...food[currentDetail], ordercnt: currentDetailCnt }
        const temp = [...cartRedux, tempObject]

        var total = 0;
        for (var i = 0; i < temp.length; i++) {
            total += temp[i].price * temp[i].ordercnt;
        }

        dispatch(setCart(temp));
        dispatch(setCartTotal(total));

        setToast(true);
        setIsWine(false);

    }


    const onOrder = () => {

        navigate(`/foodorderedlist/${table}`, { replace: false })

    }

    const onMoveCart = () => {

        navigate(`/foodcart/${table}`, { replace: false })
    }


    const itemPlus = () => {

        setCurrentDetailCnt(currentDetailCnt + 1);
    }

    const itemMinus = () => {
        if (currentDetailCnt <= 1) return;
        setCurrentDetailCnt(currentDetailCnt - 1);
    }
 
    return (
        <div id="contents" className="sub">

                
            <div id="order">
                <div className="order-top">

                    <div className="order-menu">                
                        <h4 className="order-title">MENU</h4>
                        <h5 className="order-table">( 테이블 : {table} )</h5>
                            <button onClick={onOrder}>주문내역</button>
                    </div>
                    <div className="order-cate">
                        <ul className="sly">    
                            {foodCategory.map((a, i) => (
                                <SelectedCategory key={i} selected={i === selectedCategoryRedux ? 700 : 300}
                                    onClick={() => onCategoryClick(i)}  >{foodCategory[i].title}
                                </SelectedCategory>
                            ))}
                        </ul>

                    </div>


                    <div className="order-list">
                        {foodCategory.map((b,j) => (
                            <div key={j} ref={(el)=>(foodCategoryRef.current[j] = el)}>
                            {food.filter((el)=>(el.category === foodCategory[j].title)).map((a, i) => (
                                <div className="order-list__box" key={i}>
                                    <div className="order-list__inner">
                                        <div>
                                        {a.ispicture === 1 &&
                                            <img className="order-list__thumb" src={'/publicimg/' + a.imageurl} onClick={() => onWineDetail(a.foodkey)} />
                                        }
                                        </div>
                                        <div className={a.ispicture === 1 ? "order-list__text" : "order-list__text on"}
                                            onClick={() => onWineDetail(a.foodkey)}>
                                            <h5>{a.title}
                                                {selectedType === "와인" && <div className="order-list__winedtl"> </div>}
                                            </h5>
                                            <p>{a.description}</p>
                                            <ul>
                                                <li><b>{a.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>원</li>
                                            </ul>
                                        </div>

                                        <button className="order-list__cart" onClick={() => addCart(a.foodkey)}>
                                            <div><span><BsFillCartPlusFill /></span><p>담기</p></div></button>
                                    </div>
                                </div>
                            ))}
                            </div> ))}
                    </div>
                </div>

            </div>

            <div className="order-bottom">
                <div className="order-bottom__info">
                    <input type="text" onChange={onChangeMessage} placeholder="직원호출시 전달할 메세지" />
                    <button onClick={onGuestRequest}>직원호출</button>
                </div>
                <button className="order-cart" onClick={onMoveCart}>

                    <span className="order-cart__count">{cartRedux.length}</span>
                    <h3><BsFillCartPlusFill /> 카트 보기</h3>


                    <span className="order-cart__total"><b>
                        {cartRedux.map((x) => (x.price * x.ordercnt)).reduce((a, b) => (a + b), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </b>원</span>
                </button>
            </div>

            {toast && <Toast setToast={setToast} text="카트에 담았습니다." />}


            {isWine &&

                <div className="order-wine_wrap" >

                    <div className="order-wine_cont" >
                        <h4 onClick={onWineClose} >✗</h4>
                        {currentWine.ispicture === 1 &&
                            <img src={'/publicimg/' + currentWine.imageurl} />
                        }

                        {currentWine.ispicture !== 1 &&
                            <h1> {currentWine.category} </h1>
                        }
                        <div className='order-wine_title'>
                            <h2>{currentWine.title}</h2>
                            <h3>{currentWine.description}</h3>
                        </div>
                        <div className="order-wine_price">
                            <h2>가격</h2>
                            <h2>{currentWine.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</h2>
                        </div>
                        <div className="order-wine_price">
                            <h2>수량</h2>
                            <h2 className="order-list__btn">
                                <button onClick={() => itemMinus()}>-</button>
                                <p>{currentDetailCnt}</p>
                                <button onClick={() => itemPlus()}>+</button>
                            </h2>
                        </div>

                        {selectedType === "와인" &&
                            <div>
                                <div className="order-wine_spec">
                                    <ul>
                                        <li style={{ width: "20%" }}>아로마</li>
                                        <li style={{ width: "30%" }}>
                                            {wineLoop.map((a, i) =>
                                            (<span key={i}>{currentWine.sweet > i ? <div className="order-wine_redcircle"></div> : <div className='order-wine_whitecircle'></div>}</span>
                                            ))}</li>
                                        <li style={{ width: "20%" }}>산도</li>
                                        <li style={{ width: "30%" }}>
                                            {wineLoop.map((a, i) =>
                                            (<span key={i}>{currentWine.acid > i ? <div className="order-wine_redcircle"></div> : <div className='order-wine_whitecircle'></div>}</span>
                                            ))}</li>

                                    </ul>
                                    <ul>
                                        <li style={{ width: "20%" }}>바디</li>
                                        <li style={{ width: "30%" }}>
                                            {wineLoop.map((a, i) =>
                                            (<span key={i}>{currentWine.body > i ? <div className="order-wine_redcircle"></div> : <div className='order-wine_whitecircle'></div>}</span>
                                            ))}</li>

                                        <li style={{ width: "20%" }}>타닌</li>
                                        <li style={{ width: "30%" }}>
                                            {wineLoop.map((a, i) =>
                                            (<span key={i}>{currentWine.tanin > i ? <div className="order-wine_redcircle"></div> : <div className='order-wine_whitecircle'></div>}</span>
                                            ))}</li>
                                    </ul>
                                </div>
                                <div className='order-wine_desc'>
                                    <ul>
                                        <li style={{ width: "20%" }} className='order-wine_label' >타입</li>
                                        <li style={{ width: "80%" }}>{currentWine.redwhite}</li>
                                    </ul>
                                    <ul>
                                        <li style={{ width: "20%" }} className="order-wine_label">원산지</li>
                                        <li style={{ width: "80%" }}>{currentWine.origin}</li>
                                    </ul>
                                    <ul>
                                        <li style={{ width: "20%" }} className="order-wine_label">와이너리</li>
                                        <li style={{ width: "80%" }}>{currentWine.winery}</li>
                                    </ul>
                                    <ul>
                                        <li style={{ width: "20%" }} className="order-wine_label">품종</li>
                                        <li style={{ width: "80%" }}>{currentWine.grapetype}</li>
                                    </ul>
                                </div>
                            </div>
                        }

                        <div >

                            <button className="order-wine_cart" onClick={addDetailCart}>
                                <span>{(currentDetailCnt * currentWine.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                                <p>담기</p>
                            </button>
                        </div>

                    </div>



                </div>



            }





        </div>
    )
}


const SelectedCategory = styled.li`

font-weight: ${(props) => props.selected}; 


`
