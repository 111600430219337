import { useState, useEffect } from "react"
import axios from "axios";



export default function AdminFoodCategory() {


    const [foodCategory, setFoodCategory] = useState([]);

    useEffect(() => {


        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getallfoodcategory')
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setFoodCategory(result.data)


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }, [])

    const onSaveClick = () => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/setfoodcategory', foodCategory)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                alert('저장되었습니다. ')


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })
    }



    const onClickUp = (up) => {

        if (up === 0) return;

        const temp = [...foodCategory]
        const tempObject = temp[up];
        temp[up] = { ...temp[up - 1], ordernum: up };
        temp[up - 1] = { ...tempObject, ordernum: up - 1 };
        setFoodCategory(temp);

    }

    const onClickDown = (down) => {

        if (down === foodCategory.length) return;
        const temp = [...foodCategory]
        const tempObject = temp[down];
        temp[down] = { ...temp[down + 1], ordernum: down };
        temp[down + 1] = { ...tempObject, ordernum: down + 1 };
        setFoodCategory(temp);

    }

    const onChangeTitle = (e, i) => {

        const temp = [...foodCategory];
        temp[i] = { ...temp[i], title: e.target.value };
        setFoodCategory(temp);
    }

    const onChangeToggle = (e, i) => {

        const temp = [...foodCategory];
        temp[i].isshow = !temp[i].isshow;
        setFoodCategory(temp);
    }

    const onChangeType = (e,i) => {

        const temp = [...foodCategory];
        temp[i].foodtype = e.target.value;
        setFoodCategory(temp);
    }


    return (
        <div id="adm-inner">

            <h2 id="adm-title">음식 카테고리 관리</h2>

            <table className="adm-tb">
                <thead>
                    <tr>
                        <th></th>
                        <th>순번</th>
                        <th>카테고리 이름</th>
                        <th>종류</th>
                        <th>사용</th>
                    </tr>
                </thead>
                <tbody>

                    {foodCategory.map((a, i) => (
                        <tr key={i}>
                            <td>{foodCategory[i].ordernum}</td>
                            <td>
                                <ul className="adm-seq">
                                    <li><button type="button" title="up" onClick={() => onClickUp(i)}>▲</button></li>
                                    <li><button type="button" title="down" onClick={() => onClickDown(i)}>▼</button></li>
                                </ul>
                            </td>
                            <td><input type="text" name="" id="" placeholder="카테고리 명" value={foodCategory[i].title || ''} className="w100" onChange={(e) => onChangeTitle(e, i)} /></td>

                            <td>
                                <select onChange={(e) => onChangeType(e, i)} value={foodCategory[i].foodtype}>
                                    <option value=""></option>
                                    <option value="음식">음식</option>
                                    <option value="음료">음료</option>
                                    <option value="와인">와인</option>
                                    <option value="기타주류">기타주류</option>
                                </select>

                            </td>



                            <td>
                                <label className="mypage-switch__toggle">
                                    <input type="checkbox" checked={foodCategory[i].isshow} onChange={(e) => onChangeToggle(e, i)} />

                                    <span></span>
                                </label>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>

            <button className="adm-tb__btn" onClick={() => onSaveClick()}>저장</button>
        </div>

    )





}