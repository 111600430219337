import React from 'react';
import Draggable from 'react-draggable';
import styled from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';


export default function AdminPickSeat() {

    useEffect(() => {

        /*
        const tempArray = [] ;
        for( var i = 0 ; i < 6 ; i++ ){
            const temp = { seattype : '4',  seatnumber : i , x : i*120, y : 0 , nametag :'', imageurl : '/img/common/4seat.png' , width : '50px' , height : '50px'   }
            tempArray.push(temp)
        } 
        for( var i = 0 ; i < 6 ; i++ ){
            const temp = { seattype : 'L4',  seatnumber : i , x : i*120, y : 0 , nametag :'', imageurl : '/img/common/4lseat.png' , width : '50px' , height : '50px'   }
            tempArray.push(temp)
        } 
        for( var i = 0 ; i < 5 ; i++ ){
            const temp = { seattype : 'L2',  seatnumber : i , x : i*120, y : 0 , nametag :'', imageurl : '/img/common/2lseat.png' , width : '25px' , height : '50px'   }
            tempArray.push(temp)
        } 
        for( var i = 0 ; i < 5 ; i++ ){
            const temp = { seattype : 'P2',  seatnumber : i , x : i*120, y : 0 , nametag :'' , imageurl : '/img/common/2pseat.png' , width : '50px' , height : '25px'   }
            tempArray.push(temp)
        } 
        */

        axios.get(process.env.REACT_APP_DB_HOST+'/api/admin/getallseats')
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }


                setSeats(result.data);

            })
            .catch((error) => {
                //  alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })




    }, [])


    const [seats, setSeats] = useState([]);

    // 업데이트 되는 값을 set 해줌
    const trackPos = (data, i) => {
        const temp = { ...seats[i], x: data.x, y: data.y };
        const tempArray = [...seats];
        tempArray[i] = temp;
        setSeats(tempArray);
    }


    const onSaveClick = () => {



        axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/saveseats', seats)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }


                //setArtistData(result.data.data); 
                alert('저장 하였습니다');

            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })



    }

    const onChangeSeats = (e, i) => {
        const temp = { ...seats[i], nametag: e.target.value }
        const tempArray = [...seats];
        tempArray[i] = temp;
        setSeats(tempArray);
    }

    const onChangePrice = (e, i) => {
        const temp = { ...seats[i], price: e.target.value }
        const tempArray = [...seats];
        tempArray[i] = temp;
        setSeats(tempArray);
    }
    
    return (
        <Building>

            <Warehouse>
                <h3>의자창고</h3>
                <div>
                    {seats.map((a, i) => (
                        <Draggable onDrag={(e, data) => trackPos(data)} onStop={(e, data) => trackPos(data, i)} key={i} grid={[10, 10]} defaultPosition={{ x: seats[i].x, y: seats[i].y }}>
                            <Box className="box" width={seats[i].width} height={seats[i].height}>
                                <img src={seats[i].imageurl} />
                                <input type='text' value={seats[i].nametag} onChange={(e) => onChangeSeats(e, i)} />
                                {/*<span><input type='text' value={seats[i].price} onChange={(e) => onChangePrice(e, i)} /></span>*/}
                            </Box>
                        </Draggable>
                    ))}
                </div>

            </Warehouse>
            <button onClick={onSaveClick}>저장</button>
            <Restorant>
                <h3>홀</h3>
                <Restorant_table ></Restorant_table>
            </Restorant>



        </Building>
    );

}


const Building = styled.div`
    display : flex ; 
    flex-direction : column ; 
    button { font-size : 2rem ; height : 50px; color : white; background : black ; width : 200px; margin : 0px 30px ;  }
    
`

const Warehouse = styled.div`

    display : flex; 
    flex-direction : column; 
    margin : 2rem 3rem; 
    padding : 1rem ; 
    border : solid 1px black; 
    border-radius : 10px ;
    height : 110px; 

    
    
    div{

        display : flex; 
        flex-direction : row; 
        margin : 5px 0px; 
    }
`

const Box = styled.div`
    position: absolute;
    cursor: move;
    color: black;
    width: ${(props) => (props.width)}px ;
    border-radius: 5px;
    user-select: none;
    margin : 0rem 10px ; 
    input { font-size: 10px; background: #F1F1F1; position: absolute; top: ${(props) => (props.height/20)}px; left :  ${(props) => (props.width/4)}px ; text-align : center ; width: 20px; height: 15px ;  padding : 0px; ; z-index:2;  }

    span {
        input { font-size: 8px; background: #F1F1F1; position: absolute; top: ${(props) => (props.height/20+15)}px; left :  ${(props) => (props.width/4-7)}px ; text-align : center ; width: 36px; height: 15px ;  padding : 0px; ; z-index:2; color:#C20019; 
    
        background-color:transparent;
        }
    }

    img { width : ${(props) => (props.width)}px ; height : ${(props) => (props.height)}px ; }
    z-index : 1 ; 
`



const Restorant = styled.div`
    position : relative;
    height : 600px ; 
    width : 400px; 

    margin : 2rem ; 
    padding : 1rem ; 
    border : solid 1px black; 
    border-radius : 10px ;
    background: #F1F1F1; ; 

`

const Restorant_table = styled.div`
    right : 0px; 
    position : absolute; 
    width : 90%; 
    height : 90%; 


    background: url('../img/common/restorant.png')no-repeat center right / contain;
`