import {createSlice} from '@reduxjs/toolkit'


const ArtistsSlice = createSlice(
    {   name : 'ArtistsSlice',
        initialState : { data : [{}] , team : [{}], total : [{}] , maxNumber : 16, currOrder : 'CRTDT', currInst : 'All Artist', currSearch : '' } ,

        reducers : {
            setArtists(state, action) {
                state.data = action.payload; 
            } ,
            setTeams(state, action) {
                state.team = action.payload; 
            },
            setTotal(state, action) {
                state.total = action.payload; 
            },
            setMaxNumber(state, action) { 
                state.maxNumber = action.payload; 
            },
            setCurrOrder(state, action) { 
                state.currOrder = action.payload; 
            },
            setCurrInst(state, action) { 
                state.currInst = action.payload; 
            },
            setCurrSearch(state, action) { 
                state.currSearch = action.payload; 
            }
        }
    })

export let { setArtists, setTeams, setTotal, setMaxNumber, setCurrOrder, setCurrInst, setCurrSearch} = ArtistsSlice.actions 


export default ArtistsSlice.reducer ;   
