

import './Home.css';
import Login from './pages/Login'
import Home from './Home'
import KakaoRedirectHandler from './components/KakaoRedirectHandler';
import NaverRedirectHandler from './components/NaverRedirectHandler';
import ImportRedirectHandler from './components/ImportRedirectHandler';

import SignUp from './pages/SignUp';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Reservation from './pages/Reservation';

import AdminHome from './adminpages/AdminHome';
import MyHome from './pages/MY/MyHome';
import InfoHome from './pages/INFO/InfoHome';
import FoodMenu from './pages/FoodMenu';
import FoodOrderedList from './pages/FoodOrderedList';
import FoodCart from './pages/FoodCart';

import ArtistDetail from './pages/ArtistDetail';
import ArtistList from './pages/ArtistList';
import PreReservation from './pages/PreReservation';
import Shop from './pages/Shop';
import Join from './pages/Join';


import { Route, Routes, Outlet } from 'react-router-dom';

function App() {


  const HomeTemplate = () => {
    return (
      <div>
        <div className="wrap">
          <div className="main">

            <Header />
            <Outlet />
            <Footer />
          </div>
        </div> {/* wrap */}
      </div>
    );
  }

  return (
    <>

      <Routes>
        <Route path="" element={<HomeTemplate />}>
          <Route path='/' element={<Home />}></Route>
          <Route path='reservation' element={<Reservation />} > </Route>
          <Route path='shop' element={<Shop />} > </Route>

          <Route path='my/*' element={<MyHome />}> </Route>
          <Route path='info/*' element={<InfoHome />}> </Route>
          <Route path='artistdetail/:usertype/:userid' element={<ArtistDetail />}></Route>
          <Route path='artistonlydetail/:userid' element={<ArtistDetail key='2' />}></Route>
          <Route path='artistlist' element={<ArtistList />}></Route>
          <Route path='prereservation' element={<PreReservation />}></Route>




        </Route>

        <Route path='adminhome/*' element={<AdminHome />}> </Route>
        <Route path='join' element={<Join />}></Route>
        <Route path='login' element={<Login />}></Route>
        <Route path='kakaologin' element={<KakaoRedirectHandler />}></Route>
        <Route path='naverlogin' element={<NaverRedirectHandler />}></Route>
        <Route path='importredirect' element={<ImportRedirectHandler />}></Route>
        <Route path='signup/:usertype' element={<SignUp mode='SIGNUP' />}> </Route>
        <Route path='foodmenu/:table' element={<FoodMenu />}> </Route>
        <Route path='foodorderedlist/:table' element={<FoodOrderedList />}> </Route>
        <Route path='foodcart/:table' element={<FoodCart />}> </Route>

      </Routes>

    </>
  );
}

export default App;

//    <Route path='info/*' element = { <Info/>}></Route> 
//padding: (상하) (좌우)
//padding: (상) (좌우) (하)
//padding: (상) (우) (하) (좌)


//PS C:\reactProject\allthatjazz> git branch -M main
//PS C:\reactProject\allthatjazz> git remote add origin https://github.com/jaejin0124/allthatjazz.git
//PS C:\reactProject\allthatjazz> git push -u origin main