import { useState } from "react";
import { useEffect } from "react"
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useParams , useNavigate , useLocation } from "react-router-dom";
import { setLoginFrom, setMenuLogin } from "../store/LoginSlice";


export default function FoodOrderedList() {

    let { table } = useParams();
    const navigate = useNavigate();

    const [foodOrderedList, setFoodOrderedList] = useState([]);
    const [orderTotal,setOrderTotal] = useState(0); 
    const [message, setMessage] = useState('');
    const [toast, setToast] = useState(false);

    const dispatch = useDispatch();
    const location = useLocation(); 

    useEffect(() => {


        if ( localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '' || localStorage.getItem('userid') === null ){
            alert('주문은 로그인이 필요 합니다. ') ;
            //dispatch(setLoginFrom({from :'foodorderedlist', table: table}) ) ; 

            const temp = 'foodmenu'
            dispatch(setMenuLogin(temp) ) ; 
            sessionStorage.setItem('loginfrom','foodorderlist') 
            sessionStorage.setItem('table',table) 
            navigate(`/login`, { replace: false }); 
            return ; 
        }


        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/getorderlist' , { nametag : table})
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setFoodOrderedList(result.data);

                var temp = 0 ; 
                for(var i = 0 ; i < result.data.length ; i++) {
                    if(result.data[i].orderstate !== '삭제'){
                        temp += result.data[i].price * result.data[i].ordercnt 
                    }
                }
                setOrderTotal(temp); 

            })
            .catch((error) => {
                alert("getorderedlist error occured!!! Please check console log (F12) ")
                console.log(error)
            })




    }, [])


    const onGuestRequest = () => {

        var crtdt = new Date().toISOString().slice(0, 19).replace('T', ' ')
        const data = { nametag: table, request: message, crtdt: crtdt }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/setguestrequest', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setMessage('');
                alert('요청하였습니다.');

            })
            .catch((error) => {
                alert("getfood error occured!!! Please check console log (F12) ")
                console.log(error)
            })
   }

    const onChangeMessage = (e) => {
        setMessage(e.target.value)
    }

    
    return (
        <div id="contents" className="sub">


            <div id="order">

                <div className="order-top">
                    <div className="order-menu">                
                            <h4 className="order-title">Ordered</h4>
                            <h5 className="order-table">( 테이블 : {table} )</h5>
                            <div className="order-back">
                                <p onClick={() => navigate(-1)}>Back</p>
                            </div>
                    </div>

                    <div className="cart-list">

                        {foodOrderedList.map((a, i) => (
                            <div className="order-list__box" key={i}>
                                <div className="order-list__inner">

                                <div>
                                    {a.ispicture === 1 &&
                                        <img className="order-list__thumb" src={'/publicimg/' + a.imageurl} />
                                    }
                                    </div>
                                    <div className={a.ispicture === 1 ? "order-list__text" : "order-list__text on"}>
          
                                        <h5>{foodOrderedList[i].title}</h5>
                                        <p>{foodOrderedList[i].description}</p>
                                        <ul>
                                            <li><b>{foodOrderedList[i].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>원</li>
                                        </ul>
                                        <h2>현재상태:{foodOrderedList[i].orderstate}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>


            <div className="order-bottom">

                 <div className="order-bottom__info">
                    <input type="text" onChange={onChangeMessage} placeholder="직원호출시 전달할 메세지" />
                    <button onClick={onGuestRequest}>직원호출</button>
                </div>

                <div className="order-list_btm">
                    <h4>총 주문 금액</h4>           
                    <span><b>{orderTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</b>원</span>
                </div>
            </div>


         
        </div>
    )
}