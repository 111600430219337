import styled from "styled-components"
import { useState, useRef, useEffect } from "react";
import axios from "axios" ;
import Modal from "../components/Modal"
import { useNavigate , useParams,useLocation} from "react-router-dom";




export default function SignUp (props) {

    const usertype_param = useParams(); 
    const [userType,setUserType] = useState(usertype_param.usertype); 

    const [title,setTitle] = useState(usertype_param.usertype + '회원가입');
    const location = useLocation();

    useEffect( () => {

        setUserId(localStorage.getItem('userid'));
        setEmail(localStorage.getItem('email'));
        setSnsType(localStorage.getItem('snstype'));

        var Local_userid = localStorage.getItem('userid') ; 


        // 어드민에서 다른 user 수정 
        if( props.EDITMODE === 'ADMIN')
        {
            Local_userid = props.edituserid;
            setUserId(Local_userid);
        }



        if( props.mode === 'EDIT' )
        {
            setTitle ( '회원정보수정' )
            axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getuser/${Local_userid}`) 
            .then((res)=>{


                if(res.data.errno !== undefined ) {
                    alert(res.data.message); 
                    return ; 
                }
      
                setUserType(res.data[0].usertype )
                setEmail(res.data[0].email )
                setSnsType(res.data[0].snstype )
                setPhone(res.data[0].phone); 
                setName(res.data[0].name); 
                setBirthday(res.data[0].birthday); 
                setSex(res.data[0].sex); 
                setNickname(res.data[0].nickname); 
                
                setInstruments(res.data[0].instruments.split(','));
                setVideo1(res.data[0].video1); 
                setVideo2(res.data[0].video2); 
                setVideo3(res.data[0].video3); 
                setInstagram(res.data[0].instagram); 
                setIntroduce(res.data[0].introduce); 
                setMasudong(res.data[0].masudong); 

                const temp = [] ; 
                temp[0] = res.data[0].profileimgurl ; 
                temp[1] = res.data[0].imageurl1 ;
                temp[2] = res.data[0].imageurl2 ;
                temp[3] = res.data[0].imageurl3 ;
                temp[4] = res.data[0].imageurl4 ;
                setImageurl(temp); 

            })
            .catch((error)=> {
                     console.log(error) ;
            })
        }


    },[props.mode])

// Timer Start

    const [time, setTime] = useState(0)
    const [expireAt, setExpireAt] = useState(new Date().getTime()) ; 

    const navigate = useNavigate();

    useEffect(() => {
        if( phoneNumberConfirmed ) return ;
        
        if (time > 0 && !phoneEnable) {
        const Counter = setInterval(() => {
            const gap = Math.floor(( expireAt - new Date().getTime() ) / 1000)
            setTime(gap)
        }, 1000)
        return () => clearInterval(Counter)
        }else
        {
            setTime(180); 
            setVerifiVisible(false);
            setPhoneEnable(true); 
        }
    }, [expireAt, time])

    

    const timeFormat = (time: number) => {
    const m = Math.floor((time) / 60).toString()
    let s = (time % 60).toString()
    if (s.length === 1) s = `0${s}`
    return `${m}:${s}`
    }

// Timer End 

    const [phoneEnable, setPhoneEnable] = useState(true); 
    const [verifiVisible, setVerifiVisible] = useState(false); 

    const [userId, setUserId] = useState('');
    const [email, setEmail] = useState(''); 
    const [snsType, setSnsType] = useState('');

    const [phone, setPhone] = useState(''); 
    const [yakwan, setYakwan] = useState(false); 
    const [masudong, setMasudong] = useState(false); 
    const [name,setName] = useState('') ; 
    const [birthday, setBirthday] = useState('') ; 
    const [sex, setSex] = useState('M');
    const [nickname, setNickname] = useState('');

    // 이미지 업로드 처리 
    const imageCnt = [1,2,3,4];
    const [localImageUrl, setLocalImageUrl] = useState([]); // http:// 주소 
    const [imageurl, setImageurl] = useState([null,null,null,null,null]) ;
    const [preview, setPreview] = useState([]);
    const [imageBool, setImageBool] = useState([true,true,true,true,true]);
    const pictureRef = useRef([1,2,3,4,5]);   // 버튼을 숨기고 input 에 ref 를 통해서 event 전달 용 

    const [instruments, setInstruments] = useState([]) ;
    const [video1, setVideo1] = useState('');
    const [video2, setVideo2] = useState('');
    const [video3, setVideo3] = useState('');
    
    const [instagram, setInstagram] = useState('https://instagram.com/');

    
    const [introduce, setIntroduce] = useState(''); 

    const [modalHeader, setModalHeader] = useState(''); 
    const [modalContent, setModalContent] = useState(''); 

    //const onChangePicture1 = e => { setPicture1(e.target.files[0]); };
    const onChangePhone = (e) =>{ setPhone(e.target.value)  } 
    const onChangeSex = (e) =>{ setSex(e.target.value)  } 
    const onChangeName = (e) =>{ setName(e.target.value)}
    const onChangeMasudong = (e) =>{ 
        setMasudong(!masudong)}
    const onChangeNickname = (e) =>{ setNickname(e.target.value)}
    const onChangeBirthday = (e) =>{ setBirthday(e.target.value)}
    const onChangeVideo1 = (e) =>{ setVideo1(e.target.value)}
    const onChangeVideo2 = (e) =>{ setVideo2(e.target.value)}
    const onChangeVideo3 = (e) =>{ setVideo3(e.target.value)}
    const onChangeInstagram = (e) =>{ setInstagram(e.target.value)}
    const onChangeVerification = (e) => {setVerification(e.target.value)}

    
    const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(false) ;
    const [solapiRandom, setSolapiRandom] = useState(0);
    const [verification, setVerification] = useState(0); 

    //const [instrument, setinstrument] = useState('Keyboard,Trumpet,Piano') ;

    const instBase = ['Drums','Keyboard','Bass','Trumpet','Disk Jackey','Saxophone','Vocal','Guitar','Piano','Trombone','Percussion','Harmonica','Flute','Violin','Accordion','Viola','Cello','Organ','Vibraphone'] ;
    //const instArray = instrument.split(',') ; 

 
    // 모달창 
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
      setModalOpen(true);
    };
    const closeModal = () => {
      setModalOpen(false);
    };
  

    // submit 용 에러체크 
    const checkForSubmit = () => {




        if( props.EDITMODE !== 'ADMIN' && props.mode !== 'EDIT') {
            if ( isNaN(phone) )
            {
                
                openModal () ; 
                setModalHeader('입력오류') ;
                setModalContent('휴대폰 번호를 숫자로만 입력해주세요(ex> 01012345678') ; 

                return false ; 
            }

            if ( phoneNumberConfirmed === false ) 
            {
                openModal () ; 
                setModalHeader('휴대폰 번호 검증') ;
                setModalContent('휴대폰 번호 검증이 필요합니다. ') ; 

                return false ; 

            }


            if ( yakwan === false ) 
            {
    
                openModal () ; 
                setModalHeader('약관 동의') ;
                setModalContent('[필수] 약관을 모두 동의 하셔야 합니다. ') ; 
    
                return false ; 
    
    
            }
        }


      

        if ( userId === '' || userId === null || snsType === '' || snsType === null )
        {
            
            openModal () ; 
            setModalHeader('입력오류') ;
            setModalContent('sns 로그인과 연동되지 않았습니다. ') ; 

            return false ; 
        }

        if ( name === '' || name === null )
        {
            
            openModal () ; 
            setModalHeader('입력오류') ;
            setModalContent('이름(필수)를 입력하세요. ') ; 

            return false ; 
        }
        if (checkBirthday(birthday) === false)
        {
            openModal () ; 
            setModalHeader('입력오류') ;
            setModalContent( birthday+' 생년월일(YYYYMMDD)를 확인해 주세요') ; 
            return false ; 
        }

        // 일반 유저의 경우 아래 항목은 더이상 체크하지 않음.  
        if ( userType === 'USER' ) return true; 


        if ( nickname === '' || nickname === null )
        {
            
            openModal () ; 
            setModalHeader('입력오류') ;
            setModalContent('활동이름(필수)를 입력하세요. ') ; 

            return false ; 
        }

        
        if ( instruments.length > 3 )
        {
            
            openModal () ; 
            setModalHeader('초과선택') ;
            setModalContent('대표악기는 3개까지 선택 가능합니다. ') ; 

            return false ; 
        }

        if ( imageBool[0] === true && imageurl[0] === null   ) 
        {
            openModal () ; 
            setModalHeader('필수입력') ;
            setModalContent('프로필 이미지는 반드시 필요합니다. ') ; 

            return false ; 

        }

       
        
        return true; 

    }

    const checkBirthday = (dateStr) => {

        var year = Number(dateStr.substr(0,4)); // 입력한 값의 0~4자리까지 (연)
        var month = Number(dateStr.substr(4,2)); // 입력한 값의 4번째 자리부터 2자리 숫자 (월)
        var day = Number(dateStr.substr(6,2)); // 입력한 값 6번째 자리부터 2자리 숫자 (일)
        var today = new Date(); // 날짜 변수 선언
        var yearNow = today.getFullYear(); // 올해 연도 가져옴
    
        if (dateStr.length <=8) {
        // 연도의 경우 1900 보다 작거나 yearNow 보다 크다면 false를 반환합니다.
            if (1900 > year || year > yearNow){
                return false;
            } else if (month < 1 || month > 12) {
                return false;
            } else if (day < 1 || day > 31) {
                return false;
            } else if ((month===4 || month===6 || month===9 || month===11) && day===31) {
                return false;
            } else if (month === 2) {
    
                var isleap = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
                if (day>29 || (day===29 && !isleap)) {
                    return false;
                } else {
                    return true;
                } //end of if (day>29 || (day==29 && !isleap))
            } else {
                return true;
            }//end of if
        }
        else {
            //1.입력된 생년월일이 8자 초과할때 :  auth:false
            return false;
        }
    }


    //kjj 

    

    const onPhoneNumberConfirmSend = () => {

        if ( isNaN(Number(phone) ) )
        {
            
            openModal () ; 
            setModalHeader('입력오류') ;
            setModalContent('휴대폰 번호를 숫자로만 입력해주세요(ex> 01012345678)') ; 
            

            return ; 
        }


        const data = { phonenumber : phone }
        
        axios.post(process.env.REACT_APP_DB_HOST+'/api/user/phonenumbersend', data)
        .then((result) => { 


            if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
            }

            console.log('result.data =' + result.data)

            setSolapiRandom(result.data.data); 

        })

        setVerifiVisible(true); 
        setPhoneEnable(false) ; 
        setExpireAt(new Date().getTime() + 180*1000);

    }
//kjj 
    const checkVerification = () => {

        console.log('solapiRandom = ' + solapiRandom) ;
        console.log('verification = ' + verification) ;

        if ( solapiRandom == verification )  // === 안쓰는 이유는 number 와 string 비교이기때문 
        {

            // 인증 완료 
            setPhoneNumberConfirmed( true ) ; 
                
            openModal () ; 
            setModalHeader('인증완료') ;
            setModalContent('인증되었습니다.  ') ; 

            setVerifiVisible(false);
            setPhoneEnable(false); 

            return ; 
        }
        
            // 인증 실패
            setPhoneNumberConfirmed( false ) ; 
                
            openModal () ; 
            setModalHeader('인증실패') ;
            setModalContent('인증번호가 일치하지 않습니다.') ; 


        return ; 

    }

    const submit = () => {
        if( checkForSubmit () === false ) return ; 


        // 그림 업로드 
        var j = 0 ; 
        const formArray = [] ; 

        

        for(var i=0 ; i < 5 ; i++) {
        
             // imageBool 이 false 인 것만 업로드 대상이다. 
             if(imageBool[i] === false  ) { 
                
                formArray[j] = new FormData();
                formArray[j].append("selectImg", localImageUrl[i]);
                
                j++;
            }    
        }

        const promiseArray = formArray.map( (formdata) => (axios.post(process.env.REACT_APP_DB_HOST+"/upload", formdata)) );

        // 그림이 몽땅 올라간 뒤에 
        Promise.all(promiseArray)
        .then((res) => { 
            // 1. 그림 경로 update

            const temp = [...imageurl] ;

            for(var i = 0 ; i < res.length ; i++ ) {

                for(var j = 0 ; j < imageurl.length ; j ++ ){
                    if ( temp[j] === null || temp[j] === undefined  || temp[j] === 'null' ||  temp[j]  === '' ){
                        temp[j] = res[i].data.filename ;
                        break ;        
                    }
                }

            }

            setImageurl(temp);
              
            // 2. DB 업로드 , 그림이 모두 올라가야 url 을 받아서 db update 가능 
            const dbData = {
            autopk : props.autopk,                
            userId : userId , // props.userId , 
            snsType : snsType , // props.snsType , 
            userType : userType === 'ARTIST'? 'ARTISTREQUEST' : userType, // //props.userType ,
            phone : phone , 
            name : name , 
            birthday : birthday , 
            sex : sex , 
            nickname : nickname , 
            profileimgurl : temp[0] , 
            imageurl1 : temp[1] , 
            imageurl2 : temp[2] , 
            imageurl3 : temp[3] , 
            imageurl4 : temp[4] , 
            instruments : instruments.join(',') ,
            video1 : video1 , 
            video2 : video2 , 
            video3 : video3 , 
            instagram : instagram, 
            introduce : introduce ,
            email : email, 
            click : 0 , 
            good :  0 ,
            masudong : masudong,  
            crtdt : '' 
            }
    
            console.log(dbData)
            // dbData.artistid = Math.random().toString(36).substring(2,12);
            dbData.crtdt = new Date().toISOString().slice(0,19).replace('T', ' ');
            
            axios.post(process.env.REACT_APP_DB_HOST+'/api/user/signup', dbData )
            .then((result)=>{

                if(result.data.errno !== undefined ) {
                    alert(result.data.message); 
                    return ; 
                }
    


                console.log("success")

                if ( props.mode === "EDIT"){
                    alert('수정 완료 되었습니다. ')    
                }
                else if ( userType === 'ARTIST' )  { 

                    alert('아티스트 신청이 완료되었습니다. 관리자 승인 후 이용 가능합니다. ')

                    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/requestadmin', { artist : nickname } )
                    .then((result)=>{
        
                        if(result.data.errno !== undefined ) {
                            console.log(result.data.message); 
                        }
                    })


                }else {

                    alert('완료 되었습니다.')



                    localStorage.setItem('name', name );
                    const loginfrom = sessionStorage.getItem('loginfrom') 
                    const table = sessionStorage.getItem('table') 
            
                    //navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
                    if( props.EDITMODE === 'ADMIN' )
                        props.close() ; 
                    else if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
                        navigate(`/`, { replace: false })
                    }
                    else{
                        navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: name}})
                    }


                }   



                //setModalHeader('OK')
                //setModalContent('완료 하였습니다. ')
                //openModal () ; 
                if( props.EDITMODE === 'ADMIN' )
                    props.close() ; 

                // if( props.EDITMODE !== 'ADMIN' )
                //    // navigate("/", { replace: false })
                // else                
                //     props.close() ; 

            })
            .catch((error)=> {
               // setModalHeader('서버오류발생')
                alert('회원가입 정보를 서버에 전송중 오류가 발생하였습니다. ' + error )
             //   openModal () ; 
            
                console.log(error)}) 


        })
        .catch((err)=>{
            
            alert('회원가입 정보를 서버에 전송중 오류가 발생하였습니다.  '+ err )
            console.log(err)
        } )
        


    }

        
    // 악기들 체크 박스
//    const [isChecked, setIsChecked] = useState(false); 
    //const [checkedItems, setCheckedItems] = useState(new Set()); 



    const checkedInstrument = (isChecked, item) => 
    {
        if(isChecked){
            setInstruments([...instruments, item]);
        }else if (!isChecked){
            setInstruments(instruments.filter( el=> el !== item)) ;
        }
    }
 
    

    const onPictureClick = (e,i) => {e.preventDefault(); pictureRef.current[i].click(); }



    const onDeleteClick = (e,i) => { 
        var temp = {...imageBool} ; 
        temp[i] = true ; 
        setImageBool(temp); 
    
        var temp2 = [...imageurl] ; 
        temp2[i] = null ; 
        setImageurl(temp2) ; 
    
    }

    const encodeFileToBase64 = (e, i ) => {
        
        const temp2 = {...localImageUrl} ; 
        temp2[i] = e.target.files[0] ; 
        setLocalImageUrl(temp2 ) ;

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        //console.log(fileBlob);

        return new Promise((resolve) => {
            reader.onload = () => {
            const temp = {...preview}
            temp[i] = reader.result ;
            setPreview(temp);
            resolve();
            };
            const temp1 = {...imageBool} ;
            temp1[i] = false ;
            setImageBool(temp1) ;
            e.target.value = '' ; 

        });
    };


    const introduceChange = (e) => { setIntroduce ( e.target.value) } ; 


    return (
        <Container> 
            <Main mode={props.mode}>
                <h3> {title} </h3>

                { props.EDITMODE !== 'ADMIN' && props.mode !== 'EDIT' && <div>
                    <h2> 휴대전화 인증<span>*</span></h2>
                    <Phone>
                        <input disabled = {!phoneEnable} style={{width:'50%'}} onChange={onChangePhone} value = {phone}></input>
                        <button disabled = {!phoneEnable} onClick={onPhoneNumberConfirmSend}>인증번호 받기</button>
                        <p>{timeFormat(time)}</p>
                    </Phone>


                    {verifiVisible && 
                    <PhoneNumberConfirm>
                        <input   onChange={onChangeVerification} placeholder="인증번호 4자리" /> 
                        <button  onClick={checkVerification} > 확인 </button>
                    </PhoneNumberConfirm>
                    }

                    <Yakwan>
                        <div>
                        <input type="checkbox"  id="yakwan_id" onChange={setYakwan } />
                        <label for="yakwan_id">[필수] 서비스 이용약관 & 개인정보 취급 <a href="https://allthatjazz.kr/publicimg/yakwan.htm" target="_blank"  rel="noopener noreferrer">보기</a></label>
                        </div>
                        <div>
                        <input type="checkbox" id="masudong_id" onChange={onChangeMasudong } />
                        <label for="masudong_id">[선택] 마케팅 수신 동의 <a href="https://allthatjazz.kr/publicimg/yakwan.htm" target="_blank"  rel="noopener noreferrer">보기</a></label>
                        </div>
                     </Yakwan>
                </div>
                
                
                
                }
                <CommonTable>
                    <thead></thead>
                    <tbody>
                    <tr>
                        <td> 이름<span>*</span></td> 
                        <td><input onChange={onChangeName} placeholder="이름을 입력해 주세요." value = {name} /> </td>
                    </tr>
                    <tr>
                        <td> 생년월일<span>*</span></td> 
                        <td><input onChange={onChangeBirthday} placeholder="생년월일 입력(Ex. 19990101)" value = {birthday}/> </td>
                    </tr>
                    <tr>
                        <td> 성별 <span>*</span></td> 
                        <td>
                            <SexRadio>
                                <li> 
                                     <input type="radio"  id='radio-man' onChange={onChangeSex} value="M" checked={sex==="M"}  />
                                     <label   htmlFor="radio-man"> 남성 </label> </li>
                                <li> <input type="radio"  id='radio-woman' onChange={onChangeSex} value="W" checked={sex==="W"}  />
                                     <label   htmlFor="radio-woman">여성 </label> </li>
                            </SexRadio>
                        </td>
                    </tr>
                    </tbody>
                </CommonTable>

            {userType === 'ARTIST' && <div>

                <h2> 활동이름<span>*</span></h2>
                <Phone>
                    <input style={{width:'100%'}} placeholder="활동이름을 입력해주세요 (특수문자 사용불가)" onChange={onChangeNickname} value={nickname}></input>
                </Phone>



                <h2> 프로필 이미지 등록<span>* 개인 홍보용으로 사용 됩니다.</span></h2>
                
                <ArtistImgDiv>
                    <div>
                        {!imageBool[0]?<img src={preview[0]} alt="" />  :  imageurl[0] !== null && imageurl[0] !== 'null' ? <img src={'/publicimg/' + imageurl[0]} alt="" /> : '' }
                        <input type="file" accept='image/*' ref={(el)=>pictureRef.current[0] = el} onChange={(e)=>encodeFileToBase64(e,0)} style={{display:'none'}} />
                        {imageBool[0]  && imageurl[0] === null ?<PictureClick onClick={(e)=>onPictureClick(e,0)}>＋</PictureClick> : ''}
                        {!imageBool[0] || imageurl[0] !== null ?<ImageDelete onClick={(e)=>onDeleteClick(e,0)}>X</ImageDelete>: ''}
                    </div>
                    
                </ArtistImgDiv>

                
                <h2>아티스트 이미지 등록 </h2>
      
                <ArtistImgDiv>
                    <div>
                    {imageCnt.map( (a,i)=> (
                        <div key={i}>
                            {!imageBool[a]?<img src={preview[a]} alt="" /> :  imageurl[a] !== null && imageurl[a] !== 'null'? <img src={'/publicimg/' + imageurl[a]} alt="" /> : ''}
                            <input type="file" accept='image/*' ref={(el)=>pictureRef.current[a] = el} onChange={(e)=>encodeFileToBase64(e,a)} style={{display:'none'}} />
                            {imageBool[a] && ( imageurl[a] === null || imageurl[a] === 'null' ) ?<PictureClick onClick={(e)=>onPictureClick(e,a)}>＋</PictureClick> : ''}
                            {!imageBool[a] || ( imageurl[a] !== null && imageurl[a] !== 'null') ?<ImageDelete onClick={(e)=>onDeleteClick(e,a)}>X</ImageDelete>: ''}
                        </div>
                    ))}
                    </div>
                  
                </ArtistImgDiv>
                
                


                <h2>대표악기설정<span>* 개인의 대표악기(최대3개)를 선정해주세요 </span></h2>
                <dl className="join-form">
					<dd>
						<ul className="join-inst">
                            {instBase.map( 
                                (a,i) => (
                                    <InstrumentCheckBox key={i} >
                                        <input type="checkbox" checked={instruments.includes(a)?true:false} id={a} value={a} onChange={(e)=>checkedInstrument(e.target.checked,e.target.value) } /><label  htmlFor={a}>{' '+a}</label></InstrumentCheckBox>
                                )
                            )}
							
							
						</ul>
					</dd>
                </dl> 

                <h2>아티스트 영상</h2>  

                <ArtistVideo >
                    <div>
                        <input type="text" onChange={onChangeVideo1} placeholder="URL 입력" value={video1} />
                    </div>
                    <div>
                        <input type="text" onChange={onChangeVideo2} placeholder="URL 입력" value={video2}/>
                    </div>
                    <div>
                        <input type="text" onChange={onChangeVideo3} placeholder="URL 입력" value={video3}/>
                    </div>

                </ArtistVideo>


                <h2>인스타 그램</h2>  

                <ArtistVideo >
                    <div>
                        <input type="text" onChange={onChangeInstagram} placeholder="인스타그램 URL 입력(https://instagram.com/ ) " value={instagram} />
                    </div>
                </ArtistVideo>                    

                <h2>아티스트 소개</h2>  
                <ArtistIntroduce>
                  <textarea cols="30" rows="10" maxLength = {500} onChange={introduceChange} placeholder="아티스트를 소개하는 글을 간단히 입력해 주세요" value={introduce} ></textarea>
                  <p> <span>{introduce.length}</span>/500</p>
                </ArtistIntroduce>


            </div>}    
                { userType === 'ARTIST' && props.mode !== 'EDIT' && 
                    <SubmitButton onClick={submit} > 회원가입 요청 </SubmitButton> }

                { userType === 'USER' && props.mode !== 'EDIT' && 
                    <SubmitButton onClick={submit} > 회원가입 </SubmitButton> }


                {  props.mode === 'EDIT' && 
                    <SubmitButton onClick={submit} > 수정완료 </SubmitButton> }     

               
                <Modal open={modalOpen} close={closeModal} header={modalHeader}>
                    {modalContent}
                </Modal>


           </Main>

        </Container>

     


    )
}


const Container = styled.div`
    display: flex ; 
    flex-direction : column ;

`

const Main = styled.div`
    margin : ${(props)=>(props.mode ==='SIGNUP' ? '10px auto' : ' 10px 20px' )} ; 
    max-width : 350px ; 
    min-width : 280px; 
    display : flex ; 
    flex-direction : column ; 
    h3{text-align:center ; font-size : 24px; margin: 30px ;   }
    h2{text-align:left ; font-size : 18px;  margin: 30px 0px 0px 0px; 
       span{ font-size :13px; color : red ; font-weight : 300} }
    span{color:red}
    wrap:nowrap;

`

const Phone = styled.div`

    display:flex; 
    flex-direction: row ; 

    justify-content: space-between;    

    input{
        font-size : 1.2em ; 
        margin : 10px 0px ; 
        padding : 10px 0px ; 
        border: solid 1px #ddd;
  
    }

    button{ width:100px; padding : 10px; margin : 10px ; color : #ebebeb ;     background: #000; }

    p{ width : 30px; margin : auto;  }
`

const PhoneNumberConfirm = styled.div`
    display:flex; 
    flex-direction: row ; 

    justify-content: left;    

    input{
        width : 10rem; 
        font-size : 1em ; 
        margin : 10px 0px ; 
        padding : 5px 0px ; 
        border: solid 1px #ddd;

    }

    button{ width:50px; padding : 10px; margin : 10px ; color : #ebebeb ; 
            background: #000;
           }

`
const Yakwan = styled.div`

    display:flex; 
    flex-direction : column ;            

    a{
        color : red ; 
        text-decoration-line: underline;

    }
    

`

const CommonTable = styled.table`
    margin : 30px 0px 0px 0px; 
    
    border-bottom: solid 1px #ebebeb;      

    tr{
        font-size : 1em;
        
        td{
            input{
                width : 100% ;
                border: solid 1px #ddd;
                padding: 10px ; 

            }
            border-top: solid 1px #ebebeb; 
            padding : 10px 0px; 
        
        }
    }

`

const SexRadio = styled.ul`
    
    display: inline-block; 
    width : 100%; 
    margin: 0rem auto ;
    li { display: inline-block; margin: 0rem 2rem ; width : 1rem ;  font-size : 1.5rem ;   }
    label { width : 4rem ; }


`

const InstrumentCheckBox = styled.li`
    height:30px; 
    width : auto ;
    display:inline-block;  
    line-height:28px;
    text-align:center ; 
    margin: 3px;
    padding: 0 13px 0 10px;
    border: solid 1px #acacac;
    border-radius: 20px; font-size: 14px;
`



const ArtistImgDiv = styled.div`
    position: relative; 

    div { 
        display:flex;
        flex-direction: row; 
        justify-content : space-between; 
        position: relative; 
        
            
        img {

            width : 80px; 
            height : 90px;
            margin : 10px 0px; 

        }

       
    }
`

const PictureClick = styled.button`

    font-size : 50px; 
    font-weight : 100;
    width : 80px;
    height : 90px; 
    color:#fff; 
    background: #acacac; 
    cursor: pointer; 
    margin : 10px 0px; 

    &:hover{
        background : #5b5b5b ;
    }

`

const ImageDelete = styled.button`
    
    position: absolute; 
    padding : 5px 10px; 
    left: 54px; top: 10px;
    background : black;
    opacity: 0.7;
    color : white; 
    :hover { opacity: 0.9; cursor:pointer }
`

const ArtistVideo = styled.div`
    display: flex; 
    flex-direction : column ; 

    div{
        display :flex;
        flex-direction : row ; 
        margin-bottom: 10px; position: relative; border-bottom: solid 1px #c1c1c1;

        input{
            width:calc(100% - 25px) !important; height: 40px; border: none; padding: 0; font-size: 14px;

        }
    }

`


const ArtistIntroduce = styled.div`

    textarea{
        width : 100%; 
        height : 150px ; 
        border :  solid 1px  #ebebeb;
        
        font-size: 14px;

    }
    p { text-align: right; color: #acacac; margin-top: 5px; }

`


const SubmitButton = styled.button`
    width:100%;  color : #ebebeb ;     background: #000; 
    height : 50px ; 
    font-size : 1.5em; 
    margin: 20px 0px ; 

    :hover{ background :  #c1c1c1}
`

