import axios from "axios";
import { useState, useEffect } from "react"
import AdminReservationHistory from "../admincomponents/AdminReservationHisotry";

export default function AdminUser(){


//<input type="checkbox" checked={instruments.includes(a)?true:false} id={a} value={a} onChange={(e)=>checkedInstrument(e.target.checked,e.target.value) } /><label  htmlFor={a}>{' '+a}</label>



const [totalMemberCount, setTotalMemberCount] = useState(0); 
const [userList, setUserList] = useState([]) ;
const [currentUser, setCurrentUser] = useState(0) ;
const [isModal , setIsModal] = useState(false); 
const [moreEnable, setMoreEnable] = useState(false); 

const [condition, setCondition] = useState({ 
    type : '이름' , 
    content : '' , 
    sex : '성별전체' , 
    snstype : '가입경로전체' , 
    lastpk : 999999999
})

const onChangeType = (e) => {
    setCondition ( {...condition , type : e.target.value } ) ; 
    setMoreEnable(false); 
}

const onChangeContent = (e) => {
    setCondition ({...condition, content : e.target.value }) ;
    setMoreEnable(false); 
}

const onChangeSex = (e) => {
    setCondition ({...condition, sex : e.target.value });
    setMoreEnable(false); 
}

const onChangeSnstype = (e) => {
    setCondition ({...condition, snstype : e.target.value });
    setMoreEnable(false); 
}


useEffect( ()=> {

    axios.get('/api/admin/gettotalmembercount')
    .then((result) => { 

          if(result.data.errno !== undefined ) {
            alert(result.data.message); 
            return ; 
          }

          setTotalMemberCount(result.data[0].cnt) ; 

    })
    .catch((error)=> {
        alert("server error occured!!! Please check console log (F12) ")
        console.log(error)
    }) 
    

    postUserData(0) ; 
} , [] )


const postUserData = (start) => {

    // 조회 버튼, User List 를 새로고침 
    if( start === 0 ) {
        condition.lastpk = 999999999 ; 
        setMoreEnable(true); 
    }
    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/postuserlist', condition)
        .then((result) => { 

            if( start === 1 ) {
                const temp = [...userList, ...result.data]
                setUserList(temp); 
            }else
            {
                setUserList(result.data); 
            }

            condition.lastpk = result.data[result.data.length-1].autopk ; 
 
            if( result.data[29].autopk === null )  setMoreEnable(false); 

        })
        .catch((error)=> {
          //  alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 
}


const close = () => {

    setIsModal(false);

}

const onDetail = (i) => {

    setCurrentUser(i)

    setIsModal(!isModal);

}


const onDelete = (i) => {


    if( !window.confirm( "삭제 시 복구 불가능합니다. 정말 삭제하시겠습니까?" ) ) return ; 

    const data = {userid : userList[i].userid }

    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/deleteuser', data)
    .then((result) => { 

        if (result.data.errno !== undefined) {
            alert(result.data.message);
            return;
        }

        // 토큰을 지운다 
        const body = { userid: userList[i].userid }
        axios.post(process.env.REACT_APP_DB_HOST+"/api/login/logout", body)
        .then((res) => {

        })
        .catch((error) => { console.log("error =" + error) })


        alert('삭제되었습니다. (화면 새로고침 필요) ')

 
    })
    .catch((error)=> {
      //  alert("server error occured!!! Please check console log (F12) ")
        console.log(error)
    }) 


}
 



    return(

        <div>

            <div className="adm-sch">
                <table>
                    <tbody>
                        <tr>
                            <th className="adm-sch__text">검색조건</th>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeType} >
                                    <option value="이름">이름</option>
                                    <option value="전화번호">전화번호</option>
                                    <option value="이메일">생년월일</option>
                                </select>
                            </td>
                            <td><input type="text" onChange={onChangeContent} placeholder="검색어를 입력해 주세요." /></td>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeSex} >
                                    <option value="성별전체">성별전체</option>
                                    <option value="남자">남자</option>
                                    <option value="여자">여자</option>
                                </select>
                            </td>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeSnstype} >
                                    <option value="가입경로전체">가입경로전체</option>
                                    <option value="KAKAO">카카오톡</option>
                                    <option value="NAVER">네이버</option>
                                    <option value="GOOGLE">구글</option>
                                </select>
                            </td>
                            <td className="adm-sch__submit">
                                <button onClick={()=>postUserData(0)}>검색</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="adm-tb__info">
                전체 회원수 :  <span> { totalMemberCount} </span>
			</div>

			<table className="adm-tb">
				<thead>
                    <tr>
                        <th>가입순서</th>    
                        <th>ID</th>
                        <th>이름</th>
						<th>생년월일</th>
						<th>전화번호</th>
						<th>성별</th>
						<th>가입일</th>
						<th>가입경로</th>
                        <th>노쇼</th>
                        <th>방문완료</th>
                        <th>예약취소</th>
                        <th>비정상카드취소</th>
                        <th>예약완료</th>
                        <th>예약히스토리</th>
                        <th>삭제</th>
					</tr>
				</thead>
				<tbody>

                    
                    {userList.map( (a,i)=>(
                        <tr  key = {i}>
                            <td>{userList[i].autopk}</td>
                            <td>{userList[i].userid}</td>
                            <td>{userList[i].name}</td>
                            <td>{userList[i].birthday}</td>
                            <td>{userList[i].phone}</td>
                            <td>{userList[i].sex}</td>
                            <td>{userList[i].crtdt}</td>
                            <td>{userList[i].snstype}</td>
                            <td>{userList[i].noshow_cnt}</td>
                            <td>{userList[i].visit_cnt}</td>
                            <td>{userList[i].cancel_cnt}</td>
                            <td>{userList[i].error_cnt}</td>
                            <td>{userList[i].done_cnt}</td>
                            <td><button className="adm-tb__btn" onClick={()=>onDetail(i)} >예약내역</button></td>
                            <td><button className="adm-tb__btn" onClick={()=>onDelete(i)} >삭제</button></td>
                        </tr>
					))}

				</tbody> 
			</table>
            <div className="adm-more__btn">
               { moreEnable === true ? <button   onClick={()=>postUserData(1)}> 더보기 </button> : null }
            </div>

            {isModal && 
            
                <AdminReservationHistory close={close} userid={userList[currentUser].userid} />
            }

        </div>


    )
}