import styled from "styled-components"
import {FiSearch} from "react-icons/fi"
import { useState , useEffect} from "react";
import Pagination from "react-js-pagination";
import { useSelector } from 'react-redux';
import '../css/Paging.css'

export default function OneArtist (props) {


    const [filteredList, setFilteredList] = useState([]); 
    const [pageList, setPageList] = useState([]); 
    
    const artistRedux = useSelector((state)=>{return state.ArtistsSlice.data}) 

    useEffect( () => {

        const temp = artistRedux ; 
        setFilteredList ( temp );
        setPageList( temp.slice( (page-1) * ITEMCOUNTPERPAGE, (page-1) * ITEMCOUNTPERPAGE + ITEMCOUNTPERPAGE )) ; 

    } , [] )

    
    const ITEMCOUNTPERPAGE = 8; 

   


    const close = (userid, nickname,  profileImgUrl) => {

        props.close(userid, nickname, profileImgUrl, false, '수락대기중'); 
    }

    const searchClick = () => {
        
    }

    const [page, setPage] = useState(1);

    const handlePageChange = (p) => {
        setPage(p);

        setPageList( filteredList.slice( (p-1) * ITEMCOUNTPERPAGE, (p-1) * ITEMCOUNTPERPAGE + ITEMCOUNTPERPAGE )) ; 
    };


    const changeSearchWord = (e)=>{
        
        
        setPage(1);

        const lowercase = e.target.value.toLowerCase() ;

        const temp = artistRedux.filter( (el) => ( el.name.toLowerCase().includes(lowercase )|| el.instruments.toLowerCase().includes( lowercase) || el.phone.toLowerCase().includes( lowercase) || el.nickname.toLowerCase().includes(lowercase)));
        setFilteredList ( temp );
        setPageList( temp.slice( (page-1) * ITEMCOUNTPERPAGE, (page-1) * ITEMCOUNTPERPAGE + ITEMCOUNTPERPAGE )) ; 
        

        

    }



    return ( 
        <WRAP>

            <Artist_Container> 

                <div><button onClick={()=> close('justclose','justclose','justclose')}>×</button></div>
                <Title> 아티스트 선택 </Title>
                <Search> <input placeholder="이름, 활동명, 전화번호로 검색해 보세요" onChange={changeSearchWord}/> <div onClick={searchClick}><FiSearch/></div></Search>
                <SubTitle>아티스트 검색결과</SubTitle>
                <TableContainer>
                        {pageList.map((a,i)=>( 
                            <div key={i} onClick={()=>close( pageList[i].userid, pageList[i].nickname, pageList[i].profileimgurl )}>
                                <div> 
                                    <img src={'/publicimg/' + pageList[i].profileimgurl} />
                                    <h4>{`${pageList[i].name} ( ${pageList[i].nickname} ) / 010-****-**** / ${pageList[i].sex}`} / {`${pageList[i].instruments}`}</h4> 
                                </div>
                                <h5></h5> 

                            </div>
                            


                        ))}
                        


                </TableContainer>

                <Pagination
                        activePage={page} 
                        itemsCountPerPage={ITEMCOUNTPERPAGE} 
                        totalItemsCount={ filteredList.length} 
                        pageRangeDisplayed={ITEMCOUNTPERPAGE}
                        prevPageText={"‹"} 
                        nextPageText={"›"}
                        onChange={handlePageChange} 
                />

                
                <DetailContainer>

                </DetailContainer>

            </Artist_Container>



        </WRAP>
        
    )

}


const WRAP = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
`

const Artist_Container = styled.div`

    div{
        text-align: right ; 
        button{ background-color: #fff; font-size : 2rem }
    }

    width: 90%;
    max-width: 500px;
    margin: 30px auto;
    padding: 16px 16px 16px 16px;
    border-radius: 0.3rem;
    background-color: #fff;
    
    overflow: hidden;
    
`
const Title = styled.h4`
    position: relative;
    
    margin : 1rem ; 
    font-size : 1.8rem; 
    text-align : center ; 
    font-weight: 700;
`
const SubTitle = styled.h4`
    margin : 2rem 0rem ; 
    font-size : 1.5rem; 
    text-align : left ; 

`

const Search = styled.div`
    display : flex; 
    flex-direction : row ; 
    justify-content : space-between; 
    
    border : solid 1px grey ; 
    border-radius : 30px;
    width : 80%; 
    margin : 1rem auto; 

    div { margin : auto 1.5rem ; 
            font-size : 2.5rem ; 
            cursor : pointer ; 
        }
    
    input { margin : auto 1rem ; outline : none; font-size : 1.5rem ; width : 90%  }
`

const TableContainer = styled.div `

    display: flex; 
    flex-direction : column ; 
    

        div{
            display: flex; 
            flex-direction : column ; 
            div { 
                img { margin : 0px 10px ; width : 36px; height : 36px ; object-fit : cover  }
                     text-align : left ; 
                     display: flex; 
                     flex-direction : row ; 
                h4{
                    font-size : 1.4rem ; 
                    font-weight : 400; 
                    line-height : 40px; 
                }
                
            }
            h5{
                    margin-left : 6rem; 
                    text-align : left ; 
                    font-size : 1.3em  ; 
                    font-weight : 300; 
            }
        }


`

const DetailContainer = styled.div `
`