import styled from "styled-components"
import { Link } from "react-router-dom"

export default function Footer() {

    return (



        <footer id="footer">
		<h5 className="ft-logo">ALL THAT JAZZ</h5>
		<table className="ft-menu">
			<tbody>
				<tr>
					<th>CATALOG</th>
					<td>
						<ul>
							<li><a href="#">퍼포먼스</a></li>
							<li><a href="#">예약</a></li>
							<li><a href="#">샵</a></li>
							<li><a href="#">메뉴</a></li>
						</ul>
					</td>
					<th>COMPANY</th>
					<td>
						<ul>
							<li>통신판매업신고 : 2023-서울용산-0335 </li>
							<li>사업자등록번호 : 399-81-02964(대표자:진낙원)</li>
						</ul>
					</td>
				</tr>
				<tr>
					<th>INFO</th>
					<td>
						<ul>

							<li><a href="#">메뉴</a></li>
							<li><a href="#">매장안내</a></li>
							<li><Link to='/info/aboutmenu' >주차안내</Link></li>
						</ul>
					</td>
					<th>CONTACT</th>
					<td>
						<ul>
							<li>주식회사 올댓재즈</li>
							<li>02 795 5701</li>
							<li>평일 18:00 - 24:00</li>
						
						</ul>
					</td>
				</tr>
				<tr>
					<td colSpan="2">&copy; 2022 ALL THAT JAZZ.  All Rights Reserved.</td>
					<th>ADDRESS</th>
					<td>서울특별시 용산구 이태원로 216, 2F (2F, 216, Itaewon-ro, Yongsan-gu, SEOUL)</td>
				</tr>
			</tbody>
		</table>
		<div className="ft-info">
			
			<h5>주식회사 올댓재즈( 사업자번호 :  399-81-02964 / 대표자 :진낙원 ) </h5>
			<h5>통신판매업신고 : 2023-서울용산-0335 </h5>	
			<h5>서울특별시 용산구 이태원로 216, 2F<br/>(2F, 216, Itaewon-ro, Yongsan-gu, SEOUL)<br/>02-795-5701 평일 18:00 - 24:00</h5>
			<p>&copy; 2022 ALL THAT JAZZ.  All Rights Reserved.</p>
		</div>
		<div className="ft-other">
			<ul className="ft-other__sns">
				<li><a href="#" target="_blank" className="ft-other__sns--fb">facebook</a></li>
				<li><a href="#" target="_blank" className="ft-other__sns--ist">instagram</a></li>
				<li><a href="#" target="_blank" className="ft-other__sns--ytb">youtube</a></li>
			</ul>
			<ul className="ft-other__privacy">
				<li><a href="/publicimg/yakwan.htm">서비스이용약관 </a></li>
				<li><a href="/publicimg/yakwan.htm">개인정보취급방침</a></li>
			</ul>
		</div>
	</footer>
 )
}
 

const Ft_Logo = styled.h5`

    

    @media (min-width:600px) {  display: block; margin-right: 50px; width: 63px; height: 85px; background: url('/img/common/footer_logo.png')no-repeat center center / cover; }
    @media (max-width:600px) {  display: none;   }

`

const Ft_Info = styled.div`


    @media (min-width:600px) {  font-size: 12px; font-weight: 400; }
    @media (max-width:600px) {   font-size: 11px;  }

`

const Footer_Container = styled.div`
    display:flex; 
    flex-direction: row ; 
    justify_content : space-between ; 
    width : 100% 

`



const Footer_Facebook = styled.a`width: 20px; height: 100%; background: url('/img/common/footer_sns_facebook.png')no-repeat center center / cover;  `
const Footer_Instagram = styled.a`width: 13px; height:  100%; background: url('/img/common/footer_sns_instagram.png')no-repeat center center / cover;`
const Footer_Youtube = styled.a`width: 13px; height:  100%; background: url('/img/common/footer_sns_youtube.png')no-repeat center center / cover;`

const Sns_Container = styled.div`
    display : flex ; 
    flex-direction : column ; 
    justify-content : space-between;
    padding : 0rem 1rem;
    align-item : right ; 
`

const Sns_Block = styled.ul `
    display : flex ; 
    flex-direction : row ; 
    justify-content : space-between; 
    height : 90%; 
    
    
    `

const Logo_Container = styled.div`
    display: flex; 
    flex-direction : row ; 
    justify-content : space-between;

`