import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch , useSelector} from 'react-redux';
import { SignUp } from '../pages/SignUp'
import {setUser} from "../store/LoginSlice"
import CryptoJS from "crypto-js";
import { setMenuToken } from "../store/LoginSlice"; 

const KakaoRedirectHandler = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch( );  
  //const loginRedux = useSelector((state) => { return state.LoginSlice.loginFrom });
  

  const [isSignup, setisSignup] = useState(true);
  let token = '';

  const menuLogin =  useSelector((state)=>(state.LoginSlice.menuLogin)) ;
  

  const kakaologin = async () => {

    let params = new URL(document.location.toString()).searchParams;
    let authcode = params.get("code"); // 인가코드 받는 부분
    console.log("kjj react authcode = " + authcode)

    try {


      // 카카오를 통해서 access token 과 user id 를 가져온다. 
      const kakao_res = await axios.get(process.env.REACT_APP_DB_HOST+`/api/login/kakaoaccesstoken/${authcode}`)

      if (kakao_res.data.errno !== undefined) {
        alert(kakao_res.data.message);
        return;
      }

      // const accesstoken_aes256 = CryptoJS.AES.encrypt(kakao_res.data.accesstoken, 'allthatjazzciphertext').toString();
  


      console.log('kakao_res.data.userid = ' + kakao_res.data.userid)

      // 카카오 id를 활용하여 user 테이블 조회 => name 이 있으면 로긴완료 ( usertype, name , snstype을 리덕스에 등록한다 )
      const user_res = await axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getuser/${kakao_res.data.userid}`)
      if (user_res.data.errno !== undefined) {
        alert(user_res.data.message);
        return;
      }

      localStorage.setItem('snstype', 'KAKAO');
      localStorage.setItem('userid', kakao_res.data.userid);
      localStorage.setItem('email', kakao_res.data.email);     

      // 주문을 위한 미니 로그인 
      if( 'foodmenu' === menuLogin ) {


        dispatch(setMenuToken('true') ) ; 

        const loginfrom = sessionStorage.getItem('loginfrom') 
        const table = sessionStorage.getItem('table') 

        console.log(`FoodLogin: Table : ${table} , Email : ${kakao_res.data.email }, userId : ${kakao_res.data.userid}`)

        //navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
        if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
          navigate(`/`, { replace: false, state: { name: user_res.data[0].name } })
        }
        else{
           navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
        }

        return; 

      }
    


      if (user_res.data.length === 0  ) {
        setisSignup(false);
        navigate("/join", { replace: true })
      }
      else {

        dispatch(setUser(user_res.data[0]));

        setisSignup(true);
        localStorage.setItem('name', user_res.data[0].name );
        localStorage.setItem('aes256', kakao_res.data.accesstoken);
   
        
        const loginfrom = sessionStorage.getItem('loginfrom') 
        const table = sessionStorage.getItem('table') 

        //navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
        if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
          navigate(`/`, { replace: false, state: { name: user_res.data[0].name } })
        }
        else{
           navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
        }
        

      }


    }catch(error) {
      alert("kakaoaccesstoken2 server error occured!!! Please check console log (F12) ")
      console.log(error)
    }

  }




useEffect(() => {

  kakaologin();

}, [])

  return (
  <>
  </>
  )
};

export default KakaoRedirectHandler;

/*
https://han-py.tistory.com/417
https://data-jj.tistory.com/53
https://hymndev.tistory.com/72
https://injekim97.tistory.com/137

*/ 