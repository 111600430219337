import styled from "styled-components"
import { useState, useEffect } from "react"
import axios from "axios"


export default function AdminFood() {

    const [foodCategorys, setFoodCategorys] = useState([]);
    const [food, setFood] = useState([]);
    const [currentCategory, setCurrentCategory] = useState('null');
    const [currentType, setCurrentType] = useState('null');


    //const [localImageUrl, setLocalImageUrl] = useState([]); 
    //const [preview, setPreview] = useState([]);
    //const [ispreview, setIspreview] = useState([]);

    useEffect(() => {


        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getfoodcategory')
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
                setFoodCategorys(result.data);


            })
            .catch((error) => {
                alert("getfoodcategory error occured!!! Please check console log (F12) ")
                console.log(error)
            })


    }, [])


    const onChangeToggle = (e, i) => {

        const temp = [...food];
        temp[i].isshow = !temp[i].isshow;
        setFood(temp);
    }

    const onChangePicToggle = (e, i) => {

        const temp = [...food];
        temp[i].ispicture = !temp[i].ispicture;
        setFood(temp);
    }

    const onSaveClick = () => {



        for (let i = 0; i < food.length; i++) {

            if (food[i].ispreview) {
                let formdata = new FormData();
                formdata.append("selectImg", food[i].localImageUrl);
                axios.post(process.env.REACT_APP_DB_HOST + "/upload", formdata)
                    .then((res) => {

                        if (res.data.errno !== undefined) {
                            alert(res.data.message);
                            return;
                        }

                        // 1. 그림 경로 update
                        const temp = [...food];

                        temp[i].imageurl = res.data.filename;
                        delete temp[i].preview;
                        delete temp[i].ispreview;
                        delete temp[i].localImageUrl;
                        setFood(temp);


                        // Food[i] 는 상태 업데이트가 되지 않아 temp 를 활용  
                        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/setFood', temp[i])
                            .then((result) => {

                                if (result.data.errno !== undefined) {
                                    alert(result.data.message);
                                    return;
                                }

                                const temppre = [...food];
                                temppre[i].ispreview = false;
                                setFood(temppre);


                            })
                            .catch((error) => {
                                alert("server error occured!!! Please check console log (F12) ")
                                console.log(error)
                            })

                    })
            }
            else {

                axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/setFood', food[i])
                    .then((result) => {

                        if (result.data.errno !== undefined) {
                            alert(result.data.message);
                            return;
                        }


                    })
                    .catch((error) => {
                        alert("server error occured!!! Please check console log (F12) ")
                        console.log(error)
                    })

            }
        }
        alert('저장되었습니다. ');


        //navigate("/adminhome/adminFood", { replace: true })

    }



    const onChangeTitle = (e, i) => {

        const temp = [...food];
        temp[i] = { ...temp[i], title: e.target.value };
        setFood(temp);
    }

    const onChangeDescription = (e, i) => {

        const temp = [...food];
        temp[i] = { ...temp[i], description: e.target.value };
        setFood(temp);
    }

    const onChangePrice = (e, i) => {

        const temp = [...food];
        temp[i] = { ...temp[i], price: e.target.value };
        setFood(temp);
    }

    const onChangeSweet = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], sweet: e.target.value }; setFood(temp);
    }
    const onChangeAcid = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], acid: e.target.value }; setFood(temp);
    }
    const onChangeBody = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], body: e.target.value }; setFood(temp);
    }
    const onChangeTanin = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], tanin: e.target.value }; setFood(temp);
    }    
    const onChangeRedWhite = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], redwhite: e.target.value }; setFood(temp);
    }
    const onChangeOrigin = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], origin: e.target.value }; setFood(temp);
    }    
    const onChangeWinery = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], winery: e.target.value }; setFood(temp);
    }    
    const onChangeGrapeType = (e, i) => {
        const temp = [...food]; temp[i] = { ...temp[i], grapetype: e.target.value }; setFood(temp);
    }    





    const onClickUp = (up) => {

        if (up === 0) return;

        const temp = [...food]
        const tempObject = temp[up];
        temp[up] = { ...temp[up - 1], ordernum: temp[up].ordernum };
        temp[up - 1] = { ...tempObject, ordernum: temp[up - 1].ordernum };
        setFood(temp);




    }

    const onClickDown = (down) => {

        if (down === food.length) return;
        const temp = [...food]
        const tempObject = temp[down];
        temp[down] = { ...temp[down + 1], ordernum: temp[down].ordernum };
        temp[down + 1] = { ...tempObject, ordernum: temp[down + 1].ordernum };
        setFood(temp);



    }

    const encodeFileToBase64 = (e, i) => {




        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);


        return new Promise((resolve) => {
            reader.onload = () => {

                const temp = [...food];
                temp[i] = { ...temp[i], localImageUrl: e.target.files[0], preview: reader.result, ispreview: true };
                setFood(temp)

                resolve();
            };

        });
    };

    const onFoodCategorys = (i) => {
        const temp = [...foodCategorys]

        for (var j = 0; j < foodCategorys.length; j++) {
            temp[j].isSelected = false;
        }
        temp[i].isSelected = true;

        setFoodCategorys(temp);
        setCurrentCategory(temp[i].title);
        setCurrentType(temp[i].foodtype);

        const data = { category: temp[i].title }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getfood', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
                setFood(result.data);

            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })



    }

    const onAddClick = () => {

        const temp = [...food]


        if (temp.length === 0) {
            temp.push({ ordernum: 1, category: currentCategory, isshow: true, ispicture: true, title: '', description: '', price: 0, sweet: 1, acid: 1, body: 1, tanin: 1 , redwhite:'레드' , origin: '' , winery : '' , grapetype : ''  })

        } else {
            temp.push({ ordernum: temp[temp.length - 1].ordernum + 1, category: currentCategory, isshow: true, ispicture: true , title: '', description: '', price: 0, sweet: 1, acid: 1, body: 1, tanin: 1  , redwhite:'' , origin: '' , winery : '' , grapetype : ''})
        }

        setFood(temp);

    }


    const onDeleteClick = (i) => {


        if (window.confirm("정말 삭제하시겠습니까? ") === false)
            return;

        const data = { category: food[i].category, ordernum: food[i].ordernum }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/delfood', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
                setFood(food.filter(el => el.ordernum !== food[i].ordernum));
            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }

    return (
        <div id="adm-inner">

            <h2 id="adm-title">음식메뉴 관리</h2>

            <div>
                {foodCategorys.map((a, i) => (
                    <CategoryBTN onClick={() => onFoodCategorys(i)} isSelected={foodCategorys[i].isSelected} key={i}>
                        {foodCategorys[i].title}
                    </CategoryBTN>
                ))}
            </div>

            <table className="adm-tb">
                <thead>
                    <tr>
                        <th style={{width:"50px"}}>Num</th>
                        <th style={{width:"50px"}}>순번</th>
                        <th style={{width:"200px"}}>이미지</th>
                        <th style={{width:"700px"}}>음식상세정보</th>
                        <th style={{width:"50px"}}>게시</th>
                        <th style={{width:"50px"}}>사진</th>
                        <th style={{width:"50px"}}>삭제</th>
                    </tr>
                </thead>
                <tbody>

                    {food.map((a, i) => (
                        <tr key={i}>
                            <td>{food[i].ordernum}</td>
                            <td>
                                <ul className="adm-seq">
                                    <li><button type="button" title="up" onClick={() => onClickUp(i)}>▲</button></li>
                                    <li><button type="button" title="down" onClick={() => onClickDown(i)}>▼</button></li>
                                </ul>
                            </td>
                            <td>
                                <div className='adm-ban__thumb'>
                                    {food[i].ispreview ? <Img src={food[i].preview} alt="" /> : <Img src={'/publicimg/' + food[i].imageurl} alt="" />}
                                    <input type="file" accept='image/*' id={'FILE' + food[i].ordernum} className="adm-ban__change" onChange={(e) => encodeFileToBase64(e, i)} />
                                    <label className='adm-ban__change_label' htmlFor={'FILE' + food[i].ordernum}>change</label>
                                </div>
                            </td>
                            <td>
                                <tr>
                                    <td><input type="text" style={{ width: '150px' }} placeholder="음식명 입력" value={food[i].title || ''} className="w100" onChange={(e) => onChangeTitle(e, i)} /></td>
                                    <td><input type="text" style={{ width: '500px' }} placeholder="설명 입력" value={food[i].description || ''} className="w100" onChange={(e) => onChangeDescription(e, i)} /></td>
                                    <td><input type="text" style={{ width: '70px' }} placeholder="음식가격" value={food[i].price || ''} className="w100" onChange={(e) => onChangePrice(e, i)} /></td>
                                </tr>
                            { currentType === '와인' && 

                                <tr className="adm-wine_tr">
                                    <td>
                                    <div>
                                        <label style={{ margin: '20px' }}>아로마</label>
                                        <select defaultValue='1' value={food[i].sweet} onChange={(e) => onChangeSweet(e, i)} style={{ width: '70px' }}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label style={{ margin: '20px' }}>산도</label>
                                        <select defaultValue='1' value={food[i].acid} onChange={(e) => onChangeAcid(e, i)} style={{ width: '70px' }}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label style={{ margin: '20px' }}>바디</label>
                                        <select defaultValue='1' value={food[i].body} onChange={(e) => onChangeBody(e, i)} style={{ width: '70px' }}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label style={{ margin: '20px' }}>타닌</label>
                                        <select defaultValue='1' value={food[i].tanin} onChange={(e) => onChangeTanin(e, i)} style={{ width: '70px' }}>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>
                                    </div>
                                    </td>
                                    <td>
                                    <div>
                                        <label style={{ margin: '0px 64px 0px 40px' }}>타입</label>
                                        <select defaultValue='1' value={food[i].redwhite} onChange={(e) => onChangeRedWhite(e, i)} style={{ width: '70px' }}>
                                            <option value="레드">레드</option>
                                            <option value="화이트">화이트</option>
                                            <option value="포트">포트</option>
                                            <option value="로제">로제</option>
                                            <option value="스파클링">스파클링</option>
                                            <option value="샴페인">샴페인</option>
                                        </select>
                                    </div>
                                    <div><label style={{ margin: '0px 52px 0px 40px' }}>원산지</label>
                                        <input type="text" style={{ width: '300px' }} placeholder="원산지 입력" value={food[i].origin || ''} className="w100" onChange={(e) => onChangeOrigin(e, i)} />
                                    </div>
                                    <div><label style={{ margin: '40px' }}>와이너리</label>
                                        <input type="text" style={{ width: '300px' }} placeholder="와이너리 입력" value={food[i].winery || ''} className="w100" onChange={(e) => onChangeWinery(e, i)} />
                                    </div>
                                    <div><label style={{ margin: '40px' }}>포도품종</label>
                                        <input type="text" style={{ width: '300px' }} placeholder="포도품종 입력" value={food[i].grapetype || ''} className="w100" onChange={(e) => onChangeGrapeType(e, i)} />
                                    </div>
                                    </td>
                                </tr>
                            }
                            </td>
                            <td>
                                <label className="mypage-switch__toggle">
                                    <input type="checkbox" checked={food[i].isshow} onChange={(e) => onChangeToggle(e, i)} />

                                    <span></span>
                                </label>
                            </td>
                            <td>
                                <label className="mypage-switch__toggle">
                                    <input type="checkbox" checked={food[i].ispicture} onChange={(e) => onChangePicToggle(e, i)} />

                                    <span></span>
                                </label>
                            </td>
                            <td>
                                <DelButton type="button" onClick={() => onDeleteClick(i)}>삭제</DelButton>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>

            {currentCategory !== 'null' && <div> <AddButton onClick={() => onAddClick()}>추가</AddButton>
                <button className="adm-tb__btn" onClick={() => onSaveClick()}>저장</button> </div>
            }
        </div>
    )

}


const Img = styled.img` 
    display: block; width: 180px; height: 120px; padding-bottom: 0px; position: relative;
    object-fit:cover ; 

`

const CategoryBTN = styled.button`

    border : solid 1px black ; 
    
    height : 30px; 
    width : 100px; 
    margin-bottom : 5px ; 
    background : ${(props) => (props.isSelected === true ? 'black' : 'white')} ;
    color : ${(props) => (props.isSelected === true ? 'white' : 'black')};


`

const AddButton = styled.button`

    margin-top : 20px ; 
    width : 100%; 
    height : 50px ; 
    background : black ; 
    color : white; 
    font-size : 20px; 

`

const DelButton = styled.button`

    width : 100%; 
    height : 30px ; 
    background : black ; 
    border-radius : 20px ; 
    color : white; 
    font-size : 12px; 

`