import {createSlice} from '@reduxjs/toolkit'


const LoginSlice = createSlice(
    {   name : 'LoginSlice',
        initialState : { user : {} , loginFrom : '', menuLogin : '', menuToken : '' } ,

        reducers : {
            setUser(state, action) {
                state.user = action.payload; 
            } ,
            setLoginFrom(state, action) {
                state.loginFrom = action.payload; 
            } ,
            setMenuLogin(state, action) {
                state.menuLogin = action.payload; 
            } ,
            setMenuToken(state, action) {
                state.menuToken = action.payload; 
            } ,
            

        }
    })

export let { setUser , setLoginFrom, setMenuLogin, setMenuToken } = LoginSlice.actions 


export default LoginSlice.reducer ;   
