import { useState , useEffect} from "react"
import axios from "axios";
import { getDayofWeek } from "../../components/Util";




export default function MyReservation() {

    let [toggleClassName,setToggleClassname] = useState('mpg-res__dtl') ;
    const [reservationList, setReservationList] = useState([]);
    const [resToggle, setResToggle] = useState([]); 


    useEffect(()=>{

        getData() ;


    },[])


    const getData = () => { 

        const data = { userid : localStorage.getItem('userid') }  ; 



        axios.post(process.env.REACT_APP_DB_HOST+'/api/reservation/getmyreservation', data)
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
              }

              setReservationList(result.data) ; 

        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
            
        }) 

    }


    const onResToggleClick = (i) => {

        const temp = [...resToggle] 
        if ( temp[i] !== true ) temp[i] = true ;
        else temp[i] = false;
        setResToggle(temp); 

       
    }


    const onCancelClick = (i) => {


        const today = new Date(); 


        if ( reservationList[i].yyyy === today.getFullYear() && reservationList[i].mm === today.getMonth()+1 && reservationList[i].dd === today.getDate() ) {

            alert("당일 예약취소는 불가능 합니다.")
            return ; 
        }



        if( !window.confirm( "정말 취소하시겠습니까?" ) ) return ; 


        const data = { 
            merchant_uid :  reservationList[i].uniquekey ,  
            cancel_request_amount: reservationList[i].amount  ,
            reason : "예약취소",
        }

        axios.post('/api/admin/cancelcard', data)
        .then((result) => { 
 
                if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
            }
                
                getData() ;
                alert('취소되었습니다. ') 

        })
        .catch((error)=> {
            alert(error.response.data)
            console.log(error)
        }) 
        
    }

    const onCheckOut = (i) => { 

    }

    return (


        <div id="mysub-cont">
            <div className="mpg-title">
                <h4>예약정보 확인</h4>

            </div>

            <ul className="mpg-res__list">
                {reservationList && reservationList.map((a,i)=>(
                <li key={i}>
                    <div className='mpg-res__toggle' onClick={()=>onResToggleClick(i)}>
                        <h5>{reservationList[i].yyyy + '-' + reservationList[i].mm.toString().padStart(2,'0') + '-' + reservationList[i].dd.toString().padStart(2,'0')}</h5>
                        <h4>{getDayofWeek(`${reservationList[i].yyyy}-${reservationList[i].mm}-${reservationList[i].dd}`)} </h4>
                        <span className="mem__status_red">{reservationList[i].status }</span>
                        {reservationList[i].status === '예약완료' && <button className="mpg-mem__btn" onClick={()=>onCancelClick(i)}>예약취소</button> }
                        {reservationList[i].status === '결제전' && <button className="mpg-mem__btn" onClick={()=>onCheckOut(i)}>결제</button> }

                    </div>
                    { resToggle[i] === true && 
                    <div className='mpg-res__dtl'>
                        <table className="mpg-res__dtl--tb">
                            <tbody>
                                <tr>
                                    <th>주문번호</th>
                                    <td>{reservationList[i].uniquekey}  </td>
                                </tr>
                                <tr>
                                    <th>예약날짜</th>
                                    <td>{reservationList[i].yyyy + '-' + reservationList[i].mm.toString().padStart(2,'0') + '-' + reservationList[i].dd.toString().padStart(2,'0') + '  ' +  getDayofWeek(`${reservationList[i].yyyy}-${reservationList[i].mm}-${reservationList[i].dd}`)}  </td>
                                </tr>
                                <tr>
                                    <th>예약타임</th>
                                    <td>{reservationList[i].tm}</td>
                                </tr>
                                <tr>
                                    <th>예약좌석</th>
                                    <td>{reservationList[i].nametag} </td>
                                </tr>
                                <tr>
                                    <th>예약금액</th>
                                    <td>{reservationList[i].amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</td>
                                </tr>
                                <tr>
                                    <th>예약생성일</th>
                                    <td>{reservationList[i].crtdt} </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="mpg-res__dtl--info">예약 변경을 원하는 경우, 기존 예매 건을 취소하고 재예약 부탁드립니다.</p>
                        {reservationList[i].status === '예약완료' && <button className="mpg-res__dtl--cancel" onClick={()=>onCancelClick(i)}>예약취소</button> }
                        {reservationList[i].status === '결제전' && <button className="mpg-res__dtl--cancel" onClick={()=>onCheckOut(i)}>결제</button> }
                    </div>
                    }
                </li>
                ))}
          
            </ul>

        </div>


    )

}