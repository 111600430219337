import styled from "styled-components";
import Calendar from "../components/Calendar";
import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setArtistReservationAction, setSchedulesAction, setSelectedDateAction } from '../store/ReservationSlice';  // 예약선택한 아티스트 
import { useNavigate } from 'react-router-dom';
import { setLoginFrom } from "../store/LoginSlice";
import axios from "axios";



export default function PreReservation(props) {

    const today = new Date();
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [selecedDate, setSelectedDate] = useState({ yyyy: today.getFullYear(), mm: today.getMonth() + 1, dd: today.getDate() });
    const [currentDate, setCurrentDate] = useState(`${selecedDate.yyyy}-${selecedDate.mm.toString().padStart(2, '0')}-${selecedDate.dd.toString().padStart(2, '0')}`);
    const CalendarRef = useRef();

    const artistListRedux = useSelector((state) => { return state.ReservationSlice.schedules });

    useEffect(() => {

        if ( localStorage.getItem('userid') === null || localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '' ) {

            
            //dispatch(setLoginFrom({from :'prereservation'}) ) ; 

            sessionStorage.setItem('loginfrom','prereservation') 
            sessionStorage.setItem('table','') 
            
            alert (' 예약은 로그인이 필요 합니다. ')
            navigate("/login", { replace: false })
            
            
        }

        axios.get(process.env.REACT_APP_DB_HOST+`/api/admin/scheduleforcustomer`) 
        .then((response)=>{
  
            if(response.data.errno !== undefined ) {
              alert(response.data.message); 
              return ; 
            }
  
            dispatch(setSchedulesAction(response.data));
            //setArtistList( response.data.filter((el) => el.yyyy === today.getFullYear() && el.mm === today.getMonth()+1 && el.dd === today.getDate() ) ) 
        })
        .catch((error)=> {
                console.log(error) ;
        })
  



    }, []);


    useEffect(() => {

        setArtistList(artistListRedux.filter((el) => el.yyyy === selecedDate.yyyy && el.mm === selecedDate.mm && el.dd === selecedDate.dd))

    }, [selecedDate,artistListRedux]);


    // 자식에서 호출하는 날짜 변경 함수 
    const changeDate = (yyyy2, mm2, dd2) => {

        setSelectedDate({ ...selecedDate, yyyy: yyyy2, mm: mm2, dd: dd2 })
        setCurrentDate(yyyy2 + '-' + mm2.toString().padStart(2, '0') + '-' + dd2.toString().padStart(2, '0'));

    }

    const prevDay = () => {

        const dt = new Date(selecedDate.yyyy, selecedDate.mm - 1, selecedDate.dd - 1);
        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
    }

    const nextDay = () => {

        const dt = new Date(selecedDate.yyyy, selecedDate.mm - 1, selecedDate.dd + 1);
        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
    }



    const onReservationClick = () => {


        const today = new Date(); 
        const clickDt = new Date(selecedDate.yyyy , selecedDate.mm -1 , selecedDate.dd+1) ;
  
        if( clickDt < today) {
            alert('과거날짜로 예약은 불가능합니다.')
            return ; 
        }
  


        dispatch(setArtistReservationAction(ArtistList)); 
        dispatch(setSelectedDateAction({ yyyy: selecedDate.yyyy, mm: selecedDate.mm, dd: selecedDate.dd }));
        navigate("/Reservation", { replace: false })

    }


    const [ArtistList, setArtistList] = useState([]);

    return (
        

        <div id="contents">
            <div className="sub-back">
                <p onClick={()=>navigate(-1) }>Back</p>
            </div>
            <ScheduleContainer>



                <h4 className="main-title">RESERVATION</h4>

                <CalendarContainer>
                    <Calendar
                        Ref={CalendarRef} changeDate={changeDate} yyyy={selecedDate.yyyy} mm={selecedDate.mm} dd={selecedDate.dd} />
                </CalendarContainer>

                <ArtistContainer>
                    <MonthSelector>
                        <button onClick={prevDay} >〈</button>
                        <h3> {currentDate} </h3>
                        <button onClick={nextDay} >〉</button>
                    </MonthSelector>
                    
                        <ArtistSelector>
                        { ArtistList.length === 0 &&  <h3> 예정된 공연이 없습니다. </h3> }
                            <div className="main-sch__list res"  onClick={onReservationClick}>
                                {ArtistList.map((a, i) => (
                                    <div className="main-sch__list__link" key={i}>
                                        <span className="main-sch__list__num">0{i + 1}</span>
                                        <Em className="main-sch__list__thumb" imgsrc={'/publicimg/' + ArtistList[i].profileimgurl} >thumbnail</Em>
                                        <div className="main-sch__list__text">
                                            <h5>{ArtistList[i].nickname}</h5>
                                            <p>{ArtistList[i].starthh}:{ArtistList[i].startmm} - {ArtistList[i].endhh}:{ArtistList[i].endmm}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ArtistSelector>
                    
                </ArtistContainer>

                <div className="res-bottom">
                    <ul className="res-info">
                        <li>이용시간 연장을 원하시면 공연 중복 예약 부탁드립니다.</li>
                        <li>만석 시 추가 시간 이용이 불가능하실 수 있습니다.</li>
                    </ul>
                                  <button onClick={onReservationClick} className="res-submit">RESERVATION</button>
                              </div>

            </ScheduleContainer>
        </div>
    );
}

const Em = styled.em`
    background: url(${(props) => (props.imgsrc)}) no-repeat center center / cover;
`

 
const ScheduleContainer = styled.div`

  display : flex; 
  
  width : 100%; 
  padding : 0rem 0rem ; 
  
  
  display : flex; 
  overflow : hidden; 
  
  @media (min-width:600px) {   flex-direction: column ; height : 100%;  }
  @media (max-width:768px) {   width : 100%;  font-size : 0.5rem ;  height : 90%; }
  @media (max-width:600px) {   flex-direction: column ; height : 90%;  }

`

const CalendarContainer = styled.div`

  border-top : solid 1px black;  
  @media (min-width:600px) {   width : 100%; font-size : 0.5rem ;   }
  @media (max-width:600px) {   width : 100%;  font-size : 0.5rem ;  }

`

const ArtistContainer = styled.div`

  border-top : solid 1px black;    
  display : flex; 
  flex-direction: column ; 
  
    
  @media (min-width:600px) {   width : 100%; font-size : 0.5rem ;  }
  @media (max-width:768px) {   width : 100%;  font-size : 0.5rem ; }
  @media (max-width:600px) {   width : 100%;  font-size : 0.5rem ; }
  
`




const MonthSelector = styled.div` 
      display : flex ; 
      flex-direction : row ;

      justify-content : space-between ; 
      padding : 0em 1em 0em 1em ; 
      background : white ; 
      font-size : 5em ; 
      line-height : 60px ; 
      border-bottom : solid 1px black; 
      

      button { font-size : 1em; background : white; }
`

const ArtistSelector = styled.div`
  display : flex; 
  flex-direction : column ; 
  width : 100% ; 
  h3{ font-size : 20px; font-weight : 300; padding: 10px; }
  
`

