import styled from "styled-components"
import {   useNavigate  } from 'react-router-dom';

const Em = styled.em`

       background: url(${(props) => props.imgsrc})no-repeat center center / cover;

`


export default function Shop() {

    const navigate = useNavigate();

    return (


        <div id="contents" className="sub">

            <div className="sub-back">
                <p onClick={() => navigate(-1)}>Back</p>
            </div>

            <div id="shop">



                <h4 class="main-title">MERCH</h4>

                <ul class="shop-list">
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item01.png' />
                            <dl>
                                <dt>Mug</dt>
                                <dd>15.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item02.png' />
                            <dl>
                                <dt>Bowl</dt>
                                <dd>15.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item03.png' />
                            <dl>
                                <dt>Deep Plate</dt>
                                <dd>15.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item04.png' />
                            <dl>
                                <dt>Suitcase</dt>
                                <dd>30.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item05.png' />
                            <dl>
                                <dt>Note - A</dt>
                                <dd>10.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item06.png' />
                            <dl>
                                <dt>Note - A to Z</dt>
                                <dd>10.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item07.png' />
                            <dl>
                                <dt>Bottle</dt>
                                <dd>15.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item08.png' />
                            <dl>
                                <dt>Tape</dt>
                                <dd>0.5</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item09.png' />
                            <dl>
                                <dt>Pen</dt>
                                <dd>0.5</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item10.png' />
                            <dl>
                                <dt>Candle - Type1</dt>
                                <dd>10.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item11.png' />
                            <dl>
                                <dt>Candle - Type2</dt>
                                <dd>10.</dd>
                            </dl>
                        </a>
                    </li>
                    <li>
                        <a href="#" target="_blank" class="link-hover">
                            <Em imgsrc='../img/sub/shop_item12.png' />
                            <dl>
                                <dt>Keyring Light</dt>
                                <dd>15.</dd>
                            </dl>
                        </a>
                    </li>
                </ul>

                <div class="list-more">
                    <button type="button">more</button>
                </div>


            </div>
        </div>
    )
}