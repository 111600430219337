

import axios from "axios";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";


export default function ArtistComponent(props) {

    const userid = localStorage.getItem('userid');

    const  artistList = props.artistList; 
    
    console.log("artistList" + artistList.length)
    const [myFavorite, setMyFavorite] = useState({});

    useEffect(() => {

        if (userid !== null && userid !== ""  && userid !== undefined)
            loadFav();





    }, [])


    const atstFav = (i) => {

        if (userid === null) {
            alert("관심 아티스트 설정은 로그인이 필요 합니다. ");
            return;
        }

        // 관심제거 
        if (myFavorite[artistList[i].userid] === true) {
            const data = { userid: userid, artistid: artistList[i].userid };
            axios.post(process.env.REACT_APP_DB_HOST+'/api/user/deletemyfavorite', data)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    const temp = { ...myFavorite };
                    temp[artistList[i].userid] = false;
                    setMyFavorite(temp);

                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
        } else  // 관심 추가 
        {

            const data = { userid: userid, artistid: artistList[i].userid, usertype: artistList[i].usertype };
            axios.post(process.env.REACT_APP_DB_HOST+'/api/user/setmyfavorite', data)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    const temp = { ...myFavorite };
                    temp[artistList[i].userid] = true;
                    setMyFavorite(temp);


                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
            if (appCookies["MODAL_EXPIRES"]) return;
                setHasCookie(false);                
                setOpenModal(true);
        }

    
    }


    const loadFav = () => {

        const data = { userid: userid }

        axios.post(process.env.REACT_APP_DB_HOST+'/api/user/getmyfavorite', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                const temp = {};
                for (var i = 0; i < result.data.length; i++) {
                    temp[result.data[i].artistid] = true;
                }
                setMyFavorite(temp);


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }



    const [appCookies, setAppCookies] = useCookies();
    const [openModal, setOpenModal] = useState(false);
    const [hasCookie, setHasCookie] = useState(false);

    const getExpiredDate = (days: number) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    };

    const closeModalUntilExpires = () => {
        if (!appCookies) return;

        const expires = getExpiredDate(1);
        setAppCookies("MODAL_EXPIRES", true, { path: "/", expires });

        setOpenModal(false);
    };

    const closeModal = () => {

        setOpenModal(false);

    }


    return (
        <div className="main-atst__load">
            <ul className="main-atst__list">

                {artistList.length !== ''  && artistList.map((a, i) => (

                    <li key={i}>
                        <div className="main-atst__pic">
                            <BackgroundImg imgsrc={'/publicimg/' + artistList[i].profileimgurl}> </BackgroundImg>
                            <button onClick={() => atstFav(i)} className={myFavorite[artistList[i].userid] === true ? "main-atst__favon" : "main-atst__fav"}>favorite</button>
                        </div>
                        <Link to={`/artistdetail/${artistList[i].usertype}/${artistList[i].userid}`} className="main-atst__link link-hover" >
                            <span>{artistList[i].instruments}</span>
                            <h5>{artistList[i].nickname}</h5>
                            <p>{artistList[i].introduce}</p>
                        </Link>
                    </li>
                ))}
            </ul>

            {openModal && !hasCookie &&
                <div>
                    <div id="popup" className="artist_favorite">
                        <div className="pop-title">
                            <h4 className="pop-title__fav">INTERESTED ARTIST</h4>
                            <p>관심 아티스트로 등록되었습니다.</p>
                            <span>관심 아티스트는 마이페이지에서 관리할 수 있습니다.</span>
                        </div>
                        <ul className="pop-btn">
                            <li><Link to='/my/myartist' >관심 아티스트 보기</Link></li>
                            <li><button className="bk" onClick={closeModal}>확인</button></li>
                        </ul>
                        <div className="pop-cookie">
                            <input type="checkbox" name="" id="pop-cookie" onClick={closeModalUntilExpires} />
                            <label for="pop-cookie">오늘 하루 다시보지 않기</label>
                        </div>
                    </div>
                </div>
            }
                
        </div>
    )

}

            const ArtistImg = styled.img `

            width : 100%;
            height : 100% ;
            object-fit : cover ;

            :after{content: ''; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background: #C20019; opacity: 0; -webkit-transition: all 0.3s; transition: all 0.3s;}

            `
            const BackgroundImg = styled.em `
            display: block; padding-bottom: 84.21%;  font-size: 0px;
            background: url(${(props) => (props.imgsrc)}) no-repeat center center / cover;
            `











