import axios from "axios";
import { useState, useEffect } from "react"
import { MdOutlineCheckBox } from "react-icons/md";
import AdminMenu from "../admincomponents/AdminMenu";
//import AdminArtistDetail from "../admincomponents/AdminArtistDetail";
import ArtistDetail from "../pages/ArtistDetail";
import SignUp from "../pages/SignUp";

export default function AdminArtist(){


//<input type="checkbox" checked={instruments.includes(a)?true:false} id={a} value={a} onChange={(e)=>checkedInstrument(e.target.checked,e.target.value) } /><label  htmlFor={a}>{' '+a}</label>



const [checkboxList, setCheckboxList] = useState([]) ;
const [artistList, setArtistList] = useState([]) ;
const [moreEnable, setMoreEnable] = useState(false); 
const [currentArtist, setCurrentArtist] = useState(0); 
const [isModal , setIsModal] = useState(false); 
const [isEditModal , setIsEditModal] = useState(false); 



const [condition, setCondition] = useState({ 
    type : '활동명' , 
    content : '' , 
    sex : '성별전체' , 
    snstype : '가입경로전체' , 
    permit : '승인상태전체' ,
    good : 0 , 
    updown : '이상' ,
    lastpk : 999999999
})

const close = () => {
    setIsEditModal(false) ; 
}
 
const onChangeType = (e) => {
    setCondition ( {...condition , type : e.target.value } ) ; 
    setMoreEnable(false); 
}

const onChangeContent = (e) => {
    setCondition ({...condition, content : e.target.value }) ;
    setMoreEnable(false); 
}

const onChangeSex = (e) => {
    setCondition ({...condition, sex : e.target.value });
    setMoreEnable(false); 
}

const onChangeSnstype = (e) => {
    setCondition ({...condition, snstype : e.target.value });
    setMoreEnable(false); 
}

const onChangePermit = (e) => {
    setCondition ({...condition, permit : e.target.value }) ;
    setMoreEnable(false); 
}

const onChangeGood = (e) => {
    setCondition ({...condition, good : e.target.value }) ;
    setMoreEnable(false); 
}

const onChangeUpdown = (e) => {
    setCondition ({...condition, updown : e.target.value }) ;
    setMoreEnable(false); 
}






const CheckedAll = (e) => {
    const temp = [...artistList] ;
    for( var i in temp){
        temp[i].checked = e.target.value ; 
    }
    setArtistList(temp); 
}


useEffect( ()=> {

    postArtistData(0) ; 
} , [] )


const postArtistData = (start) => {

    // 조회 버튼, artist List 를 새로고침 
    if( start === 0 ) {
        condition.lastpk = 999999999 ; 
        setMoreEnable(true); 
    }
    axios.post('/api/admin/postartistlist', condition)
        .then((result) => { 

            if( start === 1 ) {
                const temp = [...artistList, ...result.data]
                setArtistList(temp); 
            }else
            {
                setArtistList(result.data); 
            }

            condition.lastpk = result.data[result.data.length-1].autopk ; 
 
            if( result.data[29].autopk === null )  setMoreEnable(false); 

        })
        .catch((error)=> {
          //  alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 
}

const onClickPermit = (autopk, i ) => { 

    if ( window.confirm('승인하시겠습니까?') ) {

        const data = { autopk : autopk }

        axios.post('/api/admin/permit', data)
                .then((result) => { 
        
                    const temp = {...artistList[i], usertype : 'ARTIST'}
                    const tempArray = [...artistList]
                    tempArray[i] = temp ;
                    setArtistList(tempArray); 
         
                })
                .catch((error)=> {
                    alert("server error occured!!! Please check console log (F12) ") ;
                    console.log(error)})  ;
    }; 
    
}

const onCheckBox = (bool,i) => { 

    const temp = [...checkboxList]
    temp[i] = bool ; 
    setCheckboxList(temp); 
}

const onDetail = (i) => {

    setCurrentArtist(i)

    setIsModal(!isModal);

}

const onEdit = (i) => {

    setCurrentArtist(i)

    setIsEditModal(!isEditModal);

}


const onDelete = (i) => {


    if( !window.confirm( "삭제 시 복구 불가능합니다. 정말 삭제하시겠습니까?" ) ) return ; 

    const data = {userid : artistList[i].userid }

    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/deleteuser', data)
    .then((result) => { 

        if (result.data.errno !== undefined) {
            alert(result.data.message);
            return;
        }

        // 토큰을 지운다 
        const body = { userid: artistList[i].userid }
        axios.post(process.env.REACT_APP_DB_HOST+"/api/login/logout", body)
        .then((res) => {

        })
        .catch((error) => { console.log("error =" + error) })


        alert('삭제되었습니다. (화면 새로고침 필요) ')

 
    })
    .catch((error)=> {
      //  alert("server error occured!!! Please check console log (F12) ")
        console.log(error)
    }) 


}




    return(

        <div>

            <div className="adm-sch">
                <table>
                    <tbody>
                        <tr>
                            <th className="adm-sch__text">검색조건</th>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeType} >
                                    <option value="활동명">활동명</option>
                                    <option value="전화번호">전화번호</option>
                                    <option value="이메일">이메일</option>
                                </select>
                            </td>
                            <td><input type="text" onChange={onChangeContent} placeholder="검색어를 입력해 주세요." /></td>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeSex} >
                                    <option value="성별전체">성별전체</option>
                                    <option value="남자">남자</option>
                                    <option value="여자">여자</option>
                                </select>
                            </td>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeSnstype} >
                                    <option value="가입경로전체">가입경로전체</option>
                                    <option value="KAKAO">카카오톡</option>
                                    <option value="NAVER">네이버</option>
                                    <option value="GOOGLE">구글</option>
                                </select>
                            </td>
                            <td className="adm-sch__sel">
                                <select onChange={onChangePermit}>
                                    <option value="승인상태전체">승인상태전체</option>
                                    <option value="미승인">미승인</option>
                                    <option value="승인">승인완료</option>
                                </select>
                            </td>
                            <th className="adm-sch__text4">관심</th>
                            <td className="adm-sch__count"><input type="text" name="" id="" placeholder="횟수 입력" onChange={onChangeGood}/></td>
                            <th className="adm-sch__text3">회</th>
                            <td className="adm-sch__sel">
                                <select name="" id="" onChange={onChangeUpdown}>
                                    <option value="전체">전체</option>
                                    <option value="이상">이상</option>
                                    <option value="이하">이하</option>
                                </select>
                            </td>
                            <td className="adm-sch__submit">
                                <button onClick={()=>postArtistData(0)}>검색</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="adm-tb__info">
        
            <ul>
                <li><button>선택승인</button></li>
                <li><button>선택삭제</button></li>
            </ul>
			</div>

			<table className="adm-tb">
				<thead>
                    <tr>    
						
						<th style={{width:"100px"}}>이름</th>
						<th style={{width:"100px"}}>활동명</th>
						<th style={{width:"100px"}}>전화번호</th>
						<th style={{width:"50px"}}>성별</th>
						<th style={{width:"100px"}}>가입일</th>
						<th style={{width:"50px"}}>관심</th>
						<th style={{width:"100px"}}>가입경로</th>
                        <th style={{width:"50px"}}>공연수</th>
						<th style={{width:"100px"}}>최근 공연일</th>
						<th style={{width:"50px"}}>소속팀</th>
						<th style={{width:"100px"}}>상세</th>
                        <th style={{width:"100px"}}>수정</th>
                        <th style={{width:"100px"}}>삭제</th>
						<th style={{width:"100px"}}>가입승인</th>
					</tr>
				</thead>
				<tbody>

                    
                    {artistList.map( (a,i)=>(
                        <tr  key = {i}>
                         
                            <td>{artistList[i].name}</td>
                            <td>{artistList[i].nickname}</td>
                            <td>{artistList[i].phone}</td>
                            <td>{artistList[i].sex}</td>
                            <td>{artistList[i].crtdt.substr(0,10)}</td>
                            <td>{artistList[i].favorite_cnt}</td>
                            <td>{artistList[i].snstype}</td>
                            <td>{artistList[i].schedule_cnt}</td>
                            <td>{artistList[i].last_schedule}</td>
                            <td>{artistList[i].team_cnt}</td>
                            {artistList[i].usertype !== 'ARTISTREQUEST' && 
                                <td><button className="adm-tb__btn" onClick={()=>onDetail(i)} >상세</button></td>
                            }
                            {artistList[i].usertype !== 'ARTISTREQUEST' &&
                                <td><button className="adm-tb__btn" onClick={()=>onEdit(i)} >수정</button></td>
                            }
                            <td><button className="adm-tb__btn red" onClick={()=>onDelete(i)} >삭제</button></td>
                            {artistList[i].usertype === 'ARTISTREQUEST' && 
                                <td><button className="adm-tb__btn" onClick = {()=>onClickPermit(artistList[i].autopk, i)}>승인</button></td>
                            }
                            {artistList[i].usertype === 'ARTIST' && 
                                <th>승인완료</th>
                            }
                        </tr>
					))}
					
				</tbody> 
			</table>
            <div className="adm-more__btn">
               { moreEnable === true ? <button   onClick={()=>postArtistData(1)}> 더보기 </button> : null }
            </div>

            {isModal && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsModal(!isModal)}>X </h2>
                    <ArtistDetail userid={artistList[currentArtist].userid} usertype='ARTIST' adminmode='ADMIN'/>
                </div>
            }
            {isEditModal && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsEditModal(!isEditModal)}>X </h2>
                    <SignUp EDITMODE='ADMIN' edituserid={artistList[currentArtist].userid} mode='EDIT' close={close} />
                </div>
            }



        </div>




    )
}