import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useSearchParams } from 'react-router-dom'; // 훅을 호출하고
import { useNavigate } from 'react-router-dom';

const ImportRedirectHandler = () => {

  const [searchParams, setSearchParams] = useSearchParams(); // 쿼리 스트링을 searchParams 형태로 가져오고
  let merchant_uid = searchParams.get("merchant_uid"); // 인가코드 받는 부분
  let imp_success = searchParams.get("imp_success"); // 인가코드 받는 부분
  let error_code = searchParams.get("error_code"); // 인가코드 받는 부분
  let error_msg = searchParams.get("error_msg"); // 인가코드 받는 부분

  const navigate = useNavigate();
  const callbackFromImport = () => {
    
    
    
    console.log("kjj react imp_success = " + imp_success)

    
		if (imp_success === 'true') {

			alert('결제 성공');


			const data = { uniquekey : merchant_uid , status : "예약완료"  }

			axios.post('/api/reservation/updatecheckout', data)
			.then((result) => { 
	
				  if(result.data.errno !== undefined ) {
					alert(result.data.message); 
					return ; 
					}
				 
					alert('예약이 완료 되었습니다. ') 
	
					
					navigate("/my/myreservation", { replace: true })

	
			})
			.catch((error)=> {
				alert("server error occured!!! Please check console log (F12) ")
				console.log(error)
			}) 

	
		} else {
		  alert(`결제 실패: ${error_msg} (${error_code})`);
		}
  }




useEffect(() => {

  callbackFromImport();

}, [])

  return (
  <>
  </>
  )
};

export default ImportRedirectHandler;
