import { useState } from "react";
import styled from "styled-components";
import { useDispatch ,useSelector } from "react-redux";
import { setArtists, setTeams, setTotal } from '../store/ArtistsSlice';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom' ;
import ArtistComponent from "./ArtistComponent";
import axios from "axios";


export default function MainArtists () {

    const [artistList , setArtistList] = useState([]);
      
  // 초기 로딩 담당, 이것도 나중에 컴포넌트로 빼야지,. 
  const dispatch = useDispatch();
  useEffect(() => {
   
    axios.get(process.env.REACT_APP_DB_HOST+`/api/artist/getallartist`) 
    .then((response)=>{

        dispatch(setArtists(response.data))

        
     })
    .catch((error)=> {
            console.log(error) ;
    })

    
    axios.get(process.env.REACT_APP_DB_HOST+`/api/artist/getallteam`) 
    .then((response)=>{

        dispatch(setTeams(response.data))
     })
    .catch((error)=> {
            console.log(error) ;
    })

    
    axios.get(process.env.REACT_APP_DB_HOST+`/api/artist/gettotal`) 
    .then((response)=>{

        dispatch(setTotal(response.data))
        setArtistList(response.data.slice(0,4)); 
     })
    .catch((error)=> {
            console.log(error) ;
    })

    

  }, [])



  const artistReduxList = useSelector((state)=>{return state.ArtistsSlice.total}) ;
    
    //const [artistList , setArtistList] = useState( artistReduxList.slice(0,4) ); 
    const navigate = useNavigate();

    
    const atstLoad = (order) => {

        
        // Monthly 만 redux 가 아닌 api 호출로 가져온다 ( 계속변하기 때문 )
        if(order === 'Monthly'){

            
            axios.get(process.env.REACT_APP_DB_HOST+'/api/user/getmonthly')
            .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
              }
              setArtistList( result.data.slice(0,4) );           


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)}) 

        }else if (order === 'New'){
        
 
            const tempArray = [...artistReduxList] ; 
        
            tempArray.sort((a, b) => {
            return a.crtdt - b.crtdt ;
            });

            setArtistList(tempArray.slice(0,4));
        }

        

    }






    const onMoreClick = () => {

        // <Link to='artistlist'></Link>
        navigate("/artistlist")
    }



    return (
    <>
        <div className="main-atst">
            <div className="main-atst__row">
                <ul className="main-atst__menu">
                    <li><button onClick={()=>atstLoad('Monthly')}>MONTHLY BEST</button></li>
                    <li><button onClick={()=>atstLoad('New')}>NEW ARTIST</button></li>
                    <li><button onClick={onMoreClick}>MORE</button></li>
                </ul>
                <ArtistComponent artistList={artistList}/>
            </div>
        </div>


     </>

    )
}