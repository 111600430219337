import { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { getDate } from "date-fns";
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar } from 'recharts';


export default function AdminStatistic() {

    const [user, setUser] = useState([]);
    const [reservation, setReservation] = useState([]);
    const [cancel, setCancel] = useState([]);
    const [foodorder, setFoodOrder] = useState([]);
    const [toporder, setTopOrder] = useState([]);
    const [topuser, setTopUser] = useState([]);

    useEffect(() => {

        getData()

    }, [])

    const getData = () => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getstatistic')
            .then((result) => {


                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
                setUser(result.data.user)
                setReservation(result.data.reservation)
                setCancel(result.data.cancel)
                setFoodOrder(result.data.foodorder)
                setTopOrder(result.data.toporder)
                setTopUser(result.data.topuser)
            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })


    }
    return (
        <div className="adm-stat-wrap">
            
            <h4>최근 1개월 가입자수 현황(가입일기준) </h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={user}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dt" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="cnt" barSize={5} name="가입자수(명)" fill="#C73866" stroke="#C73866" />
                </ComposedChart>
            </ResponsiveContainer>


            <h4>최근 1개월 예약현황(예약일기준) / 예약취소 제외</h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={reservation}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dt" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line dataKey="cnt" barSize={5} name="예약수" fill="#413ea0" />

                </ComposedChart>
            </ResponsiveContainer>

      

            <h4>최근 1개월 예약취소현황(예약일기준)</h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={cancel}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dt" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="cnt" barSize={5} name=" 취소건수" fill="#f27f0c" />

                </ComposedChart>
            </ResponsiveContainer>

            <h4>최근 1개월 음식 매출액 추이</h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={foodorder}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="dt" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="totalamt" barSize={5} name="매출금액(원)" fill="#f7ad19" />

                </ComposedChart>
            </ResponsiveContainer>

            <h4>Top 10 매출상위 음식</h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={toporder}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="title" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="totalamt" barSize={5} name="매출금액(원)" fill="#053f5c" />

                </ComposedChart>
            </ResponsiveContainer>

            <h4>최근 1년간 Top 30 매출액 상위 유저</h4>
            <ResponsiveContainer width="100%" height="30%">
                <ComposedChart
                    width={500}
                    height={300}
                    data={topuser}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" scale="band" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="totalamt" barSize={5} name="매출금액(원)" fill="#429ebd" />

                </ComposedChart>
            </ResponsiveContainer>

        </div>
    )


}
