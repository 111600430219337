
import { Link } from "react-router-dom"
import { useState } from "react"
import styled from "styled-components"
import { useInterval } from "../components/Util"
import axios from "axios"


export default function AdminMenu() {

    const [isNew, setIsNew] = useState(false); 
    const [menu, setMenu] = useState([

        { path : 'adminstatistic',menuname : '통계보기', selected : false },
        { path : 'admindayoff',menuname : '휴무일지정', selected : false },
        { path : 'adminschedule',menuname : '공연일정관리', selected : false },
        { path : 'adminfront',menuname : '공연예약확인', selected : false },
        { path : 'adminpickseat',menuname : '좌석배치', selected : false },
        { path : 'adminuser' , menuname : '일반회원관리', selected : false },
        { path : 'adminartist', menuname : '아티스트 회원관리',  selected : false },
        { path : 'adminteam', menuname : ' 아티스트 팀관리', selected : false },
        { path : 'adminadmin',menuname : '관리자 계정관리', selected : false },
        { path : 'adminfood',menuname : '음식관리', selected : false },
        { path : 'adminfoodcategory',menuname : '음식카테고리', selected : false },
        { path : 'admingoods',menuname : '굳즈관리', selected : false },
        { path : 'adminbanner',menuname : '메인배너 관리', selected : false },
        { path : 'adminorder',menuname : '음식주문확인', selected : false },
        { path : 'instakey',menuname : '인스타AccessKey변경', selected : false },
        { path : '/foodmenu/A2',menuname : '음식주문', selected : false },
    
        ]
        
    )

    const onClieckMenu = (i) => { 

        const temp = [...menu];
        for(var j = 0 ; j < menu.length ; j++ ) 
        {
            temp[j].selected = false ;
        }

        temp[i].selected = true ; 
        setMenu(temp); 
    }

    useInterval(() => {

        getData();

    }, 3000);


    const getData = () => {

        
 
        axios.get(process.env.REACT_APP_DB_HOST + '/api/foodorder/getreadyordercnt')
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
      
                
                if( result.data[0].cnt > 0) {
                    setIsNew(true)
                }else
                {   setIsNew(false)}
        })
    }

    return (


            <aside id="adm-aside">
                {menu.map((a,i)=>(
                 <ul key={i}>
                    
                    <MenuItem selected={menu[i].selected} onClick={()=>onClieckMenu(i)}>
                        <Link to={menu[i].path} ><p>{menu[i].menuname}</p></Link>
                        {a.menuname === "음식주문확인"  && isNew && <h4> new </h4>}
                    </MenuItem>
                 </ul>))}
            </aside>		  
    )
}

const MenuItem = styled.li ` 

    border-bottom: solid 1px #000;
    display: flex;
    flex-direction: row;
    h4{ color: red; font-size: 1.2rem; }
    
    
    background: ${(props)=>(props.selected ? '#000' : '#fff' )} ;
    color: ${(props)=>(props.selected ? '#fff' :  '#000' )} ;

`