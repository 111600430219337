import axios from "axios"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { useState, useEffect } from "react"
import CryptoJS from "crypto-js"
import { setUser } from "../store/LoginSlice"
import { useDispatch , useSelector  } from 'react-redux';
import { useNavigate } from 'react-router-dom'






export default function Header(props) {

    // login.js 를 통해서 들어올 경우 name 을 받아온다.  
    //const [name, setName] = useState(useSelector((state)=>{return state.LoginSlice.name}));

    const userRedux = useSelector((state)=>{return state.LoginSlice.user}) ;
    
    // 2. location.state 에서 파라미터 취득
    const [name, setName] = useState('');


    const navigate = useNavigate();

    // const [snsType, setSnsType] = useState('');
    const [userId, setUserId] = useState('');
    const [aes256, setAes256] = useState('');


    const dispatch = useDispatch();

    /* <<< side bar */
    const [sideshow, setSideshow] = useState(false);
    const showSidebar = () => { setSideshow(!sideshow); }


    
    const sideBarData = [
        { path: '/my/myreservation', className: '', title: 'My Reservations' , type : 'USER' },
        { path: '/my/myartist', className: '', title: 'Interested Artists' , type : 'USER' },
        { path: '/my/myinfo', className: '', title: 'Member Edit' , type : 'USER' },
        { path: '/my/myteamsetup', className: '', title: 'Artist Team Setup' , type : 'ARTIST'},
        { path: '/my/myteam', className: '', title: 'View My Team', type : 'ARTIST' }];

    
    const [underBar, setUnderBar] = useState([
        {
            title: "RESERVATION",
            path: "/prereservation",
            imageurl: "/img/common/menu_reservation.png",
            imageurl_r: "/img/common/menu_reservation_r.png", 
            selected:false
        },
        {
            title: "ARTIST",
            path: "/artistlist",
            imageurl: "/img/common/menu_artist.png",
            imageurl_r: "/img/common/menu_artist_r.png",
            selected:false
        },
        {
            title: "SHOP",
            path: "/shop",
            imageurl: "/img/common/menu_shop.png",
            imageurl_r: "/img/common/menu_shop_r.png",
            selected:false
        },
        {
            title: "MY",
            path: "/my/myreservation",
            imageurl: "/img/common/menu_my.png",
            imageurl_r: "/img/common/menu_my_r.png",
            selected:false
        },
        {
            title: "INFO",
            path: "/info/waytocome ",
            imageurl: "/img/common/menu_info.png",
            imageurl_r: "/img/common/menu_info_r.png",
            selected:false
        }
    ]);
    /* side bar >>>  */

    const onClickLogin = () => {

        sessionStorage.setItem('loginfrom','') 
        sessionStorage.setItem('table','') 
        sessionStorage.setItem('menu','') 
        
        navigate("/login", { replace: false })

    }

    const onClickUndermenu = (i) => { 


        const temp = [...underBar] ;
        
        for(var j=0; j < underBar.length ; j++ ) temp[j].selected = false; 

        temp[i].selected = true; 
        setUnderBar(temp); 


        // My 인경우만 submenu 
        if(i===3) {

            if ( localStorage.getItem('userid') === null || localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '' ) {

                alert (' MY 메뉴는 로그인이 필요 합니다. ')
                
                return ; 
            }


            showSidebar();
        }

        else 
            navigate(temp[i].path , { replace: false })
    }


    const onClickLogout = () => {


        const bytes = CryptoJS.AES.encrypt(aes256, 'allthatjazzciphertext').toString();
        const accessToken = bytes.toString(CryptoJS.enc.Utf8); // output: '암호화할 값'

        const body = { userid: userId }
        axios.post(process.env.REACT_APP_DB_HOST+"/api/login/logout", body)
            .then((res) => {

                localStorage.setItem('aes256', "");
                localStorage.setItem('userid', "");
                localStorage.setItem('snstype', "");
                localStorage.setItem('name', "");

                sessionStorage.setItem('loginfrom','') 
                sessionStorage.setItem('table','') 
                sessionStorage.setItem('menu','') 

                setName('');
                setUserId('');
                // setSnsType('');
                setAes256('');

                dispatch(setUser({}));

                navigate(`/`, { replace: false })


            })
            .catch((error) => { console.log("error =" + error) })


    }
 

    useEffect(() => {


        //  setName(location.state.name) ; 

        // access token 체크 시작 
        // user id , access token 체크 후 오류일경우 localstorage clear 



        // access token 체크 끝


        setName(localStorage.getItem('name'));
        setUserId(localStorage.getItem('userid'));
        setAes256(localStorage.getItem('aes256'));

        if( localStorage.getItem('userid') === ''  ) return ; 

        axios.get(process.env.REACT_APP_DB_HOST+'/api/user/getuser/'+ localStorage.getItem('userid') )
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                if (result.data.length === 0 ){
                    return; 
                }

                dispatch(setUser(result.data[0]));

           //     dispatch(setUsertype(result.data.usertype));
                //dispatch(setSnstype(result.data.snstype));
                //setName(result.data.name); 

            })
            .catch((error) => {
                alert("/api/user/getuser/ server error occured!!! Please check console log (F12) ")
                console.log(error)
            })


    }, [])



    return (
        <HD >



            <Upper >
                <h2 >
                    <Link to="/"> <img src='/img/common/gnb_logo.png' alt="" /> </Link>
                </h2>
                <ul >
                    {name === '' || name === null ? <Link to="/login"><li>Login</li></Link> : <li onClick={onClickLogout}>logout</li>
                    }

                </ul>

            </Upper>

            <Lower >
                <div>
                    <Link to="/prereservation"> <li>RESERVATION</li> </Link>
                    <Link to="/artistlist"> <li>ARTIST</li> </Link>
                    <Link to="/shop"> <li>SHOP</li> </Link>
                    <Link to="/info/aboutmenu"> <li>INFO</li> </Link>
                    <Link to="/my/myreservation"> <li>MY</li> </Link>
                    {userRedux.usertype === 'ADMIN' &&
                        <Link to="/adminhome/adminschedule"> <li>ADMIN</li> </Link>
                    }
                </div>
                {name === '' || name === null ? null :
                    <NameBox><img src='/img/common/user_sample.png' alt="" /><h5><span>{name}</span>님</h5></NameBox>
                }

            </Lower>


            <FootMenuContainer>
                <ul>

                    {underBar.map((a, i) => (

                        <li key={i} onClick={()=>onClickUndermenu(i)}>
                            {underBar[i].selected === true ?
                                <img src={underBar[i].imageurl_r} alt="" /> :
                                <img src={underBar[i].imageurl}  alt=""/> 
                            }
                        </li>

                    ))}
                </ul>
            </FootMenuContainer>


            {sideshow && <Sidebar>

                <div>
                    <ul>

                 

                    </ul>
                    <ul>
                        <h5 onClick={showSidebar} > × </h5>
                    </ul>
                </div>

                <ol>

                    {sideBarData.map((a, i) => {
                        return (
                            <SidebarDiv key={i} >
                            {(userRedux.usertype === 'ARTIST' ||  userRedux.usertype === 'ADMIN'  || userRedux.usertype === sideBarData[i].type) &&
                            <li  >
                                
                                    <Link to={a.path}>
                                        <span onClick={showSidebar}>{a.title}</span>
                                    </Link>
                            
                            </li>
                            }
                            </SidebarDiv>
                        )
                    }
                    )
                    }
                </ol>

            </Sidebar>
            }





        </HD>
    )

}

const SidebarDiv = styled.span`

    margin : 0px; 
    padding : 10px ; 

    li{ cursor : pointer ;
        line-height : 30px; 
        font-size : 20px ; 
        margin : 0px ;  
        padding : 10px; 
        :hover{background : grey; }
    }

`

const FootMenuContainer = styled.div`

@media (min-width:700px) { display : none }

    border-top : solid 2px #d1d1d1 ; 
    padding : 20px 0px ;
    position : fixed; 
    bottom : 0;
    left : 0 ; 
    height : 100px; 
    width : 100%; 
    z-index : 99; 
        ul{
            
            display: flex; 
            flex-direction : row ; 
            justify-content : center ; 
            margin: 0 20px; 
            li{
                width : 20%; 

                text-align : center; 
                cursor : pointer ; 

                
            
                img {
                    height : 30%; 
                    width : auto; 

                }
            }
        }
    background : white; 

`


const HD = styled.header`

    display:flex ; 
    flex-direction: column; 
    
}`

const Upper = styled.div`
    
    display:flex; flex-direction: row; 
    justify-content:space-between; 
    padding: 4rem 0rem 2.2rem 0rem  ;
    wrap : no-wrap;
    align-items: center; 
    border-bottom: solid 1px #000;

    h2{
        img{width: 20rem; height: auto; }
     }


     ul {
        li{
        font-size: 1.2rem ; text-align:center ; 
        padding:0 20px; margin-right: 20px;  border-top: solid 1px #000; border: solid 1px #000; border-radius: 20px; 
        cursor:pointer; 
        height: 34px; line-height: 32px; 
        }
    }

  

`


const Lower = styled.div`


    
    display:flex ; 
    flex-direction: row; 
    justify-content : space-between; 
    height: 43px; line-height: 43px;
    border-bottom: 1px solid #000; 
    cursor :pointer ; 
    align-items : center;

    div{  font-weight: 700; font-size : 1.6rem ; display: flex ; flex-direction : row; 
       li{ padding-right: 40px;   }

       align-items : center ; 
       @media (max-width:780px) { font-size : 1.3rem ;}
       @media (max-width:700px) { font-size : 1.1rem ;}
       
       

    }
    
    img{ width: 27px; height: 27px;  border-radius: 50%;}
    h5{  float: left; margin-left: 10px; height: 100%; height: 100%; font-size: 12px; font-weight: 400;
    
        span{  font-weight: 700; margin-right: 3px;}
        margin-right : 30px; 
        
    }
    

    }
  
    @media (max-width:700px) { display : none }

`

const NameBox = styled.div`
    border-left : solid 1px black ; 
    padding-left : 25px; 

`

const Sidebar = styled.nav`

    @media (min-width:700px) { display : none }


    position : fixed; 
    top :0px ; 
    left : 0px; 

    display: flex; 
    flex-direction : column ; 

    background : black ; 
    color : white ; 
    width : 100%; 
    height : 1000%;  
    z-index : 99; 
    opacity : 0.9 ;

    left : 0; 

    div {
        margin : 4rem 1rem; 
        display : flex ; 
        flex-direction : row ; 
        justify-content : space-between; 
        align-items : center;
        ul{ 
            img{ height : 4rem ; width : 4rem; border-radius: 50%;}
            li{ font-size : 2rem ;  font-weight : 700 ; margin : 0em 0.5em 0em 1em ;  }
            h4{ font-size : 2rem ;  font-weight : 100 ;  }
            display : flex ; 
            flex-direction : row ; 
            justify-content : right ; 
            
            span{
                font-size : 2rem ; 
                padding : 0rem 2rem ; 
                width: 10rem; 
                margin: 1rem;
                border: solid 1px #FFF; border-radius: 20px; 
                cursor : pointer ; 
            }
            h5{font-size:4rem; font-weight : 100; cursor :pointer;  }
        }
        
    }

    ol{
        display : flex ; 
        flex-direction : column ; 
        text-align : center ;         


    

    }

`