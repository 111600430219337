import { useState, useEffect } from "react"
import axios from "axios";



export default function AdminAdmin() {


    const [admins, setAdmins] = useState([]);
    const [newAdmin, setNewAdmin] = useState(''); 

    useEffect(() => {




        getData() ; 
       
    }, [])


    const getData = () => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getalladmin')
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }
            setAdmins(result.data)
        })
        .catch((error) => {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        })


    }

    const onDeleteClick = (i) => {

        const data = { userid: admins[i].userid }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/setadmintouser', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData() ; 
                alert('저장되었습니다. ')
                


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })
    }

    const onNewAdmin = (e) => {
        setNewAdmin( e.target.value )

    }

    const saveNewAdmin = () => {

        const data = { userid: newAdmin }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/savenewadmin', data)
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            getData() ; 
            alert('저장되었습니다. ')
            
        })
        .catch((error) => {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        })

    }

    return (
        <div id="adm-inner">

            <h2 id="adm-title">Admin 관리(카카오 계정만 등록해주세요) </h2>

            <div className="adm-sch">
                <table>
                    <tbody>
                        <tr>
                            <th style={{width:'200px'}}>새로운 어드민 추가</th>
                            <td><input type="text" onChange={(e)=>onNewAdmin(e)} placeholder="Admin Id 입력" /></td>
                            
                            <td className="adm-sch__submit">
                                <button onClick={()=>saveNewAdmin()}>저장</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <table className="adm-tb">
                <thead>
                    <tr>
                        <th>아이디</th>
                        <th>이름</th>
                        <th>전화번호</th>
                        <th>성별</th>
                        <th>생년월일</th>
                        <th>삭제(일반유저로변경)</th>
                    </tr>
                </thead>
                <tbody>

                    {admins.map((a, i) => (
                        <tr key={i}>
                            <td>{admins[i].userid}</td>
                            <td>{admins[i].name}</td>
                            <td>{admins[i].phone}</td>
                            <td>{admins[i].sex}</td>
                            <td>{admins[i].birthday}</td>
                            <td><button className="adm-tb__btn" onClick={() => onDeleteClick(i)}>삭제</button></td>

                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    )





}