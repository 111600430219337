
import { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useNavigate, Link } from "react-router-dom"
import { useInterval } from "../components/Util"
import  OrderSeat from "../components/OrderSeat"



export default function AdminOrder() {

    const navigate = useNavigate();
    const [orderList, setOrderList] = useState([]);
    const [isPopup, setIsPopup] = useState({});

    const [count, setCount] = useState(0);

    const [guestRequest, setGuestRequest] = useState([]);
    const [orderAmt, setOrderAmt] = useState([]);

    const [currentOrderList, setCurrentOrderList] = useState([]);
    const [currentSum, setCurrentSum] = useState(0); 

    const [isOrderDetail, setIsOrderDetail] = useState(false);

    const main = [{ title: '주문', next: '준비중', data: orderList.filter((el) => el.orderstate === '주문') },
                  { title: '준비중', next: '완료', data: orderList.filter((el) => el.orderstate === '준비중') }
                 ]

    //const [firstData, setFirstData] = useState([]); 
    //const [secondData, setSecondData] = useState([]); 
    //const [thirdData, setThirdData] = useState([]); 
    //const [fourthData, setFourthData] = useState([]); 

    //const firstData = 
    //const secondData = orderList.filter((el)=> el.orderstate === '준비중')




    useInterval(() => {

        getData();

    }, 1000);


    useEffect(() => {

        getData();

    }, [])


    const getData = () => {




        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/getordertotal')
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                //getTimediff();
                setOrderList(result.data.order)
                setOrderAmt(result.data.ordersum);
                setGuestRequest(result.data.request);

            })
            .catch((error) => {
                alert("getordertotal error occured!!! Please check console log  (F12) ")
                console.log(error)
            })
    
    }

    /*

    const getTimediff = (ol) => {

        var now = new Date();
        var numnow = now.getFullYear() * 100000000 + (now.getMonth() + 1) * 1000000 + now.getDate() * 10000 + now.getHours() * 100 + now.getMinutes()

        const temp = [...ol]

        for (var i = 0; i < temp.length; i++) {


            const crtdtnum = temp[i].crtdt.replaceAll('-', '').replaceAll(' ', '').replaceAll(':', '').substr(0, 12)

            temp[i] = { ...temp[i], timegap: numnow - crtdtnum }
        }
        setOrderList(temp);
    }
    */

    const onChangeStatus = (j, i, status) => {


        if (status === '삭제') {
            if (!window.confirm("정말 삭제하시겠습니까?")) return;
        }

        const data = { orderid: main[j].data[i].orderid, state: status }
        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/updateorderstatus', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData();


            })
            .catch((error) => {
                alert("updateorderstatus error occured!!! Please check console log (F12) ")
                console.log(error)
            })


    }

    const onTableOrder = (nametag, payedtime) => {


        setIsOrderDetail(true);

        const temp = [...orderList.filter((el) => (el.nametag === nametag && el.payedtime == payedtime))]
        setCurrentOrderList(temp);

        const result = temp.reduce( function add(sum, currValue) {
            return sum+currValue.price*currValue.ordercnt; 
        },0)
        setCurrentSum(result) ;
    }

    const onTableClose = () => {
        setIsOrderDetail(false);   
    }


    const onPayDone = (i) => {

        var now = new Date();
        var numnow = now.getFullYear() * 100000000 + (now.getMonth() + 1) * 1000000 + now.getDate() * 10000 + now.getHours() * 100 + now.getMinutes()


        const data = { nametag: orderAmt[i].nametag, payedtime: numnow }
        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/paydone', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData();


            })
            .catch((error) => {
                alert("paydone error occured!!! Please check console log (F12) ")
                console.log(error)
            })




    }


    const onRequestDone = (i) => {

        const data = { autopk: guestRequest[i].autopk }
        axios.post(process.env.REACT_APP_DB_HOST + '/api/foodorder/delguestrequest', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData();


            })
            .catch((error) => {
                alert("delguestrequest error occured!!! Please check console log (F12) ")
                console.log(error)
            })


    }

    const onClose = (i) => {
        setIsPopup({isShow:false, order : {}, i:null});
    }

    const onTitleClick = (order) => {

        setIsPopup({isShow:true, order :order});

    }


    return (

        <div className="adm-order__wrap" >
            <div className="adm-order__upper" >

                <div className="adm-order__container">

                    {main.map((b, j) => (
                        <div className="adm-order__box" key={j}>
                            <h2>■ {main[j].title}</h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="adm-order__td_1"> 테이블 </th>
                                        <th className="adm-order__td_2"> 메뉴 </th>
                                        <th className="adm-order__td_3"> 수량 </th>
                                        <th className="adm-order__td_4"> 경과(분) </th>
                                        <th className="adm-order__td_5"> 처리 </th>
                                        <th className="adm-order__td_5"> 삭제 </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {main[j].data.map((a, i) => (
                                        <tr key={i} className="adm-order__first" >
                                            <td > {main[j].data[i].nametag} </td>
                                            <td className="adm-order__title" onClick={()=>{onTitleClick(a)}}> {main[j].data[i].category}-{main[j].data[i].title} </td>
                                            <td > {main[j].data[i].ordercnt}</td>
                                            <td > {main[j].data[i].timegap} </td>
                                            <td > <button onClick={() => onChangeStatus(j, i, main[j].next)}> {main[j].next} </button> </td>
                                            <td > <button onClick={() => onChangeStatus(j, i, '삭제')}> 삭제 </button> </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ))}

                </div>

                <div className="adm-order__complete">
                    <div className="adm-order__box" >
                    <h2>■ 완료</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width : "15%" }}> 테이블 </th>
                                    <th style={{ width : "70%" }}> 메뉴 </th>
                                    <th style={{ width : "15%" }}> 수량 </th>
                                </tr>
                            </thead>
                            <tbody>
                                  {orderList.filter((el) => el.orderstate === '완료').map((a, i) => (
                                        <tr key={i} className="adm-order__first" >
                                            <td > {a.nametag} </td>
                                            <td > {a.category}-{a.title} </td>
                                            <td > {a.ordercnt}</td>
                                        </tr>
                                    ))} 
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div className="adm-order__bottom" >
                <div className="adm-server__container">
                    <div className="adm-server__box">
                        <h2>고객 요청사항</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th className="adm-order__td_1"> 테이블 </th>
                                    <th className="adm-order__td_2"> 메세지 </th>
                                    <th className="adm-order__td_3"> 처리 </th>
                                </tr>
                            </thead>
                            <tbody>
                                {guestRequest.map((a, i) => (
                                    <tr key={i} className="adm-order__first" >
                                        <td > {guestRequest[i].nametag} </td>
                                        <td > {guestRequest[i].request} </td>
                                        <td > <button onClick={() => onRequestDone(i)}> 처리완료 </button> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
      

                    <div className="adm-order_sts__box">

                        <div >
                            <h2>주문 금액 현황</h2>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="adm-order__td_1"> 테이블 </th>
                                        <th className="adm-order__td_2"> 총 주문금액 </th>
                                        <th className="adm-order__td_3"> 주문내역 </th>
                                        <th className="adm-order__td_4"> 처리 </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderAmt.map((a, i) => (
                                        <tr key={i} className="adm-order__first" >
                                            <td > {orderAmt[i].nametag} </td>
                                            <td > {orderAmt[i].totalamt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</td>
                                            <td > <button onClick={() => onTableOrder(orderAmt[i].nametag, orderAmt[i].payedtime)}> 주문내역 </button> </td>
                                            <td > {orderAmt[i].payedtime === 'notyet' ?
                                                <button onClick={() => onPayDone(i)}> 결제완료 </button>
                                                :
                                                <h3>{orderAmt[i].payedtime}</h3>

                                            } </td>


                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                {isPopup.isShow &&
                
                    <div className="adm-order__popup" >
                        <OrderSeat order={isPopup} onClose={()=>{onClose()}} ></OrderSeat>

                    </div>
                }
                {isOrderDetail &&
                    <div className="adm-order_dtl_wrap" >

                        <div className="adm-order_dtl_cont" >
                             <h4 onClick={onTableClose} >X</h4>
                             <table>
                                <thead>
                                    <th style={{width:"50px"}}> 테이블 </th>
                                    <th style={{width:"300px"}}> 항목 </th>
                                    <th style={{width:"80px"}}> 단가 </th>
                                    <th style={{width:"40px"}}> 수량 </th>
                                    <th style={{width:"80px"}}> 금액 </th>
                                </thead>


                             <tbody>
                                {currentOrderList.map((a,i)=>(
                                    <tr key={i}>
                                    <td>{currentOrderList[i].nametag}</td>
                                    <td>{currentOrderList[i].title}</td>
                                    <td className='adm-order_dtl_right'>{currentOrderList[i].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</td>
                                    <td>{currentOrderList[i].ordercnt}</td>
                                    <td  className='adm-order_dtl_right'>{(currentOrderList[i].price*currentOrderList[i].ordercnt).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                            <div  className='adm-order_dtl_amt'>
                                <h4>가격</h4>
                                <h4>{currentSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</h4>
                                
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>


            )

}




       