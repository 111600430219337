
import { useEffect, useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useInterval } from "../components/Util"

export default function OrderSeat(props) {

    const [showSeats, setShowSeats] = useState([]);


    useEffect(() => {

        if (showSeats.length === 0) {

            axios.get(process.env.REACT_APP_DB_HOST + '/api/admin/getallseats')
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    setShowSeats(result.data.filter(el => el.y > 100));

                })
                .catch((error) => {
                    //  alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })

        }

        


    }, [])

   
    const onX = () => {
        props.onClose()
        
    }

    return (

        <div className="adm-orderpopup__wrap">
            <div className="adm-orderpopup__container">
                <div>
                    <div className="adm-orderpopup__desc"> 
                        <h3> 테이블 : {props.order.order.nametag} </h3>
                        <h3> {props.order.order.category}  </h3>
                        <h3 style={{width:"350px"}}> {props.order.order.title} </h3>
                        <h3> 수량 : {props.order.order.ordercnt} </h3>
                    </div >
          
                </div>
                <div className="adm-orderpopup__seat">
                    {showSeats.map((a, i) => (
                        <div key={i}>
                            
                            <Box  x={showSeats[i].x + 380} y={showSeats[i].y - 150} width={showSeats[i].width} height={showSeats[i].height} occupied={showSeats[i].nametag===props.order.order.nametag}  >
                                <h4 > {showSeats[i].nametag} </h4>
                                <img src={showSeats[i].imageurl} width={showSeats[i].width} height={showSeats[i].height} />
                            </Box>
                        </div>
                    ))}
                    <Img src='/img/common/restorant.png' />

                </div>
            
            </div>    
            <div className="adm-orderpopup__button" onClick={()=>{onX()}} >
                        확인 
             </div>
        </div>

    )
}

const Box = styled.div`

    position : absolute ;
    cursor : pointer ;
    left : ${(props) => (props.x)}px ;
    top : ${(props) => (props.y)}px ;
    z-index : 4;

    filter: ${(props) => (props.occupied  ? 'opacity(0.5) drop-shadow(0 0 0 red)' : '')}  ;
    animation: ${(props) => (props.occupied  ? 'blink-effect 1s step-end infinite' : '')} ;
    img {  }
    }
    h4 {
        position : absolute;
    text-align : center ;
    top : ${(props) => (props.height) / 4}px;
    left : ${(props) => (props.width) / 4}px;
    width : 20px;
    font-size : 1rem ;
    z-index : 4; 

}

`

const Img = styled.img`
    position : absolute;
    top : 50px;
    left : 480px;
    width : 260px;
    height : 470px;


`