import "../css/Login.css"
import { useEffect } from "react";
import Footer from "./Footer";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUser ,setMenuToken} from "../store/LoginSlice"
import CryptoJS from "crypto-js";
import { useGoogleLogin } from "@react-oauth/google";



export default function Login() {


    const navigate = useNavigate();
    const dispatch = useDispatch();
    // 카카오 
    const CLIENT_ID = "0f92ad0baa92ef45ac7f9bbbf043ddde";
    var REDIRECT_URI = "http://localhost:3000/kakaologin" ; 
    if ( process.env.REACT_APP_DB_HOST !== "" ) {
        REDIRECT_URI = "https://allthatjazz.kr/kakaologin" ;
    }
    console.log( "REDIRECT_URI = " + REDIRECT_URI )

    const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code`;

    const menuLogin =  useSelector((state)=>(state.LoginSlice.menuLogin)) ;
    const { naver } = window;

    const initializeNaverLogin = () => {

    };

    useEffect(() => {
        // 네이버 

        var naver_callback_url = "http://localhost:3000/naverlogin" ; 
        if ( process.env.REACT_APP_DB_HOST !== "" ) 
          naver_callback_url = "https://allthatjazz.kr/naverlogin" ;


        const naverLogin = new naver.LoginWithNaverId({
            clientId: 'TcQ84I_WeqRa64VWONbv',
            callbackUrl: naver_callback_url,
            isPopup: false, // popup 형식으로 띄울것인지 설정
            loginButton: { color: 'white', type: 1, height: '47' }, //버튼의 스타일, 타입, 크기를 지정
            callbackHandle: true,
        });
        naverLogin.init();


    }, []);


    // 구글 시작 
    var google_callback_url = "http://localhost:3000/api/login/googlelogin" ; 
    if ( process.env.REACT_APP_DB_HOST !== "" ) 
      google_callback_url = "https://allthatjazz.kr/api/login/googlelogin" ;


    const googleSocialLogin = useGoogleLogin({
        onSuccess: async response => {
            try {
                const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: {
                        "Authorization": `Bearer ${response.access_token}`
                    }
                })

                console.log("Access Token : " + response.access_token)
                console.log(res.data)

                // 구글은 리다이렉트가 없으니 카카오,네이버와 다르게 가입여부를 여기서 처리한다. 
                // 카카오 id를 활용하여 user 테이블 조회 => name 이 있으면 로긴완료 ( usertype, name , snstype을 리덕스에 등록한다 )
                const user_res = await axios.get(process.env.REACT_APP_DB_HOST+`/api/user/getuser/${res.data.email}`)
                if (user_res.data.errno !== undefined) {
                    alert(user_res.data.message);
                    return;
                }

                // 회원가입에 userid, email 이 활용되어 로그인 전에 저장한다.
                localStorage.setItem('snstype', 'GOOGLE');
                localStorage.setItem('userid', res.data.email);
                localStorage.setItem('email', res.data.email);        

                console.log('menuLogin :' + menuLogin);        


                // 주문을 위한 미니 로그인 
                if( 'foodmenu' === menuLogin ) {


                    dispatch(setMenuToken('true') ) ; 

                    const loginfrom = sessionStorage.getItem('loginfrom') 
                    const table = sessionStorage.getItem('table') 

                    console.log(`FoodLogin: Table : ${table} , Email : ${res.data.email }, userId : ${res.data.email}`)

                    if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
                        navigate(`/`, { replace: false, state: { name: res.data.name } })
                    }
                    else{
                        navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: res.data.name } })
                    }

                    return; 

                }


                // 최초로긴 성공 가입으로
                if ( user_res.data.length === 0  ) {
                    navigate("/join", { replace: true  , state: { snstype: 'GOOGLE', name: res.data.name }} )
                }
                else {

                    console.log("accesstoken ="+ response.access_token)
                    // 가입후 로그인 성공
                    const data = { accesstoken : response.access_token , refreshtoken : 'GOOGLEREFRESHTOKEN' , userid : res.data.email, snstype : 'GOOGLE' }

                    // access token 을 insert 한다 
                    const accesstoken_res = await axios.post(process.env.REACT_APP_DB_HOST+`/api/login/settoken`, data)
                    if (accesstoken_res.data.errno !== undefined) {
                      alert(accesstoken_res.data.message);
                      return;
                    }
                // 그냥 로긴 성공 t1_accesstoken 에 저장한다.
                    dispatch(setUser(user_res.data[0]));

                    localStorage.setItem('name', user_res.data[0].name );
                    localStorage.setItem('aes256', accesstoken_res.data.aes256);
                    
                    
                    const loginfrom = sessionStorage.getItem('loginfrom') 
                    const table = sessionStorage.getItem('table') 

                    //navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
                    if(loginfrom === null || loginfrom === undefined || loginfrom === '' ){
                    navigate(`/`, { replace: false, state: { name: user_res.data[0].name } })
                    }
                    else{
                    navigate(`/${loginfrom}/${table}`, { replace: false, state: { name: user_res.data[0].name } })
                    }
                    

                }




            } catch (err) {
                console.log(err)

            }

        }
    });

    // 구글 끝



return (

    <div id="login-container" >
        <div id="login">
            <div className="login-box">
                <h2 className="login-title">ALL THAT JAZZ</h2>
                <dl className="login-cont">
                    <dt>SNS 계정으로 로그인하기</dt>
                    <dd>
                        <ul>
                            <li> <a href={KAKAO_AUTH_URL}><button className="login-kakao">kakao login</button></a></li>
                            <li onClick={initializeNaverLogin}><button className="login-naver"><div id='naverIdLogin' /></button></li>
                            <li onClick={googleSocialLogin}><button className="login-google"><div id='googleIdLogin' /></button></li>
                            
                        </ul>
                    </dd>
                </dl>
                <p className="login-text"><span>※ 회원가입 시 편리하게 ALL THAT JAZZ를 이용하실 수 있습니다.</span></p>
            </div>

        </div>
        <Footer />

    </div>
 
)

/*
<li onClick={google}>
<GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
        render={(renderProps) => (
              <button className="login-google" onClick={renderProps.onClick} > login</button>
            )}
    />
    </li>    
*/


}


