import styled from "styled-components"
import { useState , useEffect} from "react"
import axios from "axios";



const Container = styled.div`

    border : solid 1px black ; 
    padding : 10px; 
    height : 100px; 
    width : 100% ; 
    display : flex; 
    flex-direction : row; 
    justify-content : left ; 

    h4 { 
        font-size : 14px ; 

        line-height: 40px; 
    }

    input {
        border : solid 1px black; 
        height : 40px ; 
        width : 800px ; 
        margin : 0 20px ; 
    }

    button {
        width : 100px ; 
        height : 40px; 
        background : black ; 
        color : white; 
    }
`


export default function AdminInstakey () {

    const [instkey, setInstakey] = useState('');

    const onInputChange = (e) => {

        setInstakey(e.target.value); 
    }


    useEffect(()=>{

        const data = { metakey : 'instakey'};

        axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/getmeta',data)
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                 return ; 
              }

              setInstakey(result.data[0].metavalue)


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)}) 

        },[])

    const submit = () => { 

        var data = { metakey : 'instakey' , metavalue : instkey}

        axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/setmeta',data)
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                 return ; 
              }

             alert('저장되었습니다. ') 


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)}) 
    }

    return(
        <div id="adm-inner">

           <h2 id="adm-title">Instagram Access Token 관리(메인화면 하단배너)</h2>
            <Container> 
                <h4>인스타 Access Token 입력 </h4>
                <input type='text' value={instkey} onChange={(e)=>onInputChange(e)} />
                <button type = 'button' onClick={submit} > 변경하기 </button>
            </Container> 



        </div> 

    )

    



}