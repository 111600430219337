import { useSelector, useDispatch } from 'react-redux';
import {FiSearch} from "react-icons/fi"
import { useState } from 'react';
import styled from 'styled-components';
import {   useNavigate  } from 'react-router-dom';
import ArtistComponent from '../components/ArtistComponent';
import { useEffect } from 'react';
import { setMaxNumber, setCurrInst, setCurrOrder, setCurrSearch } from '../store/ArtistsSlice';
import axios from 'axios';



export default function ArtistList() { 


    const dispatch = useDispatch();
    //const artistReduxList = useSelector((state)=>{return state.ArtistsSlice.total}) ;

    const [artistReduxList, setArtistReduxList] = useState([]); 


    /* 아티스트 위치 기억하기 시작  11111 로 검색해서 수정 
    const currSearch = useSelector((state)=>{return state.ArtistsSlice.currSearch}) ;
    const initMax = 16 ; 
    const maxNumber = useSelector((state)=>{return state.ArtistsSlice.maxNumber}) ;
    const currOrder = useSelector((state)=>{return state.ArtistsSlice.currOrder}) ;
    const currInst = useSelector((state)=>{return state.ArtistsSlice.currInst}) ;
    아티스트 위치 기억하기 시작 */

    // /* 아티스트 위치 기억 안하고 처음부터 시작  22222 로 검색해서 수정 

    const [currSearch, setCurrSearch] = useState('') ;
    const initMax = 16 ; 
    const [maxNumber, setMaxNumber] = useState(16) ; //  = useSelector((state)=>{return state.ArtistsSlice.maxNumber}) ;
    const [currOrder, setCurrOrder] = useState('CRTDT');
    const [currInst, setCurrInst] = useState('All Artist');



    //  아티스트 위치 기억 안하고 처음부터 끝  /*




    const [artistList,setArtistList ] = useState([]); 
    const baseinst = ['All Artist','Drums','Keyboard','Bass','Trumpet','Disk Jackey','Saxophone','Vocal','Guitar','Piano','Trombone','Percussion','Harmonica','Flute','Violin','Accordion','Viola','Cello','Organ'];
    const [isShowInst, setIsShowInst] = useState(false) ; 
    const navigate = useNavigate();

    
    useEffect( ()=>{

        window.scrollTo(0, 0);
        // 222222 시작 
  
        // 22222  끝 

        //dispatch(setMaxNumber(initMax)); 

    
        axios.get(process.env.REACT_APP_DB_HOST+`/api/artist/gettotal`) 
        .then((response)=>{
    
//            dispatch(setTotal(response.data))
            setArtistReduxList(response.data)
            //setArtistList(response.data.slice(0,maxNumber)); 
            showArtist( currInst , currOrder , maxNumber , response.data) 
         })
        .catch((error)=> {
                console.log(error) ;
        })
    



        

        return () => {
            window.scrollTo(0, 0);
        }

    } ,[]  )

    const onSelectInstrument = (e, i ) => {

        // 22222 시작
           setMaxNumber(initMax);
           setCurrInst(baseinst[i])
        // 22222 끝
        
        /* 11111
        dispatch(setMaxNumber(initMax)); 
        dispatch(setCurrInst(baseinst[i])); 
        */ 



        setIsShowInst( false ); 
        showArtist ( baseinst[i] ,currOrder , initMax , artistReduxList  )
    }


    const showArtist = ( inst , order , max , data ) => {

       // const tempArray = [...artistReduxList] ; 


        const tempArray = data.filter( (el) => ( el.nickname.toLowerCase().includes( currSearch.toLowerCase() )) );

        if(order === 'CLICK'){
            tempArray.sort((a, b) => {
                return b.click - a.click ;
            });
        }else if (order === 'CRTDT'){
            tempArray.sort((a, b) => {
            return b.crtdt - a.crtdt ;
            });
        }

        if(inst === 'All Artist')
            setArtistList(tempArray.slice(0,max));
        else 
            setArtistList(tempArray.filter((el)=>(el.instruments.includes(inst) )).slice(0,max)); 



    }

    const onDropDownMenu = () => {
        setIsShowInst( !isShowInst) ; 
    }

    const onClickMore = () => {

        // 22222 시작
         setMaxNumber(maxNumber + 8);
        // 22222 끝

        /* 11111 시작 
        dispatch(setMaxNumber(maxNumber+8 )); 
        */

        showArtist( currInst , currOrder, maxNumber + 8, artistReduxList);
    }

    const onClickSortbyGood = () => {

        // 22222 시작 
        setMaxNumber(initMax)
        setCurrOrder('CLICK')
        // 22222 끝 

        /* 11111 
        dispatch(setMaxNumber(initMax)); 
        dispatch( setCurrOrder('CLICK')); 
        */

        showArtist( currInst , 'CLICK', initMax , artistReduxList);

    }
    const onClickSortbyCrtdt = () => {

        // 22222 시작 
        setMaxNumber(initMax)
        setCurrOrder('CRTDT')
        // 22222 끝 

        /* 11111 
        dispatch(setMaxNumber(initMax)); 
        dispatch( setCurrOrder('CRTDT')); 
        */

        showArtist( currInst , 'CRTDT', initMax , artistReduxList);
    }

    const searchClick = () => {

        showArtist ( currInst ,currOrder , maxNumber , artistReduxList )

    }

    const onCurrSearch = (e) => { 
        
        // 22222 시작 
          setCurrSearch(e.target.value)
        // 22222 끝 
        
         /* 11111 
        dispatch(setCurrSearch(e.target.value)); 
        */ 
    }


    return (


	<div id="contents" className="sub">

        <div className="sub-back">
			<p onClick={()=>navigate(-1) }>Back</p>
            
		</div>
        

		<div id="atst">

        
			<div className="atst-title">
				<h4 className="main-title">ARTISTS</h4>
				<div className="atst-title__right">
					<div className="atst-select">
						<button onClick={onDropDownMenu}>{currInst}</button>
                        { isShowInst && 
                            <ul>
                                {  baseinst.map((a,i)=>(
                                    <li key={i} onClick={(e)=>onSelectInstrument(e,i)}>{baseinst[i]}</li>
                                ))}
                            </ul>
                        }
					</div> 
					<ul className="atst-sort">
						 <Li onClick={onClickSortbyGood} currOrder={currOrder}>조회순</Li>
						 <Li2 onClick={onClickSortbyCrtdt} currOrder={currOrder}>최신순</Li2>
					</ul>
				</div>
			</div>

            <Search> <input placeholder="Artist/팀 이름으로 검색해 보세요" onChange={onCurrSearch} value={currSearch} /> <div onClick={searchClick}><FiSearch/></div> </Search> 

            <ArtistComponent artistList={artistList}/>

		

			<div className="list-more">
				<button type="button" onClick={onClickMore}>more</button>
			</div>

		</div>



			
	</div>


    )

}


const Em = styled.em `
    background: url(${(props)=>(props.imgsrc)}) no-repeat center center / cover;
`

const Li = styled.li `

    font-weight: ${(props)=> (props.currOrder === 'CLICK') ? '700' : '300' } 
`

const Li2 = styled.li `

    font-weight: ${(props)=> (props.currOrder === 'CRTDT') ? '700' : '300' } 
`


const Search = styled.div`
    display : flex; 
    flex-direction : row ; 
    justify-content : space-between; 
    
    border : solid 1px grey ; 
    border-radius : 30px;
    width : 80%; 
    margin : 1rem auto; 

    div { margin : auto 1.5rem ; 
            font-size : 2.5rem ; 
            cursor : pointer ; 
        }
    
    input { margin : auto 1rem ; outline : none; font-size : 1.5rem ; width : 90%  }
`