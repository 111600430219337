import {createSlice} from '@reduxjs/toolkit'


const OrderSlice = createSlice(
    {   name : 'OrderSlice',
        initialState : { cart : [] , cartTotal : 0, selectedCategory : 0   } ,

        reducers : {
            setCart(state, action) {
                state.cart = action.payload; 
            } ,
            setCartTotal(state, action) {
                state.cartTotal = action.payload; 
            } , 
            setSelectedCategory(state, action) {
                state.selectedCategory = action.payload; 
            }
        }
    })

export let { setCart , setCartTotal, setSelectedCategory } = OrderSlice.actions 


export default OrderSlice.reducer ;   
