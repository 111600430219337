
import { Link } from "react-router-dom";
import Footer from "./Footer";

export default function Join() {

    const onChangeUserType = (e, type) => { localStorage.setItem('userType', type); }

    return (

        <div id="wraplogin" className="wraplogin">
            <div id="contents" className="login">
                <div id="login">
                    <div className="login-box">
                        <h2 className="login-title">ALL THAT JAZZ</h2>
                        <form action="">
                            <dl className="login-cont">
                                <dt>
                                    <ul className="join-sel">
                                        <Link to='/signup/USER' >
                                            <li onClick={(e) => onChangeUserType(e, "USER")}>일반회원 가입</li>
                                        </Link>
                                        <Link to='/signup/ARTIST' >
                                            <li onClick={(e) => onChangeUserType(e, "ARTIST")}>아티스트회원 가입</li>
                                        </Link>
                                    </ul>
                                </dt>
                            </dl>
                        </form>
                        <div className="login-join">
                            <p>회원가입시 편리하게 <br />ALL THAT JAZZ를 이용하실 수 있습니다.</p>
                            <p>아티스트 회원은 관리자 승인 후 이용가능합니다. </p>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>


    )

}