import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ArtistComponent from '../../components/ArtistComponent';
import axios from 'axios';


export default function MyArtist() { 

    const userid = localStorage.getItem('userid') ; 
    //const artistReduxList = useSelector((state)=>{return state.ArtistsSlice.total}) ;
    const [maxNumber, setMaxNumber] = useState(16) ;
    const [artistList,setArtistList ] = useState([]); 
    const [currOrder, setCurrOrder] = useState('CLICK') ;
    

    useEffect(()=>{


        if(userid === null || userid === '' || userid === undefined )
        {   alert ('로그인을 해야 합니다. ') ; 
            return ; 
        }

        const data = { userid:userid} ;

        axios.post(process.env.REACT_APP_DB_HOST+'/api/user/getmyfavoriteArtist', data)
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
              }

              setArtistList(result.data) ;

        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 

    } , [])


 



    const showArtist = ( order , max ) => {

        const tempArray = [...artistList] ; 
        if(order === 'CLICK'){
            tempArray.sort((a, b) => {
                return b.click - a.click ;
            });
        }else if (order === 'CRTDT'){
            tempArray.sort((a, b) => {
            return b.crtdt - a.crtdt ;
            });
        }

           setArtistList(tempArray.slice(0,max));
    }


    const onClickMore = () => {
        setMaxNumber(maxNumber + 8);

        showArtist(  currOrder, maxNumber + 8);
    }

    const onClickSortbyGood = () => {
        setMaxNumber(16);
        setCurrOrder('CLICK');

        showArtist( 'CLICK', 16);

    }
    const onClickSortbyCrtdt = () => {
        setMaxNumber(16);
        setCurrOrder('CRTDT')
        showArtist( 'CRTDT', 16);
    } 


    return (


	    <div id="contents" className="sub">


            <div id="mysub-cont">
				<div className="mpg-title">
					<h4>아티스트 찜 목록</h4>
                    <div className="atst-title__right">
                        <ul className="atst-sort">
                            <Li onClick={onClickSortbyGood} currOrder={currOrder}>조회순</Li>
                            <Li2 onClick={onClickSortbyCrtdt} currOrder={currOrder}>최신순</Li2>
                        </ul>
                    </div>
                </div>
                <ArtistComponent artistList={artistList}/>
			</div>
		

			<div className="list-more">
				<button type="button" onClick={onClickMore}>more</button>
			</div>

		</div>


    )

}

const Li = styled.li `

    font-weight: ${(props)=> (props.currOrder === 'CLICK') ? '700' : '300' } 
`

const Li2 = styled.li `

    font-weight: ${(props)=> (props.currOrder === 'CRTDT') ? '700' : '300' } 
`