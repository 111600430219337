import '../css/MainSlide.css';
import { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../css/animate.min.css'; 
import axios from 'axios';

export default function MainSlide() {

    const [banner, setBanner] = useState([]); 

    useEffect(()=>{


        console.log("rocess.env.REACT_APP_DB_HOST =" + process.env.REACT_APP_DB_HOST)

        axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/getbannershow')
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
            }

            console.log(result.data)
            setBanner(result.data) ;

        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 

        


    },[])


    const prevRef = useRef(); 
    const nextRef = useRef(); 


    const PrevArrow = (props) => {
        const { style, onClick } = props;
        return (
          <div
            ref={prevRef}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
    }

    const NextArrow = (props) => {
        const { style, onClick } = props;
        return (
          <div
            ref={nextRef}
            style={{ ...style, display: "none", background: "green" }}
            onClick={onClick}
          />
        );
    }



    const settings = {
        swipe: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 2000,
        arrows: true,
        dots: false,
        fade: true,
        pauseOnHover: false,
        //dotsClass: 'main-event__dots' , 
        
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [{
            breakpoint: 768,
            settings: {
                swipe: true
            }
        }]
    }



    const onClickPrev = () => { 
        prevRef.current.click(); 
    }

    const onClickNext = () => { 
        nextRef.current.click(); 
    }

 
    /*
    useEffect(() => {

        const timer = setInterval(() => {
            if (currentSlide >= total_slides) setcurrentSlide(0);
            else setcurrentSlide(currentSlide + 1);
        }
            , 3000)

        slideRef.current.style.transition = "all 0.5s ease-in-out";
        slideRef.current.style.transform = `translateX(-${currentSlide}00%)`;
        return () => {
            clearInterval(timer);
        }

    }, [currentSlide]);
*/

    return (


        <div className="main-event">
            <div className="main-event__slider">
                <Slider {...settings} >
                {banner && banner.map((a, i) =>(
                    <div className="main-event__slider--banner" key={i}>
                        <Div className="main-event__slider--img" imgsrc={ '/publicimg/' + banner[i].imageurl} ></Div>
                        <div className="main-event__slider--text">
                            <em> {banner[i].subtitle }</em>
                            <h4>{banner[i].title }</h4>
                            <p>{banner[i].target }</p>
                        </div>
                    </div>
                ))}
                </Slider>
			</div>

            <div className="main-event__control">
                <div className="main-event__arrow">
                    <button className="main-event__prev" onClick={onClickPrev}>prev</button>
                    <button className="main-event__next" onClick={onClickNext}>next</button>
                </div>
           
            </div>
        </div>




    )

}


const Div = styled.div`
    background: url(${(props)=>props.imgsrc})no-repeat center center / cover; 

`




