import styled from "styled-components"
import DatePicker from "react-datepicker"
import "../css/datepicker.css"
import {ko} from "date-fns/esm/locale"

import { useState, useEffect } from "react"
import axios from "axios"
      

export default function AdminBanner() {

    const [banner, setBanner] = useState([]) ;
    //const [localImageUrl, setLocalImageUrl] = useState([]); 
    //const [preview, setPreview] = useState([]);
    //const [ispreview, setIspreview] = useState([]);


    useEffect(()=>{
        
        axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/getbanner' )
        .then((result) => { 

              if(result.data.errno !== undefined ) {
                alert(result.data.message); 
                return ; 
              }

              setBanner(result.data);
              


        })
        .catch((error)=> {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 


    },[])

    const setFromDate = (date, i) => {

        const temp = [...banner]; 
        temp[i].fromdt = date ; 
        setBanner(temp);
     }

     const setToDate = (date, i) => {

        const temp = [...banner]; 
        temp[i].todt = date ; 
        setBanner(temp);
     }

     const onChangeToggle = (e,i) => {

        const temp = [...banner]; 
        temp[i].isshow = !temp[i].isshow ; 
        setBanner(temp); 
     }

     const onSaveClick = () => {


        for(let i=0 ; i < banner.length ; i++ ){

            if(banner[i].ispreview){
                let formdata = new FormData();
                formdata.append("selectImg", banner[i].localImageUrl);
                axios.post(process.env.REACT_APP_DB_HOST+"/upload", formdata)
                .then((res) => { 

                    if(res.data.errno !== undefined ) {
                        alert(res.data.message); 
                        return ; 
                    }

                    // 1. 그림 경로 update
                    const temp = [...banner] ; 

                    temp[i].imageurl = res.data.filename ;
                    delete temp[i].preview;
                    delete temp[i].localImageUrl; 
                    setBanner(temp);
                    

                    // banner[i] 는 상태 업데이트가 되지 않아 temp 를 활용  
                    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/setbanner', temp[i] )
                    .then((result) => { 
        
                            if(result.data.errno !== undefined ) {
                            alert(result.data.message); 
                            return ; 
                            }
                            
                            const temppre = [...banner] ; 
                            temppre[i].ispreview = false ;
                            setBanner(temppre);
 
        
                    })
                    .catch((error)=> {
                        alert("server error occured!!! Please check console log (F12) ")
                        console.log(error)
                    }) 

                })    
            }
            else {
                
                axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/setbanner', banner[i] )
                .then((result) => { 

                        if(result.data.errno !== undefined ) {
                        alert(result.data.message); 
                        return ; 
                        }


                })
                .catch((error)=> {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                }) 

            }
        } 
        alert('저장되었습니다. ') ;

        
        //navigate("/adminhome/adminbanner", { replace: true })

     }


     const onChangeSubTitle = (e,i) => {

        const temp = [...banner] ; 
        temp[i] = {...temp[i], subtitle : e.target.value }; 
        setBanner(temp); 
     }

     const onChangeTitle = (e,i) => {

        const temp = [...banner] ; 
        temp[i] = {...temp[i], title : e.target.value }; 
        setBanner(temp); 
     }

     const onChangeTarget = (e,i) => {

        const temp = [...banner] ; 
        temp[i] = {...temp[i], target : e.target.value }; 
        setBanner(temp); 
     }

     const onClickUp = (up) => {

        if (up === 0) return;

        const temp = [...banner]
        const tempObject = temp[up];
        temp[up] = { ...temp[up - 1], ordernum:  temp[up].ordernum };
        temp[up - 1] = { ...tempObject, ordernum: temp[up - 1].ordernum };
        setBanner(temp);
    }

    const onClickDown = (down) => {

        if (down === banner.length) return;
        const temp = [...banner]
        const tempObject = temp[down];
        temp[down] = { ...temp[down + 1], ordernum:  temp[down].ordernum };
        temp[down + 1] = { ...tempObject, ordernum: temp[down + 1].ordernum };
        setBanner(temp);

    }


     const encodeFileToBase64 = (e, i ) => {
        



        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);


        return new Promise((resolve) => {
            reader.onload = () => {

                const temp = [...banner];
                temp[i] = { ...temp[i], localImageUrl : e.target.files[0] , preview: reader.result, ispreview : true };
                setBanner(temp)

                resolve();
            };

        });
    };



    return (
            <div id="adm-inner">

                <h2 id="adm-title">메인배너 관리</h2>

                <table className="adm-tb">
                    <thead>
                        <tr>
                            <th></th>
                            <th>순번</th>
                            <th>이미지</th>
                            <th>상단타이틀</th>
                            <th>메인타이틀</th>
                            <th>하단(날짜)</th>
                            <th>게시기간</th>
                            <th>게시</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    {banner.map((a,i)=>(
                        <tr key={i}>
                            <td>{banner[i].ordernum}</td>
                            <td>
                                <ul className="adm-seq">
                                    <li><button type="button" title="up" onClick={()=>onClickUp(i)}>▲</button></li>
                                    <li><button type="button" title="down" onClick={()=>onClickDown(i)}>▼</button></li>
                                </ul>
                            </td>  
                            <td>
                                <div className = 'adm-ban__thumb'>
                                {banner[i].ispreview?<Img src={banner[i].preview} alt="" /> :  <Img src={'/publicimg/' + banner[i].imageurl} alt="" /> }
                                <input type="file" accept='image/*'   id={'FILE'+banner[i].autopk} className="adm-ban__change" onChange={(e)=>encodeFileToBase64(e,i)}  />
                                <label className='adm-ban__change_label' htmlFor={'FILE'+banner[i].autopk}>change</label>
                                </div>
                            </td>
                            <td><input type="text" name="" id="" placeholder="상단타이틀 입력" value={banner[i].subtitle|| ''} className="w100" onChange={(e)=>onChangeSubTitle(e,i)} /></td>
                            <td><input type="text" name="" id="" placeholder="메인타이틀 입력" value={banner[i].title|| ''} className="w100" onChange={(e)=>onChangeTitle(e,i)} /></td>
                            <td><input type="text" name="" id="" placeholder="하단(날짜) 입력" value={banner[i].target|| ''} className="w100" onChange={(e)=>onChangeTarget(e,i)} /></td>
                            <td>
                                <ul className="adm-ban__date">
                                    <li> <div className='adm-datepicker'>
                                          <DatePicker locale={ko} dateFormat='yyyy-MM-dd' selected={new Date(banner[i].fromdt)} onChange={(date) => setFromDate(date,i)} />
                                          </div> </li>
                                    <li>~</li>
                                        
                                    <li> <div className='adm-datepicker'>
                                         <DatePicker locale={ko} dateFormat='yyyy-MM-dd'  selected={new Date(banner[i].todt)} onChange={(date) => setToDate(date,i)} /> 
                                         </div></li>
                                </ul>
                            </td>
                            <td>
                                <label className="mypage-switch__toggle">
                                    <input type="checkbox" checked={banner[i].isshow}  onChange={(e)=>onChangeToggle(e,i)}/>

                                    <span></span>
                                </label>
                            </td>
                          
                        </tr>
                    ))}
                    </tbody>
                </table>

                <button className="adm-tb__btn" onClick={()=>onSaveClick()}>저장</button>
            </div>
     )

}


const Img = styled.img` 
    display: block; width: 180px; height: 100%; padding-bottom: 0px; position: relative;
`