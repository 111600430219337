import DatePicker from "react-datepicker"
import "../css/datepicker.css"
import { ko } from "date-fns/esm/locale"
import { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import SeatModal from "../components/SeatModal";
import { useDispatch } from 'react-redux';
import { setSelectedDateAction } from '../store/ReservationSlice';
import { useNavigate } from 'react-router-dom';

const MonthSelector = styled.div` 
      display : flex ; 
      flex-direction : row ;
      cursor:pointer ; 

      justify-content : space-between ; 
      padding : 0rem 1rem 0rem 1rem ; 
      background : white ; 
      font-size : 15px ; 
      line-height : 60px ; 
      width : 100%; 
      

      button { font-size : 1rem; background : white; }
`

const Tr = styled.tr`

    background : ${(props) => props.status === '노쇼' ? '#f1f1f1' : props.status === '예약취소' ? '#FFB3B3' : props.status === '방문완료' ? '#9BECFF' : props.status === '결제전' ? '#E1E1E1' : 'white'}

`



export default function AdminFront() {

    const dispatch = useDispatch();
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState({ yyyy: today.getFullYear(), mm: today.getMonth() + 1, dd: today.getDate() });
    const [currentDate, setCurrentDate] = useState(`${selectedDate.yyyy}-${selectedDate.mm.toString().padStart(2, '0')}-${selectedDate.dd.toString().padStart(2, '0')}`);

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState('전체');
    const [resItem, setResItem] = useState([]);
    const [filteredItem, setFilteredItem] = useState([]);
    const [seatModalOpen, setSeatModalOpen] = useState(false);

    const [selectedCount, setSelectedCount] = useState(0);


    const [bu, setBu] = useState([false, false, false]);
    const [I, setI] = useState(0);
    const [J, setJ] = useState(0);
    const navigate = useNavigate();


    const [seatStatusModal, setSeatStatusModal] = useState(false); 
    const [currentSeatStatusBu, setCurrentSeatStatusBu] = useState(0); 

    const [seatReservationModal, setSeatReservationModal] = useState(false);
    const [modalReservation, setModalReservation] = useState(false);
    const [adminSeats, setAdminSeats] = useState([]);
    const [reservationCount, setReservationCount] = useState(1);
    const [admindesc, setAdmindesc] = useState('');
    const [adminBu, setAdminBu] = useState(0);

    useEffect(() => {

        const temp = { yyyy: today.getFullYear(), mm: today.getMonth() + 1, dd: today.getDate() }

        getData(temp.yyyy, temp.mm, temp.dd);
        //setSelectedDate(temp);
        changeDate(temp.yyyy, temp.mm, temp.dd);
    }, [])

    const prevDay = () => {

        const dt = new Date(selectedDate.yyyy, selectedDate.mm - 1, selectedDate.dd - 1);
        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
        setCurrentDate(dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
        getData(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
    }

    const nextDay = () => {

        const dt = new Date(selectedDate.yyyy, selectedDate.mm - 1, selectedDate.dd + 1);
        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
        setCurrentDate(dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
        getData(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
    }


    const datepickerChange = (dt) => {

        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
        setCurrentDate(dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
        getData(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());


    }

    const changeDate = (yyyy, mm, dd) => {
        setSelectedDate({ yyyy: yyyy, mm: mm, dd: dd })
        dispatch(setSelectedDateAction({ yyyy: yyyy, mm: mm, dd: dd }));
    }

    const getData = async (yyyy, mm, dd) => {

        const body = { yyyy: yyyy, mm: mm, dd: dd }


        try {
            const result = await axios.post(process.env.REACT_APP_DB_HOST + '/api/reservation/getallresitem', body)
            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            const result_items = await axios.post(process.env.REACT_APP_DB_HOST + '/api/reservation/getallresitems', body)

            if (result_items.data.errno !== undefined) {
                alert(result_items.data.message);
                return;
            }

            const temp = [...result.data];
            for (var i = 0; i < temp.length; i++) {
                temp[i] = { ...temp[i], items: result_items.data.filter((el) => (el.uniquekey === temp[i].uniquekey)) }
            }

            setResItem(temp);

            setFilteredItem(temp.filter((el) => (el.phone.includes(searchText) || el.name.includes(searchText))))




        }
        catch (error) {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }



    }


    const onSeatModalClose = (selected) => {

        if (selected !== 'JUSTCLOSE') {

            const body = { uniquekey: filteredItem[I].items[J].uniquekey, keynum: filteredItem[I].items[J].keynum, seats: [...selected] }

            axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/changeseat', body)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }



                    getData(selectedDate.yyyy, selectedDate.mm, selectedDate.dd);
                    alert('변경 되었습니다. ')
                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })

        }
        //setSeats(selected);

        setSeatModalOpen(false);
    }

    const onSeatReservationClose = (selected) => {
        if (selected !== 'JUSTCLOSE') {

            setAdminSeats(selected); 

        }

        setSeatReservationModal(false);

    }


    const onChangeSearchText = (e) => {

        setSearchText(e.target.value)

        if (searchType === '전체')
            setFilteredItem(resItem.filter((el) => ((el.phone.includes(e.target.value) || el.name.includes(e.target.value)))))
        else
            setFilteredItem(resItem.filter((el) => ((el.phone.includes(e.target.value) || el.name.includes(e.target.value)) && el.status === searchType)))

    }

    const onSearchType = (e) => {
        setSearchType(e.target.value)

        if (e.target.value === '전체')
            setFilteredItem(resItem.filter((el) => ((el.phone.includes(searchText) || el.name.includes(searchText)))))
        else
            setFilteredItem(resItem.filter((el) => ((el.phone.includes(searchText) || el.name.includes(searchText)) && el.status === e.target.value)))

    }

    const onChangeSeat = (i, j) => {


        setI(i);
        setJ(j);
        setSelectedCount(filteredItem[i].items[j].person);

        const temp = [false, false, false];
        temp[filteredItem[i].items[j].bu] = true;
        setBu(temp);
        setSeatModalOpen(true);
    }
    const onChangeStatus = (i, status) => {

        const data = { uniquekey: filteredItem[i].uniquekey, status: status }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/changestatus', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData(selectedDate.yyyy, selectedDate.mm, selectedDate.dd);
                alert('변경 되었습니다. ')
            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }

    const onCancel = (i, p_reason) => {


        if (!window.confirm(`정말 ${p_reason} 처리 하시겠습니까?`)) return;


        const data = {
            merchant_uid: filteredItem[i].uniquekey,
            cancel_request_amount: filteredItem[i].amount,
            reason: p_reason,
        }

        axios.post('/api/admin/cancelcard', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData(selectedDate.yyyy, selectedDate.mm, selectedDate.dd);
                alert(`${p_reason} 처리 되었습니다. `)

            })
            .catch((error) => {
                alert(error.response.data)
                console.log(error)
            })

    }


    const onChangePerson = (e, i, j) => {

        const tempArray = [...filteredItem];
        tempArray[i].items[j].person = e.target.value;
        setFilteredItem(tempArray);

    }

    const onWorkIn = () => {

        setReservationCount(1); 
        setAdminBu(0); 
        setAdminSeats([]); 
        setModalReservation(true);
        //        navigate("/adminhome/walkin", { replace: false })

    }

    const onCloseReservation = () => {

        setModalReservation(false);

    }

    const onSeatModalOpen = () => {

        setSeatReservationModal(true);
    }


    const onSelectCount = (e) => {

        setAdminSeats([]);
        setReservationCount(e.target.value);

    }

    const onSelectBu = (e) => {

        setAdminSeats([]);


        //const temp = [false, false, false];
        //temp[e.target.value] = true;
        setAdminBu(e.target.value);

    }


    const onChangeAdmindesc = (e) => {

        setAdmindesc(e.target.value);
    }

    const moveToCheck = async () => {

/*
        if (reservationCount === 0) {

            alert('인원수를 입력해주세요.');

            return false;
        }
*/
        if (adminSeats.length === 0) {

            alert('좌석을 선택해주세요.');

            return false;
        }


        try {
            // 데이타를 저장하고 

            const uniquekey = Math.random().toString(36).substring(2, 12);

            var checkdata

            checkdata = {
                uniquekey: uniquekey,
                userid: localStorage.getItem('userid'),
                admindesc : admindesc, 
                amount: 0,
                status: '예약완료',
            }

            var checkitems = [];
            var seats = [];
            var k = 0;

            checkitems[0] = {
                uniquekey: uniquekey,
                keynum: 0,
                yyyy: selectedDate.yyyy,
                mm: selectedDate.mm,
                dd: selectedDate.dd,
                bu: adminBu,
                person: reservationCount,
                price: 0
            }

            for (var j = 0; j < adminSeats.length; j++) {
                seats[k] = {
                    uniquekey: uniquekey,
                    keynum: 0,
                    seatid: adminSeats[j].seatid
                }
                k++;
            }

            const MergeObject = { checkdata: { ...checkdata }, checkitems: [...checkitems], seats: [...seats] }

            if( checkdata.userid === null || checkdata.userid === undefined || checkdata.userid === '' ){
                alert('로그인 시간이 너무 오래 되어 재로그인이 필요 합니다. ')
                
                localStorage.setItem('aes256', "") ; 
			    localStorage.setItem('userid', "" );
			    localStorage.setItem('snstype', "") ;
			    localStorage.setItem('name', "") ;
                
                sessionStorage.setItem('loginfrom','prereservation') 
                sessionStorage.setItem('table','') 
    
                navigate(`/login`, { replace: false }); 
                return ;
            }


            const result = await axios.post(process.env.REACT_APP_DB_HOST + '/api/reservation/precheckout', MergeObject);
            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            getData(selectedDate.yyyy, selectedDate.mm, selectedDate.dd);
            alert('어드민 예약이 완료 되었습니다.') 
            onCloseReservation();


        } catch (err) {

            if (err.response.status === 510) {
                alert('조금 전 선택한 자리를 다른분께서 예약하였습니다. 자리 재선택이 필요합니다.  ');
                return;
            }
            else {
                alert(err);
            }


            console.log("Error >> " + err);
        }



    }


    const seatStatus = (bu) => {

        setCurrentSeatStatusBu(bu) ; 
        setSeatStatusModal(true); 
    }

    const closeSeatStatus = () => {
        setSeatStatusModal(false); 
    }

    return (
        <div id="adm-inner">

            <h2 id="adm-title">공연예약확인</h2>

            <form action="">
                <div className="adm-sch">
                    <table>
                        <tbody>
                            <tr>
                                <td className="adm-sch__date">
                                    <MonthSelector>
                                        <div onClick={() => prevDay()} >〈</div>
                                        <div >
                                            <DatePicker locale={ko} dateFormat='yyyy-MM-dd' selected={new Date(currentDate)} onChange={(date) => datepickerChange(date)} />
                                        </div>
                                        <div onClick={nextDay} >〉</div>




                                    </MonthSelector></td>
                                <td className="adm-sch__sel">
                                    <select name="" id="" onChange={(e) => onSearchType(e)}>
                                        <option value="전체">전체</option>
                                        <option value="예약완료">예약완료</option>
                                        <option value="노쇼">노쇼</option>
                                        <option value="예약취소">예약취소</option>
                                        <option value="방문완료">방문완료</option>
                                    </select>
                                </td>
                                <td><input type="text" name="" id="" placeholder="검색어를 입력해 주세요.(이름, 전화번호)" onChange={(e) => onChangeSearchText(e)} /></td>
                                <td className="adm-sch__submit"><button type='button' onClick>검색</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </form>



            <div className="adm-tb__info">
                <div> 예약자  </div>
                <ul>
                    <li><button className="grn" onClick={() => seatStatus(0)}>1부 좌석현황</button></li>
                    <li><button className="blue" onClick={() => seatStatus(1)}>2부 좌석현황</button></li>
                    <li><button className="org" onClick={() => seatStatus(2)}>3부 좌석현황</button></li>

                    <li><button className="bk" onClick={() => onWorkIn()}>예약추가</button></li>
                    <li><button>새로고침</button></li>
                </ul>
            </div>

            <table className="adm-tb">
                <thead>
                    <tr>
                        <th style={{width:"50px"}}>No.</th>
                        <th style={{width:"100px"}}>예약자명</th>
                        <th style={{width:"50px"}}>성별</th>
                        <th style={{width:"70px"}}>예약일</th>
                        <th style={{width:"100px"}}>전화번호</th>
                        <th style={{width:"300px"}}>시간/좌석</th>
                        <th style={{width:"100px"}}>예약금</th>
                        <th style={{width:"100px"}}>현재상태</th>
                        <th style={{width:"100px"}}>Admin예약명</th>
                        <th colSpan="3" style={{width:"250px"}} >처리</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredItem.map((a, i) => (
                        <Tr key={i} status={filteredItem[i].status}>
                            <td>{i + 1}</td>
                            <td>{filteredItem[i].name}</td>
                            <td>{filteredItem[i].sex === 'M' ? '남' : '여'}</td>
                            <td>{filteredItem[i].crtdt}</td>
                            <td>{filteredItem[i].phone.substr(0, 3) + '-' + filteredItem[i].phone.substr(3, 4) + '-' + filteredItem[i].phone.substr(7, 4)} </td>
                            <td style={{ width: '280px' }} >
                                <table>
                                    <tbody >
                                        {filteredItem[i].items.map((b, j) => (
                                            <tr key={j} className="adm-tb-item" >
                                                <td style={{ width: '30px' }}>
                                                    {filteredItem[i].items[j].bu + 1}부
                                                </td>

                                                <td >
                                                    {filteredItem[i].items[j].nametag}
                                                </td>

                                                <td style={{ width: '30px' }}>
                                                    <input id='PeopleInput' onChange={(e) => onChangePerson(e, i, j)} value={filteredItem[i].items[j].person} />

                                                </td>
                                                <td style={{ width: '20px' }}>
                                                    명
                                                </td>
                                                <td style={{ width: '80px' }}>
                                                    <button className="adm-tb__btn" onClick={() => onChangeSeat(i, j)}>좌석변경</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                            <td>{filteredItem[i].amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원</td>
                            <td>{filteredItem[i].status}</td>
                            <td>{filteredItem[i].admindesc}</td>
                            <td>{filteredItem[i].status !== '노쇼' && filteredItem[i].status !== '예약취소' && filteredItem[i].status !== '방문완료' && filteredItem[i].status !== '결제전' &&
                                <button className="adm-tb__btn" onClick={() => onChangeStatus(i, '노쇼')} >노쇼</button>}</td>
                            <td>{filteredItem[i].status !== '노쇼' && filteredItem[i].status !== '예약취소' && filteredItem[i].status !== '방문완료' && filteredItem[i].status !== '결제전' &&
                                <button className="adm-tb__btn" onClick={() => onCancel(i, '예약취소')}>예약취소</button>}</td>
                            <td>{filteredItem[i].status !== '노쇼' && filteredItem[i].status !== '예약취소' && filteredItem[i].status !== '방문완료' && filteredItem[i].status !== '결제전' &&
                                <button className="adm-tb__btn" onClick={() => onChangeStatus(i, '방문완료')}>방문완료</button>} </td>
                        </Tr>
                    ))}

                </tbody>
            </table>


            {seatStatusModal && <SeatModal close={closeSeatStatus} people={0} bu1={currentSeatStatusBu == 0? true : false } bu2={currentSeatStatusBu == 1? true : false } bu3={currentSeatStatusBu == 2? true : false } gap={100} selectedDate={selectedDate} />}
            
            {seatModalOpen && <SeatModal close={onSeatModalClose} people={selectedCount} bu1={bu[0]} bu2={bu[1]} bu3={bu[2]} gap={100} selectedDate={selectedDate} />}

            {seatReservationModal && <SeatModal close={onSeatReservationClose} people={reservationCount} bu1={adminBu == 0? true : false } bu2={adminBu == 1? true : false } bu3={adminBu == 2? true : false } gap={100} selectedDate={selectedDate} admin={'ADMIN'} />}


            {modalReservation &&

                <div className="adm-res-wrap">
                    <div className="adm-res-box" >
                        <ul className="adm-res__ul">
                            <li><h4> 어드민 예약추가 </h4></li>
                            <li><span className="adm-res__x"  onClick={onCloseReservation}>X</span></li>
                        </ul>
                        <ul className="adm-res__ul">
                            <li>
                                <h2>{selectedDate.yyyy}-{selectedDate.mm.toString().padStart(2, '0')}-{selectedDate.dd.toString().padStart(2, '0')}</h2>
                            </li>
                            <li>

                                <select defaultValue='0' onChange={onSelectBu}>
                                    <option value="0">1부</option>
                                    <option value="1">2부</option>
                                    <option value="2">3부</option>
                                </select>
                            </li>
                            <li>
                                <label >인원</label>
                                <select defaultValue='1' onChange={onSelectCount}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </select>
                            </li>
                            <li><button onClick={onSeatModalOpen}>좌석선택</button></li>

                            <li >
                                {adminSeats.map((a, i) => (
                                    <p key={i}>{adminSeats[i].nametag}</p>
                                ))}
                            </li>


                            <li><td>방문자 <input type="text" name="" id="" placeholder="이름,전화번호 등 식별정보를 입력" onChange={(e) => onChangeAdmindesc(e)} /></td></li>

                            
                            <li>
                                <button onClick={moveToCheck}> 확정 </button>
                            </li>

                        </ul>

                    </div>
                </div>

            }



        </div>


    )
}