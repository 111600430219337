import axios from "axios";
import { useState, useEffect } from "react"
import MyTeamSetup from "../pages/MY/MyTeamSetup";

import ArtistDetail from "../pages/ArtistDetail";

export default function AdminTeam(){


//<input type="checkbox" checked={instruments.includes(a)?true:false} id={a} value={a} onChange={(e)=>checkedInstrument(e.target.checked,e.target.value) } /><label  htmlFor={a}>{' '+a}</label>



const [teamList, setTeamList] = useState([]) ;
const [currentTeam, setCurrentTeam] = useState(0) ;
const [moreEnable, setMoreEnable] = useState(false); 
const [isModal , setIsModal] = useState(false); 
const [isEditModal, setIsEditModal] = useState(false); 

const [condition, setCondition] = useState({ 
    type : '팀이름' , 
    content : '' , 
    sex : '성별전체' , 
    snstype : '가입경로전체' , 
    lastpk : 999999999
})

const onChangeType = (e) => {
    setCondition ( {...condition , type : e.target.value } ) ; 
    setMoreEnable(false); 
}

const onChangeContent = (e) => {
    setCondition ({...condition, content : e.target.value }) ;
    setMoreEnable(false); 
}

const onChangeSex = (e) => {
    setCondition ({...condition, sex : e.target.value });
    setMoreEnable(false); 
}

const onChangeSnstype = (e) => {
    setCondition ({...condition, snstype : e.target.value });
    setMoreEnable(false); 
}


useEffect( ()=> {

    postTeamData(0) ; 
} , [] )


const postTeamData = (start) => {

    // 조회 버튼, Team List 를 새로고침 
    if( start === 0 ) {
        condition.lastpk = 999999999 ; 
        setMoreEnable(true); 
    }
    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/postteamlist', condition)
        .then((result) => { 

            if( start === 1 ) {
                const temp = [...teamList, ...result.data]
                setTeamList(temp); 
            }else
            {
                setTeamList(result.data); 
            }

            condition.lastpk = result.data[29].autopk ; 
 
            if( result.data[29].autopk === null )  setMoreEnable(false); 

        })
        .catch((error)=> {
          //  alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        }) 
}

const closeModal = () => {

    setIsEditModal(false); 
}


const onDetail = (i) => {

    setCurrentTeam(i)

    setIsModal(!isModal);

}

const onEdit = (i) => {


    setCurrentTeam(i)

    setIsEditModal(!isEditModal);
}


const onDelete = (i) => {


    if( !window.confirm( "삭제 시 복구 불가능합니다. 정말 삭제하시겠습니까?" ) ) return ; 

    const data = {teamname : teamList[i].teamname }

    axios.post(process.env.REACT_APP_DB_HOST+'/api/admin/deleteteam', data)
    .then((result) => { 

        if (result.data.errno !== undefined) {
            alert(result.data.message);
            return;
        }

        alert('삭제되었습니다. (화면 새로고침 필요) ')
 
    })
    .catch((error)=> {
      //  alert("server error occured!!! Please check console log (F12) ")
        console.log(error)
    }) 


}


    return(

        <div>

            <div className="adm-sch">
                <table>
                    <tbody>
                        <tr>
                            <th className="adm-sch__text">검색조건</th>
                            <td className="adm-sch__sel">
                                <select onChange={onChangeType} >
                                    <option value="팀이름">팀이름</option>
                                    <option value="팀멤버">팀멤버</option>
                                    <option value="악기">악기</option>
                                </select>
                            </td>
                            <td><input type="text" onChange={onChangeContent} placeholder="검색어를 입력해 주세요." /></td>
                            <td className="adm-sch__submit">
                                <button onClick={()=>postTeamData(0)}>검색</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="adm-tb__info">
        
			</div>

			<table className="adm-tb">
				<thead>
                    <tr>    
						<th>팀명</th>
						<th>악기</th>
						<th>소개</th>
						<th>팀생성일</th>
						<th>팀멤버</th>
                        <th>상세</th>
                        <th>수정</th>
                        <th>삭제</th>
					</tr>
				</thead>
				<tbody>
  
                     
                    {teamList.map( (a,i)=>(
                        <tr  key = {i}>
                            <td>{teamList[i].teamname}</td>
                            <td>{teamList[i].instruments}</td>
                            <td>{teamList[i].introduce}</td>
                            <td>{teamList[i].crtdt}</td>
                            <td>{teamList[i].teammember}</td>
                            <td><button className="adm-tb__btn" onClick={()=>onDetail(i)} >상세</button></td>
                            <td><button className="adm-tb__btn" onClick={()=>onEdit(i)} >수정</button></td>
                            <td><button className="adm-tb__btn" onClick={()=>onDelete(i)} >삭제</button></td>
                        </tr>
					))}

				</tbody> 
			</table>

            {isModal && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsModal(!isModal)}>X </h2>
                    <ArtistDetail userid={teamList[currentTeam].teamname} usertype='TEAM'adminmode='ADMIN'/>                  
                </div>
            }

            {isEditModal && 
                <div className="adm-modal__box"> <h2 onClick={()=>setIsEditModal(!isEditModal)} >X </h2>
                    <MyTeamSetup teamname={teamList[currentTeam].teamname} mode='EDIT' close={closeModal}/>
                </div>
            }                        

        </div>


    )
}