
import styled from "styled-components";
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import SeatModal from "../components/SeatModal";
import { getDayofWeek } from "../components/Util";
import { setLoginFrom } from "../store/LoginSlice";
import { useDispatch, useSelector } from 'react-redux';


const SeatContainer = styled.div`
	display:flex; 
	flex-direction: row;
	justify-content: space-between;
	width : 50%; 


	@media (max-width:768px) {
		width : 100%;  
		border-top : solid 1px #f1f1f1 ; 
		margin : 10px 0px ;
		padding : 10px 0px 0px 0px; 
	}

`


const SelectChair = styled.div` 

	
	margin: auto 0px  ; 
	width : 25%; 
	display:flex; 
	text-align: center; 

	span { margin: 0 auto ; height: 40px; width : 70px ; line-height: 40px; font-size: 12px; background: none; border: solid 1px #000; padding: 0px 10px; border-radius: 30px; cursor : pointer  }

	@media (max-width:768px) {
		
	}


`

const SelectedChair = styled.div`
	text-align: center;
	display : flex; 
	width : 50px; 
	flex-direction : column; 
	justify-content : center ; 
	line-height: 20px; 
	
	
`

const Cart = styled.div`

	 margin: auto 0px  ; 
	 width : 80px; 
	 text-align : center ; 
	 display : flex ; flex-direction : row ;
	 span { display : flex ; flex-direction : column ; width : 80px;  }
     button { height: 40px; width: 80px;  line-height: 38px; font-size: 14px; background: black; color:white; border: solid 1px #000; padding: 0 10px; border-radius: 30px; }


`

const Box = styled.div`

    position : absolute ; 
    left : ${(props) => (props.x)}px ;
    top : ${(props) => (props.y)}px ;
    z-index : 3; 

    cursor : ${(props) => (props.occupied === 'OCCUPIED' ? '' : 'pointer')} ; 
    img {
        z-index : 3; 
    }
    h4 {
        position : absolute; 
        text-align : center ; 
        top : ${(props) => (props.height)/4}px; 
        left : ${(props) => (props.width)/4}px; 
        width : 20px; 
        font-size : 1rem ; 
        z-index : 4; 
        
        
        
    }

`


const Img = styled.img`
    position : absolute; 
	top : 0px; 
	left : 100px; 
	width : 260px; 
	height : 470px; 


`


export default function Reservation(props) {
	const artistListRedux = useSelector((state) => { return state.ReservationSlice.schedules });
	const userRedux = useSelector((state) => { return state.LoginSlice.user });
	const selectedDate = useSelector((state) => { return state.ReservationSlice.selectedDate });
    const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {

		if (localStorage.getItem('userid') === null || localStorage.getItem('userid') === undefined || localStorage.getItem('userid') === '') {

			
			alert(' 예약은 로그인이 필요 합니다..! ');
			// dispatch(setLoginFrom({from :'reservation'}) ) ; 
			sessionStorage.setItem('loginfrom','prereservation') ;
            sessionStorage.setItem('table','') ;

			navigate("/login", { replace: false });
			return; 

		}


		let tempList = [{}, {} , {} ] ; 
		//setArtistList(artistListRedux.filter((el) => el.yyyy === selectedDate.yyyy && el.mm === selectedDate.mm && el.dd === selectedDate.dd))

		const tempArray = artistListRedux.filter((el) => el.yyyy === selectedDate.yyyy && el.mm === selectedDate.mm && el.dd === selectedDate.dd)

		for(var i = 0 ; i < tempArray.length ;i++) {
			tempList[tempArray[i].bu] = tempArray[i] ;
		}

		setArtistList(tempList); 

		axios.get(process.env.REACT_APP_DB_HOST + '/api/admin/getallseats')
			.then((result) => {

				if (result.data.errno !== undefined) {
					alert(result.data.message);
					return;
				}
				setShowSeats(result.data.filter(el => el.y > 100));

			})
			.catch((error) => {
				//  alert("server error occured!!! Please check console log (F12) ")
				console.log(error)
			})





	}, [])

	const [showSeats, setShowSeats] = useState([]);
	const zonePrice = { A: 15000, B: 15000, C: 100000 };
	const [selectedPeople, setSelectedPeople] = useState(1);
	const [selectedZone, setSelectedZone] = useState(0);

	const [checkList, setCheckList] = useState([]);
	const [totalAmount, setTotalAmount] = useState(0);
	const [selectedArtist, setSelectedArtist] = useState([false, false, false]);
	const [seatModalOpen, setSeatModalOpen] = useState(false);
	const [seats, setSeats] = useState([]);

	const [artistList, setArtistList] = useState([])



	// 아임포트 결제 모듈 시작 
	const { IMP } = window;
	IMP.init('imp88428664'); 


	const isMobile = () => {
		const user = navigator.userAgent;
		let isCheck = false;
	
		if ( user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1 ) {
			isCheck = true;
		}
	
		return isCheck;
	}

	// 아임포트 결제 모듈 끝



	const onSeatModalOpen = () => {


		var now = new Date() ; 

		var tempMonth = now.getMonth() ; 

		console.log( "now.getHours" + now.getFullYear()  + now.getMonth()+1 + now.getHours() + ' vs '+ selectedDate.yyyy + selectedDate.mm + selectedDate.dd  )


		console.log( "getHours()  = " +   now.getHours() )

		if( selectedDate.yyyy === now.getFullYear() && selectedDate.mm === now.getMonth()+1 && selectedDate.dd === now.getDate() ) {
		 
		   if( now.getHours() >= 17 ) {

				alert('당일 예약은 17시 이전까지만 가능합니다. 워크인 가능여부는 전화문의 부탁드립니다. ')
				return 
			}
		}



		var cnt = 0;
		for (var i = 0; i < selectedArtist.length; i++) {
			if (selectedArtist[i] === true) cnt++;
		}

		if (cnt === 0) {
			alert('공연을 선택해 주세요.')
			return
		}

		setSeatModalOpen(true);
	}

	const onSeatModalClose = (selected) => {

		if (selected === 'JUSTCLOSE') {
			setSeatModalOpen(false);
			return;
		}

		setSeats(selected);

		var cnt = 0;
		for (var i = 0; i < selectedArtist.length; i++) {
			if (selectedArtist[i] === true) cnt++;
		}


/*		if (localStorage.getItem('snstype') === 'GOOGLE' ) // 구글로그인(외국인)일 경우 공짜 예약
			setTotalAmount(0);
		else 
*/

		if (props.mode === 'ADMIN')
			setTotalAmount(0);
		else if (selected[0].nametag.substr(0, 1) === 'V')
			setTotalAmount(100000);
		else
			setTotalAmount(15000 * selectedPeople * cnt);
			//setTotalAmount(zonePrice[selected[0].nametag.substr(0, 1)] * selectedPeople * cnt);

		setSeatModalOpen(false);
	}



	const resSubmit = () => { //결재모듈 
	}

	const onSelectCount = (e) => {

		//        const temp = {...selectedSeat}
		//temp[j][i] = e.target.value ; 
		setSeats([]);
		setTotalAmount(0);
		setSelectedPeople(e.target.value);

	}


	const onSelectArtist = (i) => {
		setSeats([]);

		setTotalAmount(0);
		const temp = [...selectedArtist];
		temp[i] = !selectedArtist[i];
		setSelectedArtist(temp);
	}



	const moveToCheck = () => {


		if (selectedPeople === 0) {

			alert('인원수를 입력해주세요.');

			return false;
		}

		if (seats.length === 0) {

			alert('좌석을 선택해주세요.');

			return false;
		}

		const tempuserid = localStorage.getItem('userid') ; 
		if (tempuserid.length === 0) {

			alert('로그인 아이디가 없습니다. 재로그인 부탁드립니다.. ');
			sessionStorage.setItem('loginfrom','prereservation') 
			sessionStorage.setItem('table','') 

			navigate(`/login`, { replace: false }); 

			return false;
		}

		const sendData = { 
			userid : localStorage.getItem('userid') ,
			snstype : localStorage.getItem('snstype') ,
			accesstoken : localStorage.getItem('aes256') 
		}
	
	   
	
		axios.post( process.env.REACT_APP_DB_HOST+'/api/login/checktoken', sendData  )
		.then((res)=>{
						
			if(res.data.length === 0 )
			{
				alert('로그인 아이디가 없습니다. 재로그인 부탁드립니다. ');
			
  			    localStorage.setItem('aes256', "") ; 
			    localStorage.setItem('userid', "" );
			    localStorage.setItem('snstype', "") ;
			    localStorage.setItem('name', "") ;


				sessionStorage.setItem('loginfrom','prereservation') 
				sessionStorage.setItem('table','') 

				navigate(`/login`, { replace: false }); 
			  return false;
			}   
		})
		.catch((error)=> {console.log("error =" + error)}) 



		// 첫번째 좌석에 해당하는 zone 단가 * 인원수 
		//var temp = [...checkList]
		var temp = [] ; 
		for (var i = 0; i < selectedArtist.length; i++) {
			if (selectedArtist[i]) {

				var totAmt = zonePrice[seats[0].zone] * selectedPeople
				const tempObject = {};
				Object.assign(tempObject, artistList[i], { selectedZone: selectedZone }, { selectedPeople: selectedPeople }, { seats: seats }, { price: totAmt })

				temp = [...temp, tempObject]

			}
		}
		setCheckList(temp);

		checkOut(temp);



	}

	const checkOut = async (checklist) => {


		try {
			// 데이타를 저장하고 

			const uniquekey = Math.random().toString(36).substring(2, 12);

			var checkdata 
//|| 1 === 1
			if (props.mode === 'ADMIN' ){
				checkdata = {
					uniquekey: uniquekey,
					userid: localStorage.getItem('userid'),
					amount: 0,
					status: '예약완료',
				}

			}

			else {

				checkdata = {
					uniquekey: uniquekey,
					userid: localStorage.getItem('userid'),
					amount: totalAmount,
					status: '결제전',
				}
			}

			var checkitems = [];
			var seats = [];
			var k = 0;
			for (var i = 0; i < checklist.length; i++) {
				checkitems[i] = {
					uniquekey: uniquekey,
					keynum: i,
					yyyy: selectedDate.yyyy,
					mm: selectedDate.mm,
					dd: selectedDate.dd,
					bu: checklist[i].bu,
					person: checklist[i].selectedPeople,
					price: checklist[i].price
				}

				for (var j = 0; j < checklist[i].seats.length; j++) {
					seats[k] = {
						uniquekey: uniquekey,
						keynum: i,
						seatid: checklist[i].seats[j].seatid
					}
					k++;
				}


			}

			if( checkdata.userid === null || checkdata.userid === undefined || checkdata.userid === '' ){
                alert('로그인이 필요 합니다. ')
                localStorage.setItem('aes256', "") ; 
			    localStorage.setItem('userid', "" );
			    localStorage.setItem('snstype', "") ;
			    localStorage.setItem('name', "") ;
                
                sessionStorage.setItem('loginfrom','prereservation') 
                sessionStorage.setItem('table','') 
    
                navigate(`/login`, { replace: false }); 
                return ;
            }

			const MergeObject = { checkdata: { ...checkdata }, checkitems: [...checkitems], seats: [...seats] }


			const result = await axios.post(process.env.REACT_APP_DB_HOST + '/api/reservation/precheckout', MergeObject);
			if (result.data.errno !== undefined) {
				alert(result.data.message);
				return;
			}


			// 1 === 1  임시 

/*****************예약금 결제 모드 시작 *******************/			

//			alert('결제 없이 예약완료(그랜드 오픈기념기간 예약금 없이 예약됩니다. '); // 임시 예약 완료 1 === 1
//			navigate("/my/myreservation", { replace: false })
			//alert('임시 저장되었습니다.')
			// Admin 이 아닌경우 결제연동 사이트를 부르고 

			// 구글 로그인인 경우 무상 결제 운영자 반대로 사용x
			/*
			if (localStorage.getItem('snstype') === 'GOOGLE'  ){

				const data = { uniquekey : uniquekey , status : "예약완료"  }

				axios.post('/api/reservation/updatecheckout', data)
				.then((result) => { 
		
					  if(result.data.errno !== undefined ) {
						alert(result.data.message); 
						return ; 
						}
					 
						alert('예약이 완료 되었습니다. 외국인(구글 로그인)인 경우 예약금(입장료)는 현장결제로 진행됩니다. ') 
						
						navigate("/my/myreservation", { replace: false })
	
		
				})
				.catch((error)=> {
					alert("server error occured!!! Please check console log (F12) ")
					console.log(error)
				}) 

			}
			else 
			*/

			/*****************
			if (props.mode !== 'ADMIN'  ){
				const data = {
					pg: 'html5_inicis',                           // PG사
					pay_method: 'card',                           // 결제수단
					merchant_uid: uniquekey,   // 주문번호
					amount: totalAmount,                                 // 결제금액
					name: '올댓재즈 공연관람 예약금',                  // 주문명
					buyer_name: userRedux.name,                           // 구매자 이름
					buyer_email: userRedux.email, 
					buyer_tel: userRedux.phone,                     // 구매자 전화번호
				}


				if (isMobile()){
					IMP.request_pay({
						...data,
						m_redirect_url: "https://allthatjazz.kr/importredirect"
					  })
				}else{
					IMP.request_pay(data, callback);
				}

			// 완료시 my 주문 list 리다이렉션한다. 
			}

			*****************/

	/*****************예약금 결제 모드 끝 *******************/						


    /*****************예약금 결제 없이 시작 *******************/						

		const data = { uniquekey : uniquekey , status : "예약완료"  }

			axios.post('/api/reservation/updatecheckout', data)
			.then((result) => { 
	
					if(result.data.errno !== undefined ) {
					alert(result.data.message); 
					return ; 
					}
					
					alert('예약이 완료 되었습니다. *공연시작 후 20분내 미입장 시 예약 취소되니 주의해주세요.') 
					
					navigate("/my/myreservation", { replace: false })

	
			})
			.catch((error)=> {
				alert("server error occured!!! Please check console log (F12) ")
				console.log(error)
			}) 


	/*****************예약금 결제 없이 끝 *******************/						


		} catch (err) {
			
			if(err.response.status === 510){
				alert('조금 전 선택한 자리를 다른분께서 예약하였습니다. 자리 재선택이 필요합니다.  ');
				return ; 
			}
			else {
				alert(err);
			}

 			
			console.log("Error >> " + err);
		}

		if (props.mode === 'ADMIN')
		navigate(-1)


	}

	const callback = (response) =>{
		const {
		  success,
		  merchant_uid,
		  error_msg
		} = response;
	  
		if (success) {

			alert('결제 성공');


			const data = { uniquekey : merchant_uid , status : "예약완료"  }

			axios.post('/api/reservation/updatecheckout', data)
			.then((result) => { 
	
				  if(result.data.errno !== undefined ) {
					alert(result.data.message); 
					return ; 
					}
				 
					alert('예약이 완료 되었습니다. ') 
	
					
					navigate("/my/myreservation", { replace: false })

	
			})
			.catch((error)=> {
				alert("server error occured!!! Please check console log (F12) ")
				console.log(error)
			}) 

	
		} else {
		  alert(`결제 실패: ${error_msg}`);

		  const data = { uniquekey : merchant_uid  }

		  axios.post('/api/reservation/usercancel', data)
		  .then((result) => { 
  
				if(result.data.errno !== undefined ) {
				  alert(result.data.message); 
				  return ; 
				}
				  // navigate("/", { replace: false })
		  })
		  .catch((error)=> {
			  alert("server error occured!!! Please check console log (F12) ")
			  console.log(error)
		  }) 

		}
	  }


	return (
		<div>
			<div className="sub-back">
				<p onClick={() => navigate(-1)}>Back</p>
			</div>

			<div id="res">
				<h4 className="main-title">RESERVATION</h4>

				<div className="res-order__info">
					<h5>좌석안내</h5>


				</div>

				<div className="res-order__map">
					
					<div className="res-order__map--left">
						<div className="res-order__map--cont">
							{showSeats.map((a, i) => (

								<Box key={i} x={showSeats[i].x } y={showSeats[i].y -200} width={showSeats[i].width} height={showSeats[i].height} >
									<h4 > {showSeats[i].nametag} </h4>
									<img src={showSeats[i].imageurl} width={showSeats[i].width} height={showSeats[i].height} />
								</Box>

							))}
							<Img src='/img/common/restorant.png' />

						</div>
					</div>
					<div className="res-order__map--right">
						<dl className="res-order__map--amount">
							<dt>[좌석등급 / 잔 여석]</dt>
							<dd><h5 className="zone-a">Hall Table </h5><p><b> 15,000 </b>원</p></dd>
							<dd><h5 className="zone-b">Bar Table </h5><p><b> 15,000 </b>원</p></dd>
							<dd><h5 className="zone-c">Booth </h5><p><b>100,000 </b>원</p></dd>
						</dl>
						<dl className="res-order__map--info">
							<dt>[예매 유의사항]</dt>
							<dd>
								<ul>
									<li>예매 유의사항 꼭 확인해주세요! </li>
									<li>* 바 매장 특성상 1인 1주류 주문이 필수입니다. </li>
									<li>* 좌석 예약금은 공연 관람료로 방문 시 환불되지 않습니다. </li>
									<li>* 공연이 없을 시, 전문 jazz DJ 재즈바로 운영되며 추가 공연비는 부가 되지 않습니다. </li>
									{/* <li >예약보증금은 예약인원, 선택하신 공연에 따라 발생되고 방문 시 100% 환불됩니다.</li>*/}
									<p style={{ paddingLeft: '10px'}}> [예약관람료 계산 방식] </p>
									<p style={{ paddingLeft: '16px'}}>- S, H, O, B 테이블 = 인당 1.5만원 * 공연수 </p>
									<p style={{ paddingLeft: '16px'}}>- V 부스 (단체석) = 테이블당 10만원 ( 인원수,공연수 무관 )</p>
									<p style={{ paddingLeft: '20px'}}>	** V-3(최대인원 7명) 50만원 / V-1(최대인원 5명) 40만원 이상 메뉴 주문 시 이용 가능하고, 좌석비용은 없습니다. </p>
									<li>예매 취소는 공연 1일전 밤12시까지 100% 보증금 환불이 가능하며, 그 이후에는 환불되지 않으니 신중하게 예약해주세요. </li>
									<li>* 당일 예약은 오후 5시까지 가능하고 이후는 전화로 예약 부탁드립니다.</li>
									<li>* 5명 이상 예약은 전화로 부탁드립니다.</li>
									


								</ul>
							</dd>
						</dl>
					</div>
				</div>



				<div className="res-order__info">
					<h5>예약 선택 </h5> <span>{selectedDate.yyyy}-{selectedDate.mm.toString().padStart(2, '0')}-{selectedDate.dd.toString().padStart(2, '0')} </span>
					<span>{getDayofWeek(`${selectedDate.yyyy}-${selectedDate.mm}-${selectedDate.dd}`)} </span>

					<div >
						<h5>총 결제금액 : {totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</h5>
					</div>
				</div>

				<div className="res-order__step">


					<div className="res-order__step--concert">
						<ul className="res-order__step--list">
							{artistList.map((b, j) => (
								<li key={j}>
									{ artistList[j].yyyy !== undefined &&
									<div className="res-order__step--box">

										<input type="checkbox" name="rdo" id={"rdo" + j} onChange={() => onSelectArtist(artistList[j].bu)} />

										<img className="res-order__step--image" src={'/publicimg/' + artistList[j].profileimgurl} htmlFor={"rdo" + j} alt="" />
										<label htmlFor={"rdo" + j}>
											<div className="res-order__step--text">
												<span>{artistList[j].bu + 1}부</span>
												<h5>{artistList[j].nickname}</h5>
												<p>{artistList[j].timeplan}</p>
											</div>
										</label>
									</div>
									}
								</li>
								
							))}
						</ul>
					</div>


					<SeatContainer>
						<div className="res-order__step--person">
							<label >인원</label>
							<select name="" id="order-person" defaultValue='0' onChange={onSelectCount}>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
							</select>

						</div>


						<SelectChair>
							<span onClick={onSeatModalOpen}>좌석선택</span>


						</SelectChair>

						<SelectedChair >
							{seats.map((a, i) => (
								<li key={i}>{seats[i].nametag}</li>
							))}
						</SelectedChair>

						<Cart >

							<button type="button" onClick={moveToCheck}>예약하기</button>
						</Cart>
					</SeatContainer>
				</div>


				<div className="res-order__del">
					<p>
						* 1부공연 관람 후, 2부 관람 연장을 원하시면 2부도 추가 예약 부탁드립니다.<br />
						* 다음 공연 예약 만석 시 추가 시간 이용이 불가능하실 수 있습니다.<br />
						* 인원에 따른 좌석 예약금이 발생합니다.<br />
						* 공연 시작 후 20분내 미 입장시 노쇼 처리 됩니다.<br />
						* S, H, O, B 테이블 = 인당 1.5만원, V3(최대인원 7명) 50만원 / V1(최대인원 5명) 40만원 이상 메뉴 주문 시 이용 가능<br />
					</p>
				</div>




			</div>

			{seatModalOpen && <SeatModal close={onSeatModalClose} people={selectedPeople} bu1={selectedArtist[0]} bu2={selectedArtist[1]} bu3={selectedArtist[2]} gap={900} selectedDate={selectedDate} />}



		</div>
	)

}