import './css/font.css';

import MainSlide from './components/MainSlide'
import MainBanner from './components/MainBanner'
import MainSchedule from './components/MainSchedule'
import { useEffect, useState } from "react";
import axios from 'axios';
import MainArtists from './components/MainArtists2';
import Instagram from './components/Instagram';
import HomePopup from './HomePopUp' ;

function Home() {

  // Popup Start
  const [showMainPop, setShowMainPop] = useState(false);
    // 기본 세팅 값은 false
  const HOME_VISITED = localStorage.getItem("homeVisited");
    // localStorage에 homeVisited 조회
  // Popup end

  useEffect( () => {

    // api 로 access key 검증 문제 없으면 props 로 userid/snstype 을 헤더등에 넘긴다. 
    const sendData = { 
        userid : localStorage.getItem('userid') ,
        snstype : localStorage.getItem('snstype') ,
        accesstoken : localStorage.getItem('aes256') 
    }

   

    axios.post( process.env.REACT_APP_DB_HOST+'/api/login/checktoken', sendData  )
    .then((res)=>{
        
        
        if(res.data.length === 0 )
        {

          localStorage.setItem('aes256', "") ; 
          localStorage.setItem('userid', "" );
          localStorage.setItem('snstype', "") ;
          localStorage.setItem('name', "") ;
        }   
    })
    .catch((error)=> {console.log("error =" + error)}) 
    
},[])


useEffect(() => {
  const today = new Date();
  const handleMainPop = () => {
    if (HOME_VISITED && HOME_VISITED > today) {
    // 현재 date가 localStorage의 시간보다 크면 return
      return;
    }
     if (!HOME_VISITED || HOME_VISITED < today) {
    // 저장된 date가 없거나 today보다 작다면 popup 노출
      setShowMainPop(true);
    }
  };
  window.setTimeout(handleMainPop, 1000); // 1초 뒤 실행
}, [HOME_VISITED]);


  return (
    <div className="App">

      {showMainPop && <HomePopup setShowMainPop={setShowMainPop}></HomePopup>}

      <h1 className="title">ALL THAT JAZZ</h1>

            <MainSlide/>
            <MainBanner/>
            <h4 className="subtitle">SCHEDULE</h4>
            <MainSchedule/>
            <h4 className="subtitle">ARTISTS</h4>
            <MainArtists/>
            <h4 className="subtitle">PHOTO</h4>
           
           

            <Instagram  counter="10"/>
          
    </div>
  );
}

export default Home;



//padding: (상하) (좌우)
//padding: (상) (좌우) (하)
//padding: (상) (우) (하) (좌)


/*
@media (min-width:1440px) {
  .container{ font-size : 15px; }
}
@media (min-width:768px) {
  .container{
   }

}

@media (max-width:769px) {  }
*/