import { useParams } from "react-router-dom";
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, Link , useLocation} from 'react-router-dom';
import axios from "axios";
import { setArtistReservationAction } from '../store/ReservationSlice';  // 예약선택한 아티스트 
import { setSelectedDateAction } from '../store/ReservationSlice';
import { getDayofWeek } from "../components/Util";
import { useCookies } from "react-cookie";
import Instagram from "../components/Instagram";



const Em = styled.em`
    background : url( ${(props) => (props.src)}) no-repeat center center / cover;
    display: block; padding-bottom: 100%;
    border-radius: 50%; position: relative;
    width : 100px; 
    height : 100px ; 
    
`

const SliderEm = styled.em`
    background : url( ${(props) => (props.src)}) no-repeat center center / cover;

`



export default function ArtistDetail(props) {

    let { usertype, userid } = useParams();
    const location = useLocation();

    if ( usertype === undefined ) usertype = props.usertype ; 
    if ( userid === undefined ) userid = props.userid ; 

    const [artist, setArtist] = useState({instruments: '' });
    const [teamMembers, setTeamMembers] = useState([]);
    const navigate = useNavigate();
    const [ArtistList, setArtistList] = useState([]);
    const dispatch = useDispatch();




    useEffect(() => {
        
        window.scrollTo(0, 0);
        if (userid !== null && userid !== "" && userid !== undefined)
            loadFav();

        if (usertype === 'TEAM') {

            const data = { yyyy : location.state?.yyyy, mm : location.state?.mm , dd : location.state?.dd , bu : location.state?.bu ,  teamname :userid}
    
            axios.post(process.env.REACT_APP_DB_HOST + `/api/user/getperformmembers` , data )
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    setTeamMembers(result.data);
                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })

            axios.get(process.env.REACT_APP_DB_HOST + `/api/user/getteam/${userid}`)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    setArtist(result.data[0]);
                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
        


        }
        // Artist 인 경우  
        else {

            axios.get(process.env.REACT_APP_DB_HOST + `/api/user/getuser/${userid}`)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    setArtist(result.data[0]);
                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })


        }


        // 공연정보 가져오기 
        axios.get(process.env.REACT_APP_DB_HOST + `/api/artist/getschedule/${userid}`)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                setArtistList(result.data);

            })
            .catch((error) => {
                console.log(error);
            })

        // To get  Performance 
        //setArtistList( artistListRedux.filter((el) => el.userid === userid ) ) 

        // 페이지가 열릴때마다  클릴 수를 늘린다. click API 

        const data = { artistid: userid, userid: localStorage.getItem('userid') }

        axios.post(process.env.REACT_APP_DB_HOST + `/api/user/click`, data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }
            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })
    }, [])


    const instruments = artist.instruments.split(',');

    let temp = [];
    temp[0] = artist.profileimgurl;
    temp[1] = artist.imageurl1;
    temp[2] = artist.imageurl2;
    temp[3] = artist.imageurl3;
    temp[4] = artist.imageurl4;

    let image = [];
    let j = 0;
    for (var i = 0; i < 5; i++) {
        if (temp[i] !== null && temp[i] !== '' && temp[i] !== undefined) {
            image[j] = temp[i];
            j++;
        }
    }

    temp = [];
    temp[0] = artist.video1;
    temp[1] = artist.video2;
    temp[2] = artist.video3;
    temp[3] = artist.video4;

    let video = [];
    j = 0;
    for (i = 0; i < 4; i++) {
        if (temp[i] !== null && temp[i] !== '' && temp[i] !== undefined) {
            video[j] = temp[i];
            j++;
        }
    }

    //////////////////////// 쿠키를 활용한 좋아요 등록 시작 ////////////////////////////////


    const [myFavorite, setMyFavorite] = useState({});
    const [appCookies, setAppCookies] = useCookies();
    const [openModal, setOpenModal] = useState(false);
    const [hasCookie, setHasCookie] = useState(false);

    const atstFav = (i) => {

        if (localStorage.getItem('userid') === null) {
            alert("관심 아티스트 설정은 로그인이 필요 합니다. ");
            return;
        }

        // 관심제거 
        if (myFavorite[artist.userid] === true) {
            const data = { userid: localStorage.getItem('userid'), artistid: artist.userid };
            axios.post(process.env.REACT_APP_DB_HOST + '/api/user/deletemyfavorite', data)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    const temp = { ...myFavorite };
                    temp[artist.userid] = false;
                    setMyFavorite(temp);

                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
        } else  // 관심 추가 
        {

            const data = { userid: localStorage.getItem('userid'), artistid: artist.userid, usertype: artist.usertype };
            axios.post(process.env.REACT_APP_DB_HOST + '/api/user/setmyfavorite', data)
                .then((result) => {

                    if (result.data.errno !== undefined) {
                        alert(result.data.message);
                        return;
                    }

                    const temp = { ...myFavorite };
                    temp[artist.userid] = true;
                    setMyFavorite(temp);


                })
                .catch((error) => {
                    alert("server error occured!!! Please check console log (F12) ")
                    console.log(error)
                })
            if (appCookies["MODAL_EXPIRES"]) return;
            setHasCookie(false);
            setOpenModal(true);
        }


    }


    const loadFav = () => {

        const data = { userid: localStorage.getItem('userid') }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/user/getmyfavorite', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                const temp = {};
                for (var i = 0; i < result.data.length; i++) {
                    temp[result.data[i].artistid] = true;
                }
                setMyFavorite(temp);


            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })

    }




    const getExpiredDate = (days: number) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    };

    const closeModalUntilExpires = () => {
        if (!appCookies) return;

        const expires = getExpiredDate(1);
        setAppCookies("MODAL_EXPIRES", true, { path: "/", expires });

        setOpenModal(false);
    };

    const closeModal = () => {

        setOpenModal(false);

    }

    //////////////////////// 쿠키를 활용한 좋아요 등록 끝 ////////////////////////////////





    const imagesettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 1000,
        arrows: true,
    }




    const onReservationClick = (yyyy, mm, dd) => {

        dispatch(setArtistReservationAction(ArtistList));
        dispatch(setSelectedDateAction({ yyyy: yyyy, mm: mm, dd: dd }));
    }



    return (
        <div id="contents" className="sub">

            {props.adminmode !== 'ADMIN' &&
            <div className="sub-back">
                <p onClick={() => navigate(-1)}>Back</p>
            </div>
            }

            <div id="atst">

                <table className="atst-detail">
                    <tbody>
                        <tr>
                            <th rowSpan="3" className="atst-detail__pic">
                                <Slider {...imagesettings} >
                                    {image.map((a, i) => (
                                        <div key={i}><SliderEm src={'/publicimg/' + image[i]}></SliderEm></div>
                                    ))}
                                </Slider>
                            </th>
                            <td className="atst-detail__name"><strong>{artist.nickname || artist.teamname}</strong><span></span></td>
                            {props.adminmode !== 'ADMIN' &&
                            <td className={myFavorite[artist.userid] === true ? "atst-detail__fav on" : "atst-detail__fav"} ><button onClick={() => atstFav(i)} >관심</button></td>
                                    }
                        </tr>
                        <tr>
                            <td colSpan="2" className="atst-detail__text">
                                <h5>{artist.introduce}</h5>
                                <p></p>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" className="atst-detail__part">{usertype === 'ARTIST' ? 'Artist' : 'Band'}</td>
                        </tr>
                    </tbody>
                </table>
                {usertype === 'TEAM' &&
                    <div>
                        <h4 className="subtitle">BAND MEMBERS</h4>
                        <ul className="join-artist__img atst">
                            {teamMembers.map((a, i) => (

                                <li key={i}>
                                    <Link to={`/artistonlydetail/${teamMembers[i].userid}`}  >

                                        <div className="mpg-team__profile">
                                            <Em src={'/publicimg/' + teamMembers[i].profileimgurl}>
                                                {teamMembers[i].isleader == true &&
                                                    <span className="mpg-team__profile--leader">leader</span>}
                                            </Em>
                                            <p>{teamMembers[i].nickname}</p>
                                            {teamMembers[i].isleader == true ? <span>(리더)</span> : null}
                                        </div>
                                    </Link>                                              
                                </li>
                                
                            ))}
                        </ul>
                    </div>
                }

                <h4 className="subtitle">INSTRUMENTS</h4>
                <ul className="atst-inst">
                    {instruments.map((a, i) => (
                        <li key={i}><span>{instruments[i]}</span></li>
                    ))}
                </ul>

                <div className="atst-video__title">
                    <h4 className="subtitle">VIDEOS</h4>
                    <div className="atst-video__arrows"></div>
                </div>

                <div className="atst-video">
                    {video.map((a, i) => (
                        <div key={i}>
                            <div className="atst-video__frame">
                                <iframe src={'https://youtube.com/embed/' + video[i].trim().slice(-11)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                    ))}
                </div>

                <h4 className="subtitle">Instagram</h4>
                <h3><a href={artist.instagram} target="_blank" rel="noopener noreferrer" >
                    {artist.instagram}  </a></h3>


                <h4 className="subtitle">PERFORMANCES</h4>
                <Link to='/Reservation' >
                    <table className="atst-pfm">



                        <tbody>

                            {ArtistList.length === 0 &&
                                <tr><td><h3> 등록된 공연일정이 없습니다. </h3></td></tr>
                            }

                            {ArtistList.map((a, i) => (
                                <tr key={i}>
                                    <td className="atst-pfm__date">{ArtistList[i].yyyy}.{ArtistList[i].mm.toString().padStart(2, '0')}.{ArtistList[i].dd.toString().padStart(2, '0')}</td>
                                    <td className="atst-pfm__day">{getDayofWeek(`${ArtistList[i].yyyy}-${ArtistList[i].mm}-${ArtistList[i].dd}`)}</td>
                                    <td className="atst-pfm__time">{ArtistList[i].bu + 1}부 {ArtistList[i].starthh}:{ArtistList[i].startmm} - {ArtistList[i].endhh}:{ArtistList[i].endmm} </td>
                                    <td className="atst-pfm__part">{ArtistList[i].usertype === 'ARTIST' ? 'Solo Performance' : 'Team Performance'}</td>
                                    <td className="atst-pfm__btn"><button onClick={(e) => onReservationClick(ArtistList[i].yyyy, ArtistList[i].mm, ArtistList[i].dd)} >RESERVATION</button></td>
                                </tr>
                            ))}

                        </tbody>

                    </table>
                </Link>

            </div>

            {openModal && !hasCookie &&
                <div>
                    <div id="popup" className="artist_favorite">
                        <div className="pop-title">
                            <h4 className="pop-title__fav">INTERESTED ARTIST</h4>
                            <p>관심 아티스트로 등록되었습니다.</p>
                            <span>관심 아티스트는 마이페이지에서 관리할 수 있습니다.</span>
                        </div>
                        <ul className="pop-btn">
                            <li><Link to='/my/myartist' >관심 아티스트 보기</Link></li>
                            <li><button className="bk" onClick={closeModal}>확인</button></li>
                        </ul>
                        <div className="pop-cookie">
                            <input type="checkbox" name="" id="pop-cookie" onClick={closeModalUntilExpires} />
                            <label for="pop-cookie">오늘 하루 다시보지 않기</label>
                        </div>
                    </div>
                </div>
            }



        </div>

    )




}