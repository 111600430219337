

import '../css/admin.css'
import AdminMenu from '../admincomponents/AdminMenu'
import { Link, Routes, Route, Outlet } from 'react-router-dom'
import AdminArtist from './AdminArtist'
import AdminSchedule from './AdminSchedule'
import AdminDayoff from './AdminDayoff'
import AdminPickSeat from './AdminPickSeat'
import AdminBanner from './AdminBanner'
import AdminFront from './AdminFront'
import Reservation from '../pages/Reservation'
import AdminInstakey from './AdminInstakey'
import AdminFoodCategory from './AdminFoodCategory'
import AdminFood from './AdminFood'
import AdminUser from './AdminUser'
import AdminTeam from './AdminTeam'
import AdminAdmin from './AdminAdmin'
import AdminOrder from './AdminOrder'
import AdminStatistic from './AdminStatistic'
import { useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function AdminHome() {

    const navigate = useNavigate();


    useEffect(() => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/isadmin', { userid: localStorage.getItem('userid'), accesstoken: localStorage.getItem('aes256') })
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                if (result.data.length === 0) {
                    alert('Admin 만 접근 가능합니다. ')
                    navigate(-1);
                }

            })
            .catch((error) => {
                //alert("getorderedlist error occured!!! Please check console log (F12) ")
                console.log(error)
            })




    }, [])


    const HomeTemplate = () => {
        return (
            <div id="adm-wrap" >
            <header id="adm-header">
                <Link to='/' >
                    <h2> ALL THAT JAZZ </h2>
                </Link>
                <dl>
                    <dt><strong>관리자</strong>님 환영합니다.</dt>
                </dl>
            </header>

            <div id="adm-main">

            <AdminMenu />
                <div id="adm-content">
                 
                <Outlet />
                </div>
                </div>

            </div>
         
        );
    }


    return (
        <>


            <Routes>
                <Route path="" element={<HomeTemplate />}>
                
                    <Route path='adminstatistic' element={<AdminStatistic />}> </Route>
                    <Route path='admindayoff' element={<AdminDayoff />}> </Route>
                    <Route path='adminartist' element={<AdminArtist />}> </Route>
                    <Route path='adminuser' element={<AdminUser />}> </Route>
                    <Route path='adminteam' element={<AdminTeam />}> </Route>
                    <Route path='adminadmin' element={<AdminAdmin />}> </Route>
                    <Route path='admintablerequest' element={<AdminHome />}> </Route>
                    <Route path='adminfood' element={<AdminFood />}> </Route>
                    <Route path='adminfoodcategory' element={<AdminFoodCategory />}> </Route>
                    <Route path='admingoods' element={<AdminHome />}> </Route>
                    <Route path='adminschedule' element={<AdminSchedule />}> </Route>
                    <Route path='adminnotification' element={<AdminHome />}> </Route>
                    <Route path='adminbanner' element={<AdminBanner />}> </Route>
                    <Route path='statistics' element={<AdminHome />}> </Route>
                    <Route path='adminfront' element={<AdminFront />}> </Route>
                    <Route path='adminpickseat' element={<AdminPickSeat />}> </Route>
                    <Route path='walkin' element={<Reservation mode='ADMIN' />}> </Route>
                    <Route path='instakey' element={<AdminInstakey />}> </Route>
                    <Route path='adminorder' element={<AdminOrder />}> </Route>
                </Route>    

                
            </Routes>
            


        </>

    )


}


