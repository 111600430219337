import {createSlice} from '@reduxjs/toolkit'


const ReservationSlice = createSlice(
    {   name : 'setArtistReservationSlice',
        initialState : { data : [{}] , schedules : [{}] , selectedDate : {}   } ,

        reducers : {
            setArtistReservationAction(state, action) {
                state.data = action.payload; 
            } ,

            setSchedulesAction(state, action) {
                state.schedules = action.payload; 
            } , 

            setSelectedDateAction(state, action) {
                state.selectedDate = action.payload; 
            } , 
        }
    })

export let { setArtistReservationAction, setSchedulesAction , setSelectedDateAction } = ReservationSlice.actions 


export default ReservationSlice.reducer ;   
