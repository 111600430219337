import { useState, useEffect } from "react"
import axios from "axios";
import DatePicker from "react-datepicker"
import { ko } from "date-fns/esm/locale"



export default function AdminDayoff() {

    const today = new Date();

    const [selectedDate, setSelectedDate] = useState({ yyyy: today.getFullYear(), mm: today.getMonth() + 1, dd: today.getDate() });
    const [currentDate, setCurrentDate] = useState(`${selectedDate.yyyy}-${selectedDate.mm.toString().padStart(2, '0')}-${selectedDate.dd.toString().padStart(2, '0')}`);

    const [dayoff, setDayoff] = useState([]);

    useEffect(() => {




        getData() ; 
       
    }, [])


    const getData = () => {

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/getdayoff')
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }
            setDayoff(result.data)
        })
        .catch((error) => {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        })


    }

    const onDeleteClick = (i) => {

        const data = { yyyy : dayoff[i].yyyy, mm : dayoff[i].mm, dd : dayoff[i].dd }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/deldayoff', data)
            .then((result) => {

                if (result.data.errno !== undefined) {
                    alert(result.data.message);
                    return;
                }

                getData() ; 
                alert('삭제되었습니다. ')

            })
            .catch((error) => {
                alert("server error occured!!! Please check console log (F12) ")
                console.log(error)
            })
    }


    const datepickerChange = (dt) => {

        changeDate(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());
        setCurrentDate(dt.getFullYear() + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
        getData(dt.getFullYear(), dt.getMonth() + 1, dt.getDate());

    }

    const changeDate = (yyyy, mm, dd) => {
        setSelectedDate({ yyyy: yyyy, mm: mm, dd: dd })
    }


    const saveNewAdmin = () => {

        const data = { yyyy: selectedDate.yyyy  , mm : selectedDate.mm , dd : selectedDate.dd   }

        axios.post(process.env.REACT_APP_DB_HOST + '/api/admin/setdayoff', data)
        .then((result) => {

            if (result.data.errno !== undefined) {
                alert(result.data.message);
                return;
            }

            getData() ; 
            alert('저장되었습니다. ')
            
        })
        .catch((error) => {
            alert("server error occured!!! Please check console log (F12) ")
            console.log(error)
        })

    }

    return (
        <div id="adm-inner">

            <h2 id="adm-title">휴무일 관리 </h2>

            <div className="adm-sch">
                <table>
                    <tbody>
                        <tr>
                            <th style={{width:'200px'}}>새로운 휴무일 추가</th>
                            <td>  <DatePicker locale={ko} dateFormat='yyyy-MM-dd' selected={new Date(currentDate)} onChange={(date) => datepickerChange(date)} />
                            </td>

                            <td className="adm-sch__submit">
                                <button onClick={()=>saveNewAdmin()}>저장</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <table className="adm-tb">
                <thead>
                    <tr>
                        <th>YYYY</th>
                        <th>MM</th>
                        <th>DD</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>

                    {dayoff.map((a, i) => (
                        <tr key={i}>
                            <td>{dayoff[i].yyyy}</td>
                            <td>{dayoff[i].mm}</td>
                            <td>{dayoff[i].dd}</td>
                            <td><button className="adm-tb__btn" onClick={() => onDeleteClick(i)}>삭제</button></td>

                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    )





}